import React from "react";

const PropertyDetailSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="flex items-center gap-x-1 h-7 bg-slate-200 rounded-full sm:gap-x-3 mt-7 w-11/12 lg:w-3/4 mx-auto"></div>

      <div className="w-11/12 lg:w-3/4 mx-auto h-[20vh] sm:h-[40vh] lg:h-[70vh] gap-x-5 mt-5 grid grid-cols-12">
        <div className="col-span-7 bg-slate-200 lg:col-span-8 h-[20vh] sm:h-[40vh] lg:h-[70vh] w-full"></div>
        <div className="col-start-8 lg:col-start-9 col-end-13 h-[20vh] sm:h-[40vh] lg:h-[70vh] relative">
          <div className="flex gap-y-2 flex-col h-full">
            <div className="bg-slate-200 h-full w-full"></div>
            <div className="bg-slate-200 h-full w-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailSkeleton;
