import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  banner: [],
};

export const Banner = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BANNER:
      return {
        ...state,
        isloading: false,
        success: true,
        banner: action.payload.banners,
      };

    case ActionTypes.REMOVE_BANNER_DATA:
      return {
        isloading: true,
        success: false,
        banner: [],
      };

    default:
      return state;
  }
};
