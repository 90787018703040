import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import { Link } from "react-router-dom";
import { classiBazarLogo } from "../../Asset/Assest";

const AgentsCard = ({ data, setShowEmailPopup, setAgentId }) => {
  return (
    <div className="w-full border rounded-2xl cursor-pointer transition-all duration-300 hover:scale-100 hover:shadow-md">
      <Link to={`/agent-detail?indx=${data.id}`}>
        <div
          className="w-full rounded-t-xl h-12 flex justify-start px-8 py-3 items-center"
          style={{ background: `${data.company_color}` }}
        >
          <div className="w-24 h-7">
            {data.company_logo ? (
              <img
                src={data.company_logo}
                alt="logo"
                className="w-full h-full object-contain"
              />
            ) : (
              <img src={classiBazarLogo} alt="logo" className="w-full h-full" />
            )}
          </div>
        </div>
      </Link>
      <div className="p-2 md:p-5 w-full">
        <div className="flex items-center justify-between md:gap-x-10 w-full">
          {/* profile */}
          <Link to={`/agent-detail?indx=${data.id}`}>
            <div className="flex items-center gap-x-3 md:gap-x-7 w-full">
              <div className="w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24 border rounded-full">
                {data.image_url ? (
                  <img
                    src={data.image_url}
                    alt=""
                    className="w-full h-full rounded-full"
                  />
                ) : (
                  <img
                    src={classiBazarLogo}
                    alt=""
                    className="w-full h-full rounded-full object-contain"
                  />
                )}
              </div>
              <div>
                <h4 className="font-medium text-base md:text-2xl text-textPrimary">
                  {data.first_name} {data.last_name}
                </h4>
              </div>
            </div>
          </Link>

          {/* email and call */}
          <div className="flex gap-y-3 md:gap-y-4 flex-col">
            <div
              className="border py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center transition duration-1000 ease-in-out hover:bg-gray-100 justify-center rounded-2xl"
              onClick={() => {
                setAgentId(data.id);
                setShowEmailPopup(true);
              }}
            >
              <HiOutlineMail className="text-textSecondary text-base md:text-2xl" />
              <p className="text-sm md:text-lg text-textPrimary">Email</p>
            </div>

            <a href={`tel: ${data.mobile}`}>
              <div className="border py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center justify-center rounded-2xl transition duration-1000 ease-in-out hover:bg-gray-100">
                <BiPhoneCall className="text-textSecondary text-base md:text-2xl" />
                <p className="text-sm md:text-lg text-textPrimary">Call</p>
              </div>
            </a>
          </div>
        </div>
        <Link to={`/agent-detail?indx=${data.id}`}>
          <hr className="mt-6" />
          <div className="flex flex-wrap items-center justify-between mt-8">
            <div className="flex items-center flex-col gap-y-4">
              <h3 className="font-medium text-xl md:text-3xl">
                {data.sold?.length ? data.sold?.length : 0}
              </h3>
              <p className="text-textSecondary text-xs md:text-base">
                Properties sold
              </p>
            </div>
            <div className="flex items-center flex-col gap-y-4">
              <h3 className="font-medium text-xl md:text-3xl">
                ${data.sold_amount ? data.sold_amount : 0}
              </h3>
              <p className="text-textSecondary text-xs md:text-base">
                Avg sold price
              </p>
            </div>
            <div className="flex items-center flex-col gap-y-4">
              <h3 className="font-medium text-xl md:text-3xl">
                {data.days_on_market ? data.days_on_market : 0}
              </h3>
              <p className="text-textSecondary text-xs md:text-base">
                Avg days on market
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AgentsCard;

export const AgentsCardSkeleton = () => {
  return (
    <div className="w-full animate-pulse border rounded-2xl">
      <div className="w-full rounded-t-xl h-12 flex justify-start px-8 py-3 items-center bg-slate-200">
        <div className="w-24 h-7 bg-slate-100 rounded-full"></div>
      </div>
      <div className="p-2 md:p-5 w-full">
        <div className="flex items-center justify-between md:gap-x-10">
          {/* profile */}
          <div className="flex items-center gap-x-2 md:gap-x-7">
            <div className="w-16 h-16 md:w-24 md:h-24 border bg-slate-200 rounded-full"></div>
            <div>
              <div className="font-semibold text-2xl bg-slate-200 text-textPrimary w-32 md:w-52 rounded-full h-6"></div>
              <div className="text-textSecondary mt-2 h-4 w-28 md:w-40 rounded-full bg-slate-200"></div>
              <div className="flex items-center gap-x-4 mt-2 w-24 md:w-36 rounded-full bg-slate-200 h-5"></div>
            </div>
          </div>
          {/* email and call */}
          <div className="flex gap-y-4 flex-col">
            <div className="border py-3 w-28 md:w-40 rounded-full h-7 bg-slate-200 mt-5"></div>
            <div className="border py-3 w-28 md:w-40 rounded-full h-7 bg-slate-200 "></div>
          </div>
        </div>
        <hr className="mt-6" />
        <div className="flex items-center justify-between mt-8">
          <div className="flex items-center flex-col gap-y-4">
            <div className="font-semibold text-3xl h-6 w-16 md:w-20 rounded-full bg-slate-200"></div>
            <div className="text-textSecondary w-28 md:w-36 bg-slate-200 rounded-full h-5"></div>
          </div>
          <div className="flex items-center flex-col gap-y-4">
            <div className="font-semibold text-3xl h-6 w-16 md:w-20 rounded-full bg-slate-200"></div>
            <div className="text-textSecondary w-28 md:w-36 bg-slate-200 rounded-full h-5"></div>
          </div>
          <div className="flex items-center flex-col gap-y-4">
            <div className="font-semibold text-3xl h-6 w-16 md:w-20 rounded-full bg-slate-200"></div>
            <div className="text-textSecondary w-28 md:w-36 bg-slate-200 rounded-full h-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
