import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const ExploreCard = ({ title, data }) => {
  return (
    <div className="w-full">
      <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
        {title}
      </h4>
      <hr className="w-20 border border-primary mt-4" />

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
        {data.map((data) => {
          return (
            <div
              key={data.id}
              className={`mt-10 rounded-xl hover:shadow-md pt-7 transition-all duration-300 hover:scale-105 ${
                data.link ? "pb-16" : "pb-5"
              } border relative`}
            >
              <div className="w-fit py-2 px-5 bg-primary text-white border rounded-r-full">
                <h5 className="font-semibold text-base xl:text-lg">
                  {data.tag}
                </h5>
              </div>
              <div className="p-5 mt-4">
                <h5 className="font-semibold text-xl xl:text-2xl">
                  {data.title}
                </h5>
                <p className="text-sm xl:text-base text-textSecondary mt-5">
                  {data.description}
                </p>

                {data.link ? (
                  <Link to={data.url}>
                    <div className="mt-10 absolute bottom-5 flex items-center gap-x-2 xl:gap-x-5 cursor-pointer">
                      <h6 className="text-green-600 font-medium text-sm xl:text-base tracking-wider">
                        {data.link}
                      </h6>
                      <BsArrowRight className="text-green-600 text-lg xl:text-2xl" />
                    </div>
                  </Link>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExploreCard;
