import React from "react";
import { GrClose } from "react-icons/gr";
import Loading from "../../Component/Loading/Loading";
import { shallowEqual, useSelector } from "react-redux";

const EnquiryDetail = ({
  showDetail,
  setShowDetail,
  enquiryDetail,
  setEnquiryId,
  setUserEnquiryId,
  approvedEnquiry,
}) => {
  const detailLoading = useSelector(
    (state) => state.enquiry.isloading,
    shallowEqual
  );

  if (detailLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="mt-2 w-full h-fit p-5 border bg-white shadow-lg rounded-xl">
      <div className="flex justify-end">
        <div
          className="hover:border cursor-pointer hover:bg-gray-100 rounded-full p-2"
          onClick={() => {
            setEnquiryId("");
            setUserEnquiryId("");
            setShowDetail(false);
          }}
        >
          <GrClose className="text-xl sm:text-2xl" />
        </div>
      </div>
      <h4 className="text-textPrimary text-sm md:text-xl font-semibold">
        Enquiry Details
      </h4>
      <hr className="w-12 border border-primary mt-4" />
      <h6 className="text-sm md:text-base lg:text-xl font-semibold mt-8">
        Lead for {enquiryDetail.enquiry_for}
      </h6>
      <div className="flex gap-x-3 w-full mt-7">
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Property Type
          </p>

          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.property_type}
          </p>
        </div>
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Location
          </p>
          {enquiryDetail.location.map((val, i) => {
            return (
              <p
                className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary"
                key={i}
              >
                {val},
              </p>
            );
          })}
        </div>
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Property Condition
          </p>
          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.property_condition}
          </p>
        </div>
      </div>

      <div className="flex gap-x-3 w-full mt-7">
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Nature of property
          </p>

          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.nature_of_property}
          </p>
        </div>
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            What are you looking for?
          </p>
          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.looking_for}
          </p>
        </div>
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Budget (price range)
          </p>
          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.budget}
          </p>
        </div>
      </div>

      <div className="flex gap-x-3 w-full mt-7">
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Land Size (Square m)
          </p>

          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.land_size}
          </p>
        </div>
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            House Size (Square m)
          </p>
          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.house_size}
          </p>
        </div>
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            When do you need
          </p>
          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.when_need}
          </p>
        </div>
      </div>

      <hr className="mt-8" />
      <h4 className="text-textPrimary text-sm md:text-xl font-semibold mt-7">
        Contact Details
      </h4>
      <hr className="w-12 border border-primary mt-4" />
      <div className="flex gap-x-3 w-full mt-7">
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Name
          </p>

          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.name}
          </p>
        </div>
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Email
          </p>
          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.email}
          </p>
        </div>
        <div className="w-full h-full">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Contact
          </p>
          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.phone}
          </p>
        </div>
      </div>
      <div className="w-full h-full mt-7">
        <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
          Note
        </p>
        <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
          {enquiryDetail.description}
        </p>
      </div>
      {approvedEnquiry && approvedEnquiry.length ? (
        <>
          <hr className="mt-8" />
          <h4 className="text-textPrimary text-sm md:text-xl font-semibold mt-7">
            Agency Details
          </h4>
          <hr className="w-12 border border-primary mt-4" />
          <div className="flex gap-x-3 w-full mt-7">
            <div className="w-full h-full">
              <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
                Agency Name
              </p>

              <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
                {approvedEnquiry?.[0].company_name}
              </p>
            </div>

            <div className="w-full h-full">
              <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
                Email
              </p>
              <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
                {approvedEnquiry?.[0].email_address}
              </p>
            </div>
            <div className="w-full h-full">
              <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
                Contact
              </p>
              <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
                {approvedEnquiry?.[0].contact_number
                  ? approvedEnquiry?.[0].contact_number
                  : approvedEnquiry?.[0].mobile_number}
              </p>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EnquiryDetail;
