import React, { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { MapCard } from "../Card/SearchCard";

const Maps = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleMarkerClick = (product) => {
    setSelectedProduct(product);
  };

  const handleInfoWindowClose = () => {
    setSelectedProduct(null);
  };
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  };

  const mapOptions = {
    zoom: 5, // Initial zoom level
    center: { lat: -25.2744, lng: 133.7751 },
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU", // Replace with your Google Maps API key
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={mapContainerStyle} options={mapOptions}>
      {products.map((product) => (
        <Marker
          key={product.postId}
          position={{
            lat: parseFloat(product.latitude),
            lng: parseFloat(product.longitude),
          }}
          onClick={() => handleMarkerClick(product)}
        />
      ))}

      <div className="w-[200%]">
        {selectedProduct && (
          <InfoWindow
            position={{
              lat: parseFloat(selectedProduct.latitude),
              lng: parseFloat(selectedProduct.longitude),
            }}
            onCloseClick={handleInfoWindowClose}
          >
            <MapCard data={selectedProduct} />
          </InfoWindow>
        )}
      </div>
    </GoogleMap>
  ) : null;
};

export default Maps;
