import React from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "../Formik/Error";

const SelectField = ({ label, name, ...rest }) => {
  return (
    <div className="text-sm">
      <label
        htmlFor={name}
        className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between"
      >
        {label} <ErrorMessage name={name} component={TextError} />
      </label>

      <Field
        as="select"
        name={name}
        {...rest}
        className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
      >
        <option value="Banner Advertisement">Banner Advertisement</option>
        <option value="List My Ad">List My Ad</option>
        <option value="Others">Others</option>
      </Field>
    </div>
  );
};

export default SelectField;
