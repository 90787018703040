import React from "react";
import { PropertyCardSkeleton } from "../../Component";

const SavedPropertySkeleton = () => {
  return (
    <div className="w-full h-full animate-pulse">
      <div className="w-full h-fit border bg-slate-300 px-5 pt-5 rounded-xl">
        <div className="font-semibold text-2xl w-full h-7 bg-slate-200 rounded-full"></div>

        <div className="flex items-center gap-x-3 mt-8 pb-4">
          <div className="bg-slate-200 w-full h-5 rounded-full"></div>
          <div className="bg-slate-200 w-full h-5 rounded-full"></div>
          <div className="bg-slate-200 w-full h-5 rounded-full"></div>
        </div>
      </div>

      <div className="w-full grid lg:grid-cols-2 grid-cols-1 gap-x-3">
        <PropertyCardSkeleton />
        <PropertyCardSkeleton />
      </div>
    </div>
  );
};

export default SavedPropertySkeleton;
