import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { News } from "./News/reducer";
import { Properties } from "./Property/reducer";
import { AgentAndAgencies } from "./Agent-Agencies/reducer";
import { Search } from "./Search/reducer";
import { Auth } from "./Auth/reducer";
import { Profile } from "./Profile/reducer";
import { Wishlist } from "./Wishlist/reducer";
import { Enquiry } from "./Enquiry/reducer";
import { Profiles } from "./UserProfile/reducer";
import { Notification } from "./Notification/reducer";
import { Inbox } from "./Inbox/reducer";
import { TermsPrivacy } from "./Terms-Privacy/reducer";
import { Contact } from "./Contact/reducer";
import { Sold } from "./Sold/reducer";
import { Banner } from "./Banner/reducer";

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      property: Properties,
      search: Search,
      news: News,
      agentagencies: AgentAndAgencies,
      auth: Auth,
      profile: Profile,
      profiles: Profiles,
      wishlist: Wishlist,
      enquiry: Enquiry,
      notification: Notification,
      inbox: Inbox,
      termsprivacy: TermsPrivacy,
      contact: Contact,
      sold: Sold,
      banner: Banner,
    }),
    process.env.NODE_ENV === "development"
      ? compose(composeWithDevTools(applyMiddleware(...[thunk, logger])))
      : compose(applyMiddleware(...[thunk]))
  );

  return store;
};
