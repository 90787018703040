import React from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { classiBazarLogo } from "../../Asset/Assest";

const PropertyCard = ({
  title,
  data,
  handleAddToWishlist,
  handleRemoveWishlist,
}) => {
  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowBack />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowForward />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 644,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 483,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="w-full">
      <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
        {title}
      </h4>
      <hr className="w-20 border border-primary mt-4" />

      <div className="relative">
        <Slider {...settings}>
          {data &&
            data.map((data) => {
              return (
                <div className="px-1 2xl:px-3 pb-5" key={data.post_id}>
                  <div className="mt-10 h-[28rem] lg:h-[30rem] relative rounded-xl hover:shadow-md border cursor-pointer slider-card">
                    <Link
                      to={`/property-detail?property_category=${data.property_category}&indx=${data.post_id}&key=${data.title}`}
                    >
                      <div
                        className="w-full rounded-t-xl h-12 flex justify-center items-center px-3"
                        style={{ background: `${data.company_color}` }}
                      >
                        <div className="flex items-center gap-x-2 w-full">
                          <div className="w-8 h-8 rounded-full">
                            {data.athumbnail ? (
                              <img
                                src={data.athumbnail}
                                alt=""
                                className="w-full h-full object-fill rounded-full"
                              />
                            ) : (
                              <img
                                src={classiBazarLogo}
                                alt=""
                                className="w-full h-full object-cover rounded-full"
                              />
                            )}
                          </div>

                          <h4
                            className="font-medium text-[0.5rem] md:text-xs"
                            style={{ color: `${data.agent_color}` }}
                          >
                            {data.afname} {data.alname}
                          </h4>
                        </div>

                        <div className="w-24 h-7 overflow-hidden">
                          {data.company_logo_thumb ? (
                            <img
                              src={data.company_logo_thumb}
                              alt="logo"
                              className="w-full object-contain h-full"
                            />
                          ) : (
                            <img
                              src={classiBazarLogo}
                              alt="logo"
                              className="w-full h-full object-contain"
                            />
                          )}
                        </div>
                      </div>

                      <div className="w-full h-52 lg:h-56 relative overflow-hidden">
                        {data.thumbnail ? (
                          <img
                            src={data.thumbnail}
                            alt="img"
                            className="w-full h-full object-cover thumb"
                          />
                        ) : (
                          <img
                            src={classiBazarLogo}
                            alt="img"
                            className="w-full h-full object-contain thumb"
                          />
                        )}

                        {data.ad_status !== "4" && data.spoted === "1" ? (
                          <div className="absolute top-1 text-white flex items-center justify-center gap-x-1 bg-primary left-0 py-1 px-5 rounded-r-lg">
                            <p className="font-medium capitalize">Spotted</p>
                          </div>
                        ) : null}

                        <div className="absolute bottom-5 text-primary flex items-center justify-center gap-x-1 bg-white left-10 md:left-5 py-1 pl-1 pr-3 rounded-lg">
                          <GoPrimitiveDot size={20} />
                          <p className="font-medium capitalize">
                            {data.property_category}
                          </p>
                        </div>

                        {data.ad_status !== "4" && data.urgent === "1" ? (
                          <div className="absolute bottom-5 text-white flex items-center justify-center gap-x-1 bg-red-500 right-10 md:right-5 py-1 pl-3 pr-3 rounded-lg">
                            <p className="font-medium capitalize">Urgent</p>
                          </div>
                        ) : null}

                        {data.ad_status === "4" ? (
                          <div className="absolute bottom-5 text-white flex items-center justify-center gap-x-1 bg-red-500 right-10 md:right-5 py-1 pl-3 pr-3 rounded-lg">
                            <p className="font-medium capitalize">
                              {data.status}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </Link>
                    <div className="w-11/12 mx-auto mt-4">
                      <div className="flex w-full items-center justify-between">
                        <h5 className="text-primary truncate font-medium w-full text-base lg:text-lg ">
                          <Link
                            to={`/property-detail?property_category=${data.property_category}&indx=${data.post_id}&key=${data.title}`}
                          >
                            {data.title}
                          </Link>
                        </h5>

                        <div className="hover:bg-gray-100 rounded-full p-2">
                          {data.is_wishlist ? (
                            <AiFillHeart
                              size={27}
                              className="text-red-500"
                              onClick={() => handleRemoveWishlist(data.post_id)}
                            />
                          ) : (
                            <AiOutlineHeart
                              size={27}
                              className="text-textSecondary"
                              onClick={() => handleAddToWishlist(data.post_id)}
                            />
                          )}
                        </div>
                      </div>

                      <Link
                        to={`/property-detail?property_category=${data.property_category}&indx=${data.post_id}&key=${data.title}`}
                      >
                        <h4 className="text-textPrimary font-semibold text-xl lg:text-2xl">
                          {data.price !== "0"
                            ? data.price_type !== "2"
                              ? `$${data.price} ${
                                  data.property_category === "rent"
                                    ? `/ ${data.price_type2}`
                                    : ""
                                }`
                              : `$${data.price} - $${data.to_price}  ${
                                  data.property_category === "rent"
                                    ? `/  ${data.price_type2}`
                                    : ""
                                }`
                            : ""}
                        </h4>
                        <p className="text-textSecondary text-sm mt-3 truncate">
                          {data.ad_address}
                        </p>

                        {/* icons */}
                        <div className="flex flex-wrap items-center gap-x-5 absolute bottom-2">
                          {data.ads_data &&
                            data.ads_data.map((item, i) => {
                              return item.value === null ||
                                item.value === "0" ||
                                item.value === " m sq." ? null : (
                                <div
                                  className="flex items-center gap-x-2"
                                  key={i}
                                >
                                  <img
                                    src={item.img}
                                    alt="parking"
                                    className="w-5 h-5"
                                  />

                                  <h6 className="text-textPrimary text-base lg:text-lg flex items-center">
                                    {item.label === "Area"
                                      ? item.value.replace("m sq.", " ")
                                      : item.value}
                                    <p
                                      className={` ${
                                        item.label === "Area"
                                          ? "block"
                                          : "hidden"
                                      }`}
                                    >
                                      {" "}
                                      m <sup>2</sup>
                                    </p>
                                  </h6>
                                </div>
                              );
                            })}
                          <h6 className="border-l px-2 capitalize text-textSecondary text-sm lg:text-base mt-2">
                            {data.property_type}
                          </h6>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default PropertyCard;

export const GridPropertyCard = ({
  data,
  handleAddToWishlist,
  handleRemoveWishlist,
}) => {
  return (
    <div className="w-full">
      <div
        className="mt-10 h-[28rem] lg:h-[30rem] relative rounded-xl hover:shadow-md border cursor-pointer slider-card"
        key={data.post_id}
      >
        <Link
          to={`/property-detail?property_category=${data.property_category}&indx=${data.post_id}&key=${data.title}`}
        >
          <div
            className="w-full rounded-t-xl h-12 flex justify-center items-center px-3"
            style={{
              background: `${
                data.company?.company_color
                  ? data.company?.company_color
                  : data.company_color
              }`,
            }}
          >
            <div className="flex items-center gap-x-2 w-full">
              <div className="w-8 h-8 rounded-full">
                {data.athumbnail ? (
                  <img
                    src={data.athumbnail}
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : (
                  <img
                    src={classiBazarLogo}
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                )}
              </div>

              <h4
                className="font-medium text-[0.5rem] md:text-xs"
                style={{ color: `${data.agent_color}` }}
              >
                {data.afname} {data.alname}
              </h4>
            </div>
            <div className="w-24 h-7 overflow-hidden">
              {data.company_logo_thumb ? (
                <img
                  src={data.company_logo_thumb}
                  alt="logo"
                  className="w-full object-contain h-full"
                />
              ) : (
                <img
                  src={classiBazarLogo}
                  alt="logo"
                  className="w-full h-full object-contain"
                />
              )}
            </div>
          </div>
          <div className="w-full h-52 lg:h-56 relative overflow-hidden">
            {data.thumbnail ? (
              <img
                src={data.thumbnail}
                alt="img"
                className="w-full h-full object-cover thumb"
              />
            ) : (
              <img
                src={classiBazarLogo}
                alt="img"
                className="w-full h-full object-contain thumb"
              />
            )}

            {data.ad_status !== "4" && data.spoted === "1" ? (
              <div className="absolute top-1 text-white flex items-center justify-center gap-x-1 bg-primary left-0 py-1 px-5 rounded-r-lg">
                <p className="font-medium capitalize">Spotted</p>
              </div>
            ) : null}

            <div className="absolute bottom-5 text-primary flex items-center justify-center gap-x-1 bg-white left-10 md:left-5 py-1 pl-1 pr-3 rounded-lg">
              <GoPrimitiveDot size={20} />
              <p className="font-medium capitalize">{data.property_category}</p>
            </div>

            {data.ad_status !== "4" && data.urgent === "1" ? (
              <div className="absolute bottom-5 text-white flex items-center justify-center gap-x-1 bg-red-500 right-10 md:right-5 py-1 pl-3 pr-3 rounded-lg">
                <p className="font-medium capitalize">Urgent</p>
              </div>
            ) : null}

            {data.ad_status === "4" ? (
              <div className="absolute bottom-5 text-white flex items-center justify-center gap-x-1 bg-red-500 right-10 md:right-5 py-1 pl-3 pr-3 rounded-lg">
                <p className="font-medium capitalize">{data.status}</p>
              </div>
            ) : null}
          </div>
        </Link>
        <div className="w-11/12 mx-auto mt-4">
          <div className="flex items-center w-full justify-between">
            <h5 className="text-primary font-medium text-base lg:text-lg truncate">
              <Link
                to={`/property-detail?property_category=${data.property_category}&indx=${data.post_id}&key=${data.title}`}
              >
                {data.title}
              </Link>
            </h5>

            <div className="hover:bg-gray-100 rounded-full p-2">
              {data.ad_status === "4" ? null : data.is_wishlist ? (
                <AiFillHeart
                  size={27}
                  className="text-red-500"
                  onClick={() => handleRemoveWishlist(data.post_id)}
                />
              ) : (
                <AiOutlineHeart
                  size={27}
                  className="text-textSecondary"
                  onClick={() => handleAddToWishlist(data.post_id)}
                />
              )}
            </div>
          </div>
          <Link
            to={`/property-detail?property_category=${data.property_category}&indx=${data.post_id}&key=${data.title}`}
          >
            <h4 className="text-textPrimary font-semibold text-xl lg:text-2xl">
              {data.price !== "0"
                ? data.price_type !== "2"
                  ? `$${data.price} ${
                      data.property_category === "rent"
                        ? `/ ${data.price_type2}`
                        : ""
                    }`
                  : `$${data.price} - $${data.to_price}  ${
                      data.property_category === "rent"
                        ? `/  ${data.price_type2}`
                        : ""
                    }`
                : ""}
            </h4>
            <p className="text-textSecondary text-sm mt-3 truncate">
              {data.ad_address}
            </p>

            {/* icons */}
            <div className="flex flex-wrap items-center gap-x-5 absolute bottom-2">
              {data.ads_data &&
                data.ads_data.map((item, i) => {
                  return item.value === null ||
                    item.value === "0" ||
                    item.value === " m sq." ? null : (
                    <div className="flex items-center gap-x-2" key={i}>
                      <img src={item.img} alt="parking" className="w-5 h-5" />

                      <h6 className="text-textPrimary text-base lg:text-lg flex items-center">
                        {item.label === "Area"
                          ? item.value.replace("m sq.", " ")
                          : item.value}
                        <p
                          className={` ${
                            item.label === "Area" ? "block" : "hidden"
                          }`}
                        >
                          {" "}
                          m <sup>2</sup>
                        </p>
                      </h6>
                    </div>
                  );
                })}
              <h6 className="border-l px-2 capitalize text-textSecondary text-sm lg:text-base mt-2">
                {data.property_type}
              </h6>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const PropertyCardSkeleton = () => {
  return (
    <div className="mt-10 h-[28rem] relative rounded-xl hover:shadow-md border border-gray-100 cursor-pointer transition-all duration-300 hover:scale-105 animate-pulse">
      <div className="w-full bg-slate-200 rounded-t-xl h-12 flex justify-center items-center">
        <div className="w-24 h-7"></div>
      </div>
      <hr className="border-slate-50" />
      <div className="w-full h-52 bg-slate-200 lg:h-56 relative">
        <div className="absolute bottom-5 text-primary flex items-center gap-x-2 bg-slate-100 w-20 h-5 left-5 p-2 px-3 rounded-lg"></div>
      </div>
      <div className="w-11/12 mx-auto mt-7">
        <div className="flex items-center justify-between">
          <div className="text-primary font-semibold text-base w-10/12 h-5 rounded-full bg-slate-200 lg:text-lg truncate"></div>
          <div className="hover:bg-gray-100 bg-slate-200 rounded-full w-6 h-6 p-2"></div>
        </div>
        <div className="text-textPrimary bg-slate-200 font-black w-3/4 h-7 rounded-full mt-3 text-xl lg:text-2xl"></div>
        <div className="text-textSecondary bg-slate-200 w-full rounded-full h-4 text-sm mt-4"></div>

        <div className="flex flex-wrap items-center gap-x-5 absolute bottom-2">
          <div className="flex items-center gap-x-2">
            <div className="w-5 h-5 bg-slate-200 rounded-full"></div>
            <div className="text-textPrimary bg-slate-200 w-10 h-3 rounded-full text-base lg:text-lg"></div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="w-5 h-5 bg-slate-200 rounded-full"></div>
            <div className="text-textPrimary bg-slate-200 w-10 h-3 rounded-full text-base lg:text-lg"></div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="w-5 h-5 bg-slate-200 rounded-full"></div>
            <div className="text-textPrimary bg-slate-200 w-10 h-3 rounded-full text-base lg:text-lg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
