import React from "react";
import Avatar from "react-avatar";
import { Button } from "../../Component";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FiCheck } from "react-icons/fi";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchVerifyMobile,
  removeProfileData,
} from "../../Redux/Profile/action";
import Cookies from "js-cookie";
import ProfileSkeleton from "./ProfileSkeleton";
import { useState } from "react";
import ImagePopup from "./Popup/ImagePopup";
import PersonalDetailPopup from "./Popup/PersonalDetailPopup";
import UpdatePassword from "./Popup/UpdatePassword";
import { toast } from "react-toastify";
import OTPpopup from "./Popup/OTPpopup";
import {
  fetchGetUserProfile,
  removeProfilesData,
} from "../../Redux/UserProfile/action";

const Profile = () => {
  const [imagePopupModal, setImagePopupModal] = useState(false);
  const [personalDetailPopup, setPersonalDetailPopup] = useState(false);
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const profile = useSelector((state) => state.profiles.profile, shallowEqual);
  const loading = useSelector(
    (state) => state.profiles.isloading,
    shallowEqual
  );

  const message = useSelector((state) => state.profile.verifyMsg, shallowEqual);
  const errMessage = useSelector(
    (state) => state.profile.verifyErr,
    shallowEqual
  );

  const handleVerifyMobile = (mobile) => {
    scrollToTop();
    const body = {
      mobile_number: mobile,
    };

    dispatch(fetchVerifyMobile(body, token));
  };

  useEffect(() => {
    dispatch(fetchGetUserProfile(token));
  }, [token]);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    message && toast.success(message);
    message && setOtpPopup(true);

    setTimeout(() => {
      dispatch(removeProfileData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.success(errMessage);
    errMessage && dispatch(removeProfilesData());
    errMessage && dispatch(fetchGetUserProfile(token));
  }, [errMessage]);

  if (loading) {
    return (
      <div className="w-full h-full">
        <ProfileSkeleton />
      </div>
    );
  }

  return (
    <div className="w-full h-full relative">
      {imagePopupModal || personalDetailPopup || passwordPopup || otpPopup ? (
        <div
          className="w-full h-full bg-black opacity-10 absolute top-0 rounded-xl"
          onClick={() => {
            setImagePopupModal(false);
            setPersonalDetailPopup(false);
            setPasswordPopup(false);
          }}
        ></div>
      ) : null}
      {imagePopupModal && (
        <ImagePopup setImagePopupModal={setImagePopupModal} />
      )}

      {personalDetailPopup && (
        <PersonalDetailPopup
          setPersonalDetailPopup={setPersonalDetailPopup}
          profile={profile}
        />
      )}

      {passwordPopup && <UpdatePassword setPasswordPopup={setPasswordPopup} />}

      {otpPopup && <OTPpopup setOtpPopup={setOtpPopup} />}
      <div className="w-full h-fit border rounded-xl bg-white">
        <h5 className="font-semibold text-2xl p-4">Profile</h5>
      </div>
      <div className="mt-5 w-full h-fit bg-white border rounded-xl py-5">
        <h6 className="font-medium text-xl text-textPrimary px-5">
          Your Photo
        </h6>
        <hr className="w-full mt-5" />
        <div className="w-full h-full p-5 flex items-center gap-x-2">
          {profile && profile.image ? (
            <div className="h-20 w-20">
              <img
                src={profile.image}
                alt=""
                className="w-full h-full rounded-full"
              />{" "}
            </div>
          ) : (
            <Avatar
              name={profile && profile.first_name}
              size={100}
              round={true}
            />
          )}

          <Button
            value="Upload Photo"
            handleClick={() => setImagePopupModal(!imagePopupModal)}
            className="w-fit px-7 py-3 bg-primary text-white rounded-xl ml-7"
          />
        </div>
      </div>

      <div className="mt-5 w-full h-fit bg-white border rounded-xl py-5">
        <div className="w-full flex items-center justify-between px-5">
          <h6 className="font-medium text-xl text-textPrimary">
            Personal Details
          </h6>
          <Button
            value="Edit"
            handleClick={() => {
              scrollToTop();
              setPersonalDetailPopup(!personalDetailPopup);
            }}
            className="py-2 rounded-xl border borer-xl px-5 transition ease-in-out duration-300 hover:bg-gray-50"
          />
        </div>

        <hr className="w-full mt-5" />
        <div className="w-full pt-5 px-5 lg:px-10 lg:pt-10">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col gap-y-2 w-3/4">
              <p className="text-[0.6rem] md:text-sm text-textSecondary">
                Name
              </p>
              <p className="text-xs md:text-base lg:text-lg text-textPrimary w-3/4 md:w-11/12">
                {profile.first_name} {profile.last_name}
              </p>
            </div>
          </div>
          <hr className="w-full mt-5 lg:mt-7" />
        </div>

        <div className="w-full pt-5 px-5 lg:pt-10 lg:px-10">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col gap-y-2 w-3/4">
              <p className="text-[0.6rem] md:text-sm text-textSecondary">
                Phone Number
              </p>
              {profile.user_mobile ? (
                <div className="flex items-center gap-x-3 w-3/4 md:w-11/12">
                  <p className="text-xs md:text-base lg:text-lg text-textPrimary truncate">
                    {profile.user_mobile}
                  </p>
                  {profile.mobile_verified === "0" && (
                    <h1
                      className="text-red-700 cursor-pointer bg-red-100 rounded-full py-1 text-[0.5rem] md:text-xs lg:text-sm w-fit px-2 md:px-4 flex items-center gap-x-1 md:gap-x-2"
                      onClick={() => handleVerifyMobile(profile.user_mobile)}
                    >
                      <IoIosInformationCircleOutline />
                      <span>Not Verified </span>
                    </h1>
                  )}

                  {profile.mobile_verified === "1" && (
                    <h1 className="text-green-700 bg-green-100 rounded-full py-1 text-[0.5rem] md:text-xs lg:text-sm w-fit px-2 md:px-4 flex items-center gap-x-1 md:gap-x-2">
                      <FiCheck />
                      <span>Verified </span>
                    </h1>
                  )}
                </div>
              ) : (
                <h5>-</h5>
              )}
            </div>
          </div>
          <hr className="w-full mt-5 lg:mt-7" />
        </div>

        <div className="w-full pt-5 px-5 lg:pt-10 lg:px-10">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col gap-y-2 w-3/4">
              <p className="text-[0.6rem] md:text-sm text-textSecondary">
                Email Address
              </p>

              <div className="flex items-center gap-x-3 w-3/4 md:w-11/12">
                <p className="text-xs md:text-base lg:text-lg text-textPrimary truncate">
                  {profile.user_email}
                </p>
                <h1 className="text-green-700 bg-green-100 rounded-full py-1 text-[0.5rem] md:text-xs lg:text-sm w-fit px-2 md:px-4 flex items-center gap-x-1 md:gap-x-2">
                  <FiCheck />
                  <span>Verified </span>
                </h1>
              </div>
            </div>
          </div>
          <hr className="w-full mt-5 lg:mt-7" />
        </div>

        <div className="w-full pt-5 px-5 lg:px-10 lg:pt-10">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col gap-y-2 w-3/4">
              <p className="text-[0.6rem] md:text-sm text-textSecondary">
                Address
              </p>
              {profile.street_address ? (
                <p className="text-xs md:text-base lg:text-lg text-textPrimary w-3/4 md:w-11/12">
                  {profile.street_address}
                </p>
              ) : (
                <h5>-</h5>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 w-full h-fit bg-white border rounded-xl py-5">
        <div className="w-full flex items-center justify-between px-5">
          <h6 className="font-medium text-xl text-textPrimary">Security</h6>

          <Button
            value="Edit"
            handleClick={() => {
              scrollToTop();
              setPasswordPopup(!passwordPopup);
            }}
            className="py-2 rounded-xl border borer-xl px-5 transition ease-in-out duration-300 hover:bg-gray-50"
          />
        </div>
        <hr className="w-full mt-5" />

        <div className="w-full pt-5 px-5 lg:px-10 lg:pt-10">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col gap-y-2 w-full">
              <p className="text-[0.6rem] md:text-sm text-textSecondary">
                Password
              </p>
              <p className="text-textPrimary text-xs md:text-base">
                XXXXXXXXXXXXXXXXX
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
