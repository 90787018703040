import React from "react";
import { classiBazarLogo } from "../../Asset/Assest";
import { Button } from "../index";

const MyEnquiriesCard = ({ myEnquiry, handleClickViewDetail }) => {
  return (
    <div className="w-full h-full bg-white border p-5 rounded-xl hover:shadow-md">
      <div className="w-full flex gap-x-3">
        <div className="w-14 h-14 border p-2 rounded-lg border-green-500">
          <img src={classiBazarLogo} alt="" className="w-full h-full" />
        </div>
        <div className="w-full overflow-hidden">
          <h5 className="font-medium text-xl capitalize truncate">
            {myEnquiry.title}
          </h5>
          <p className="text-textSecondary mt-2 text-sm capitalize truncate2">
            {myEnquiry.message}
          </p>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          value="View Details"
          handleClick={() => handleClickViewDetail(myEnquiry.id)}
          className="border py-2 rounded-xl px-5 transition duration-1000 ease-in-out hover:bg-gray-100 text-textPrimary mt-4 md:py-3"
        />
      </div>
    </div>
  );
};

export default MyEnquiriesCard;
