import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const profileLoading = () => ({
  type: ActionTypes.PROFILE_LOADING,
});

export const removeProfileData = () => ({
  type: ActionTypes.REMOVE_PROILE_DATA,
});

export const fetchGetSavedProperty = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v3/wishlists?api_from=classiEstate&slug=real-estate`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_SAVED_PROPERTY,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_GET_SAVED_PROPERTY,
        payload: e.response.data,
      });
    });
};

export const fetchGetAgentEnquiries = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v3/agent_enquiries?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_AGENT_ENQUIRIES,
        payload: data,
      });
    });
};

export const fetchGetMyInspection = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v3/my_insepection_lists`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_INSPECTION,
        payload: data,
      });
    });
};

export const fetchDeleteInspection = (id, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/delete_inspections_new`, id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_INSPECTION_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DELETE_INSPECTION_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchAddInspection = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/add_inspections_new?api_from=classiEstate`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_INSPECTION_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.ADD_INSPECTION_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchUploadProfile = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/upload_image`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPLOAD_PROFILE_SUCCESSS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPLOAD_PROFILE_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchUpdateProfile = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/profile`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchChangePassword = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/user_update_password`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CHANGE_PASSWORD_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchVerifyMobile = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/verify_mobile`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.VERIFY_MOBILE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.VERIFY_MOBILE_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchVerifyCode = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/add_code`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.VERIFY_CODE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.VERIFY_CODE_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchAgentRequest = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/agency_request`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.AGENT_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.AGENT_REQUEST_FAIL,
        payload: e?.response?.data,
      });
    });
};
