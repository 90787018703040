import React, { useEffect, useState } from "react";
import { Button, FormikControl } from "../../../Component";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { scrollToTop } from "../../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  fetchSendPropertyEnquiry,
  removeEnquiryData,
} from "../../../Redux/Enquiry/action";
import { classiBazarLogo } from "../../../Asset/Assest";

const buy = [
  {
    id: 1,
    name: "Inspection",
    value: "Inspection",
  },

  {
    id: 2,
    name: "Rates & Fees",
    value: "Rates & Fees",
  },

  {
    id: 3,
    name: "Price Information",
    value: "Price Information",
  },

  {
    id: 4,
    name: "Similar Properties",
    value: "Similar Properties",
  },
];

const rent = [
  {
    id: 1,
    name: "Available Date",
    value: "Available Date",
  },

  {
    id: 2,
    name: "Inspection",
    value: "Inspection",
  },

  {
    id: 3,
    name: "Length of Lease",
    value: "Length of Lease",
  },

  {
    id: 4,
    name: "Rental Application",
    value: "Rental Application",
  },
];

const PropertyEnquiry = ({ data, setToggle, postId, category }) => {
  const [selectToggle, setSelectToggle] = useState([]);

  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const profile = useSelector((state) => state.profiles.profile, shallowEqual);

  const message = useSelector((state) => state.enquiry.message, shallowEqual);
  const errMessage = useSelector((state) => state.enquiry.errMsg, shallowEqual);

  const setToogleValues = (value) => {
    if (selectToggle.includes(value)) {
      const test = selectToggle.filter((val) => val !== value);
      setSelectToggle(test);
    } else {
      setSelectToggle((pre) => [...pre, value]);
    }
  };

  console.log("selected", selectToggle);
  //   initial values
  const initialValues = {
    name: profile ? profile.first_name + " " + profile.last_name : "",
    email: profile ? profile.user_email : "",
    phone: profile ? profile.user_mobile : "",
    message: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required *"),
    name: Yup.string().required("Required*"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(9, "Phone number is too short")
      .max(11, "Phone number is too long"),
    message: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();
    const body = {
      inquiry_for: selectToggle,
      message: values.message,
      name: values.name,
      email: values.email,
      phone: values.phone,
      request_type: 1,
      postid: postId,
      copy: "1",
      app_name: "CLASSIESTATE",
    };

    dispatch(fetchSendPropertyEnquiry(body, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({ values: "" });
    }, 1000);
  };

  useEffect(() => {
    message && dispatch(removeEnquiryData());
    message && toast.success(message);
    message && setToggle(false);
  }, [message]);

  useEffect(() => {
    errMessage && dispatch(removeEnquiryData());
    errMessage && toast.error(errMessage);
  }, [errMessage]);

  return (
    <div className="w-11/12 md:w-3/4 lg:w-1/2 left-[4%] md:translate-x-[12%] lg:translate-x-1/2 top-[5%] border rounded-xl p-5 z-40 bg-white absolute">
      <h6 className="font-medium text-base md:text-xl">Send enquiry to</h6>

      {data.map((val) => {
        return (
          <div className="mt-7 flex items-center gap-x-7">
            <div className="w-14 h-14 md:w-20 md:h-20 rounded-full overflow-hidden">
              {val.image_url ? (
                <img
                  src={val.image_url}
                  alt=""
                  className="w-full h-full object-fill 
            "
                />
              ) : (
                <img
                  src={classiBazarLogo}
                  className="w-full h-full rounded-full"
                />
              )}
            </div>
            <div>
              <h5 className="font-medium text-base md:text-lg">
                {val.first_name} {val.last_name}
              </h5>
              {/* <h6 className="text-textSecondary text-xs md:text-sm mt-1">
            {data.company_name}
          </h6> */}
            </div>
          </div>
        );
      })}

      <hr className="w-full mt-6" />

      <div className="mt-5">
        <h6 className="font-medium text-base md:text-xl">
          What's the enquiry about?
        </h6>
        {category === "buy" ? (
          <div className="flex items-center gap-x-5 w-full flex-wrap">
            {buy.map((val) => {
              return (
                <Button
                  value={val.name}
                  handleClick={() => setToogleValues(val.value)}
                  className={`${
                    selectToggle.includes(val.value)
                      ? "border-primary text-primary bg-purple-50"
                      : ""
                  } w-fit px-5 mt-5 py-2 md:py-3 border rounded-xl text-center text-sm md:text-base transition duration-1000 ease-in-out hover:bg-gray-100`}
                />
              );
            })}
          </div>
        ) : (
          <div className="flex items-center gap-x-5 w-full flex-wrap">
            {rent.map((val) => {
              return (
                <Button
                  value={val.name}
                  handleClick={() => setToogleValues(val.value)}
                  className={`${
                    selectToggle.includes(val.value)
                      ? "border-primary text-primary bg-purple-50"
                      : ""
                  } w-fit px-5 mt-5 py-2 md:py-3 border rounded-xl text-center text-sm md:text-base transition duration-1000 ease-in-out hover:bg-gray-100`}
                />
              );
            })}
          </div>
        )}
      </div>

      <hr className="w-full mt-6" />
      <div className="mt-5">
        <h6 className="font-medium text-base md:text-xl">Details</h6>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full flex flex-col md:flex-row items-center justify-between gap-x-5 mt-5">
                <div className="w-full">
                  <FormikControl
                    label="Full Name"
                    control="input"
                    name="name"
                    placeholder="Enter Your Full Name"
                  />
                </div>
                <div className="w-full mt-5 md:mt-0">
                  <FormikControl
                    label="Phone Number"
                    control="input"
                    name="phone"
                    type="number"
                    placeholder="Enter Your Phone Number"
                  />
                </div>
              </div>

              <div className="w-full mt-5">
                <FormikControl
                  label="Email Address"
                  control="input"
                  name="email"
                  type="email"
                  placeholder="Enter Your Email Address"
                />
              </div>

              <div className="w-full mt-5">
                <FormikControl
                  label="Message"
                  control="textarea"
                  name="message"
                  type="email"
                  placeholder="Type Your Message Here..."
                />
              </div>

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <div
                  className="border w-full rounded-2xl text-primary cursor-pointer font-medium py-4 text-center "
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setToggle(false)}
                >
                  <Button value="Cancel" />
                </div>

                <Button
                  value="Submit"
                  type="submit"
                  className="border w-full rounded-2xl bg-primary cursor-pointer text-white text-sm md:text-base font-medium py-4 text-center"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PropertyEnquiry;
