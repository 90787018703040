import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { GrClose } from "react-icons/gr";
import { Button } from "../index";
import {
  fetchGetDefaultSearch,
  fetchGetExploreSearch,
  fetchGetSearchResults,
} from "../../Redux/Search/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { fetchGetBanner } from "../../Redux/Banner/action";

const FilterMenu = ({
  showFilter,
  setShowFilter,
  filters,
  value,
  setValue,
  filterData,
  propTypes,
}) => {
  const [categortyToggle, setCategoryToggle] = useState(true);

  const [clickClear, setClickClear] = useState(false);

  const [propertyAddress, SetPropertyAddress] = useState([]);
  const [propertyState, setPropertyState] = useState([]);
  const [propertyStreetName, setPropertyStreetName] = useState([]);
  const [propertySuburb, setPropertySuburb] = useState([]);

  const [propertyCategory, setPropertyCategory] = useState("buy");
  const [propertyType, setPropertyType] = useState([]);
  const [numOfRooms, setNumOfRooms] = useState("");
  const [numOfBathroom, setNumberOfBathroom] = useState("");
  const [numOfCarSpace, setNumOfCarSpace] = useState("");

  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = Cookies.get("token");

  const data = filters?.find((val) => val.attr.name === "property_type");
  const property_type = data?.childs.filter(
    (val) => val.nature === "residential"
  );

  const no_of_room = filters?.find((val) => val.attr.name === "no_of_room");
  const no_of_bathroom = filters?.find(
    (val) => val.attr.name === "no_of_bathroom"
  );

  const no_of_carspace = filters?.find(
    (val) => val.attr.name === "no_of_carspace"
  );

  const handleClickBuy = () => {
    setPropertyCategory("buy");
    setCategoryToggle(true);
  };

  const handleClickRent = () => {
    setPropertyCategory("rent");
    setCategoryToggle(false);
  };

  const handleSelectPropertyType = (e, value) => {
    propertyType.includes(value)
      ? setPropertyType((pre) => [...pre.filter((val) => val !== value)])
      : setPropertyType((pre) => [...pre, value]);
  };

  const handleSelectRooms = (val) => {
    setNumOfRooms(val);
  };

  const handleSelectBathrooms = (val) => {
    setNumberOfBathroom(val);
  };

  const handleSelectCarSpace = (val) => {
    setNumOfCarSpace(val);
  };

  const handleClickClearFilter = () => {
    setClickClear(true);
    setValue("");
    setPropertyType([]);
    setNumOfRooms("");
    setNumberOfBathroom("");
    setNumOfCarSpace("");
    setMinPrice("");
    setMaxPrice("");
    setMinArea("");
    setMaxArea("");
  };

  useEffect(() => {
    filterData && !clickClear && setPropertyType(filterData.type);
    filterData && !clickClear && setNumOfRooms(filterData.bedroom);
    filterData && !clickClear && setNumberOfBathroom(filterData.bathroom);
    filterData && !clickClear && setNumOfCarSpace(filterData.parking);
    filterData && !clickClear && setPropertyCategory(filterData.category);
    filterData && !clickClear && setMinPrice(filterData.minPrice);
    filterData && !clickClear && setMaxPrice(filterData.maxPrice);
    filterData && !clickClear && setMinArea(filterData.minArea);
    filterData && !clickClear && setMaxArea(filterData.maxArea);
    filterData &&
      !clickClear &&
      setCategoryToggle(
        filterData.category === "buy"
          ? true
          : filterData.category === "rent"
          ? false
          : null
      );
  }, [filterData, clickClear]);

  const handleClickSearch = () => {
    localStorage.setItem("Search-value", JSON.stringify(value));

    if (filterData) {
      if (
        ((propertyAddress && propertyAddress.length) ||
          (propertyState && propertyState.length) ||
          (propertyStreetName && propertyStreetName.length) ||
          (propertySuburb && propertySuburb.length) ||
          (propertyCategory && propertyCategory.length) ||
          (numOfRooms && numOfRooms.length) ||
          (numOfBathroom && numOfBathroom.length) ||
          (numOfCarSpace && numOfCarSpace.length) ||
          (propertyType && propertyType.length) ||
          (minPrice && minPrice.length) ||
          (maxPrice && maxPrice.length) ||
          (minArea && minArea.length) ||
          (maxArea && maxArea.length)) &&
        !filterData.lat &&
        !filterData.is_featured
      ) {
        dispatch(
          fetchGetSearchResults(
            propertyCategory,
            propertyAddress,
            propertyStreetName,
            propertySuburb,
            propertyState,
            numOfRooms,
            numOfBathroom,
            numOfCarSpace,
            propertyType,
            minPrice,
            maxPrice,
            minArea,
            maxArea,
            1,
            token
          )
        );

        navigate(
          `/search?property_category=${propertyCategory}&key=${propertyAddress}`
        );

        setShowFilter(false);
      } else if (
        ((propertyAddress && propertyAddress.length) ||
          (propertyState && propertyState.length) ||
          (propertyStreetName && propertyStreetName.length) ||
          (propertySuburb && propertySuburb.length) ||
          (propertyCategory && propertyCategory.length) ||
          (numOfRooms && numOfRooms.length) ||
          (numOfBathroom && numOfBathroom.length) ||
          (numOfCarSpace && numOfCarSpace.length) ||
          (propertyType && propertyType.length) ||
          (minPrice && minPrice.length) ||
          (maxPrice && maxPrice.length) ||
          (minArea && minArea.length) ||
          (maxArea && maxArea.length)) &&
        filterData.lat &&
        !filterData.is_featured
      ) {
        dispatch(
          fetchGetDefaultSearch(
            propertyCategory,
            25.2744,
            133.7751,
            numOfRooms,
            numOfBathroom,
            numOfCarSpace,
            propertyType,
            minPrice,
            maxPrice,
            minArea,
            maxArea,
            1,
            token
          )
        );

        navigate(`/search?property_category=${propertyCategory}`);

        setShowFilter(false);
      } else if (
        ((propertyAddress && propertyAddress.length) ||
          (propertyState && propertyState.length) ||
          (propertyStreetName && propertyStreetName.length) ||
          (propertySuburb && propertySuburb.length) ||
          (propertyCategory && propertyCategory.length) ||
          (numOfRooms && numOfRooms.length) ||
          (numOfBathroom && numOfBathroom.length) ||
          (numOfCarSpace && numOfCarSpace.length) ||
          (propertyType && propertyType.length) ||
          (minPrice && minPrice.length) ||
          (maxPrice && maxPrice.length) ||
          (minArea && minArea.length) ||
          (maxArea && maxArea.length)) &&
        filterData.lat &&
        filterData.is_featured
      ) {
        dispatch(
          fetchGetExploreSearch(
            propertyCategory,
            25.2744,
            133.7751,
            filterData.is_featured,
            numOfRooms,
            numOfBathroom,
            numOfCarSpace,
            propertyType,
            minPrice,
            maxPrice,
            minArea,
            maxArea,
            1,
            token
          )
        );

        navigate(
          `/search?property_category=${propertyCategory}&type=${propTypes}`
        );

        setShowFilter(false);
      }

      if (propertyState || propertySuburb) {
        dispatch(
          fetchGetBanner(
            propertyState,
            propertySuburb,
            propertyCategory === "buy" ? "1166" : "1167"
          )
        );
      } else {
        dispatch(fetchGetBanner(propertyCategory === "buy" ? "1166" : "1167"));
      }
    } else {
      dispatch(
        fetchGetSearchResults(
          propertyCategory,
          propertyAddress,
          propertyStreetName,
          propertySuburb,
          propertyState,
          numOfRooms,
          numOfBathroom,
          numOfCarSpace,
          propertyType,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          1,
          token
        )
      );

      propertyState || propertySuburb
        ? dispatch(
            fetchGetBanner(
              propertyState,
              propertySuburb,
              propertyCategory === "buy" ? "1166" : "1167"
            )
          )
        : dispatch(
            fetchGetBanner(propertyCategory === "buy" ? "1166" : "1167")
          );

      navigate(
        `/search?property_category=${propertyCategory}&key=${
          propertyAddress ? propertyAddress : "all"
        }`
      );

      setShowFilter(false);
    }
  };

  const fetchLocationData = async () => {
    if (value && value.length) {
      const search = value.map((val) => val.label);

      const promises = search.map(async (item) => {
        return await geocodeByAddress(item);
      });
      const geocodes = await Promise.all(promises);
      const description = geocodes.map((item) => item[0]);

      // property address
      description.map((val, i) => {
        return SetPropertyAddress((pre) => [
          ...new Set([...pre, val.formatted_address]),
        ]);
      });

      // property state
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes("administrative_area_level_1")
        );

        data.map((val) => {
          return setPropertyState((pre) => [
            ...new Set([...pre, val.short_name]),
          ]);
        });
      });

      // property street name
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes("route")
        );

        data.map((val) => {
          return setPropertyStreetName((pre) => [
            ...new Set([...pre, val.long_name]),
          ]);
        });
      });

      // property Suburb
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes(
            "locality" || "political" || "administrative_area_level_2"
          )
        );

        data.map((val) => {
          return setPropertySuburb((pre) => [
            ...new Set([...pre, val.long_name]),
          ]);
        });
      });
    } else {
      SetPropertyAddress("");
      setPropertyState("");
      setPropertyStreetName("");
      setPropertySuburb("");
    }
  };

  useEffect(() => {
    fetchLocationData();
  }, [value]);

  return (
    <div
      className={` bg-white fixed right-0 top-0 pb-10 h-full w-11/12 sm:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-2/5 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        showFilter ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="flex justify-between w-full p-5 items-center">
        <h5 className=" text-xl p-2 font-medium">Filter Your Search</h5>

        <div
          className="hover:border cursor-pointer hover:bg-gray-100 rounded-full p-2"
          onClick={() => setShowFilter(false)}
        >
          <GrClose className="text-lg sm:text-xl md:text-2xl" />
        </div>
      </div>
      <hr />

      {/* property category */}
      <div className="flex justify-between items-center gap-x-4 py-4">
        <h5
          className={`${
            categortyToggle === true &&
            "text-textPrimary border-b border-b-textPrimary font-medium"
          } text-xl w-full pb-4 text-center cursor-pointer`}
          onClick={handleClickBuy}
        >
          Buy
        </h5>
        <h5
          className={`${
            categortyToggle === false &&
            "text-textPrimary border-b border-b-textPrimary font-medium"
          } text-xl w-full pb-4 text-center cursor-pointer`}
          onClick={handleClickRent}
        >
          Rent
        </h5>
      </div>

      {/* location */}
      <div className="mt-3 p-5">
        <h5 className="text-xl font-medium text-textPrimary">Location</h5>
        <div className="w-full mt-5">
          <GooglePlacesAutocomplete
            placeholder="Where is it?"
            textInputProps={{
              placeholderTextColor: "#32a852",
              returnKeyType: "search",
            }}
            autocompletionRequest={{
              componentRestrictions: { country: ["au"] },
            }}
            selectProps={{
              value,
              onChange: setValue,
              isMulti: true,
            }}
          />
        </div>
      </div>
      <hr className="mt-4" />

      {/* property Type */}
      <div className="mt-3 p-5">
        <h5 className="text-xl font-medium text-textPrimary">Property Type</h5>
        <div className="flex items-center gap-x-5 flex-wrap">
          {property_type?.map((val, i) => {
            return (
              <Button
                value={val.label}
                key={i}
                className={`rounded-lg w-fit px-5 py-3 mt-5 transition duration-300 ease-in-out hover:border-primary border  ${
                  propertyType?.includes(val.value)
                    ? "border-primary text-primary bg-purple-100 font-medium"
                    : " border-textSecondary text-textPrimary hover:bg-purple-50"
                }`}
                handleClick={(e) => handleSelectPropertyType(e, val.value)}
              />
            );
          })}

          {propertyType && propertyType.length ? (
            <Button
              value="Clear"
              className="w-fit py-3 p-5 border mt-5 rounded-lg border-red-500 text-red-500 transition duration-1000 ease-in-out hover:bg-red-50"
              handleClick={() => setPropertyType("")}
            />
          ) : null}
        </div>
      </div>
      <hr className="mt-4" />

      {/* number of bedrooms */}
      <div className="mt-3 p-5">
        <h5 className="text-xl font-medium text-textPrimary">Bedroom</h5>
        <div className="flex items-center gap-x-5 flex-wrap">
          {no_of_room?.childs?.map((val, i) => {
            return (
              <Button
                value={val.label}
                key={i}
                className={`rounded-lg w-fit px-5 py-3 mt-5 transition duration-300 ease-in-out hover:border-primary border  ${
                  numOfRooms === val.value
                    ? "border-primary text-primary bg-purple-100 font-medium"
                    : " border-textSecondary text-textPrimary hover:bg-purple-50"
                }`}
                handleClick={() => handleSelectRooms(val.value)}
              />
            );
          })}

          {numOfRooms && (
            <Button
              value="Clear"
              className="w-fit py-3 p-5 border mt-5 rounded-lg border-red-500 text-red-500 transition duration-1000 ease-in-out hover:bg-red-50"
              handleClick={() => setNumOfRooms("")}
            />
          )}
        </div>
      </div>

      <hr className="mt-4" />

      {/* number of bathrooms */}
      <div className="mt-3 p-5">
        <h5 className="text-xl font-medium text-textPrimary">Bathroom</h5>
        <div className="flex items-center gap-x-5 flex-wrap">
          {no_of_bathroom?.childs?.map((val, i) => {
            return (
              <Button
                value={val.label}
                key={i}
                className={`rounded-lg w-fit px-5 py-3 mt-5 transition duration-300 ease-in-out hover:border-primary border  ${
                  numOfBathroom === val.value
                    ? "border-primary text-primary bg-purple-100 font-medium"
                    : " border-textSecondary text-textPrimary hover:bg-purple-50"
                }`}
                handleClick={() => handleSelectBathrooms(val.value)}
              />
            );
          })}
          {numOfBathroom && (
            <Button
              value="Clear"
              className="w-fit py-3 p-5 border mt-5 rounded-lg border-red-500 text-red-500 transition duration-1000 ease-in-out hover:bg-red-50"
              handleClick={() => setNumberOfBathroom("")}
            />
          )}
        </div>
      </div>

      <hr className="mt-4" />

      {/* number of car spaces */}
      <div className="mt-3 p-5">
        <h5 className="text-xl font-medium text-textPrimary">Car Space</h5>
        <div className="flex items-center gap-x-5 flex-wrap">
          {no_of_carspace?.childs?.map((val, i) => {
            return (
              <Button
                value={val.label}
                key={i}
                className={`rounded-lg w-fit px-5 py-3 mt-5 transition duration-300 ease-in-out hover:border-primary border  ${
                  numOfCarSpace === val.value
                    ? "border-primary text-primary bg-purple-100 font-medium"
                    : " border-textSecondary text-textPrimary hover:bg-purple-50"
                }`}
                handleClick={() => handleSelectCarSpace(val.value)}
              />
            );
          })}
          {numOfCarSpace && (
            <Button
              value="Clear"
              className="w-fit py-3 p-5 border mt-5 rounded-lg border-red-500 text-red-500 transition duration-1000 ease-in-out hover:bg-red-50"
              handleClick={() => setNumOfCarSpace("")}
            />
          )}
        </div>
      </div>

      <hr className="mt-4" />

      {/* price range */}
      <div className="mt-3 p-5">
        <h5 className="text-xl font-medium text-textPrimary">Price Range</h5>
        <div className="flex items-center gap-x-5 flex-wrap md:flex-nowrap w-full mt-5">
          <div className="">
            <label className="text-sm px-3">Min Price:</label>
            <div className="w-full flex items-center  mt-3">
              <input
                type="number"
                placeholder="AUD"
                min="0"
                value={minPrice}
                className="w-full border py-3 px-5 outline-none text-sm rounded-l-full"
                onChange={(e) => setMinPrice(e.target.value)}
              />
              <h1 className="border py-2.5 px-5 rounded-r-full text-textSecondary">
                $
              </h1>
            </div>
          </div>

          <div className="mt-5 md:mt-0">
            <label className="text-sm pb-1 px-3">Max Price:</label>
            <div className="w-full flex items-center  mt-3">
              <input
                type="number"
                placeholder="AUD"
                min="0"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
                className="w-full border py-3 px-5 outline-none text-sm rounded-l-full"
              />
              <h1 className="border py-2.5 px-5 rounded-r-full text-textSecondary">
                $
              </h1>
            </div>
          </div>
        </div>
      </div>

      <hr className="mt-4" />
      {/* area size */}
      <div className="mt-3 p-5">
        <h5 className="text-xl font-medium text-textPrimary">Area Size</h5>

        <div className="flex items-center gap-x-5 flex-wrap md:flex-nowrap w-full mt-5">
          <div className="">
            <label className="text-sm px-3">Min Area:</label>
            <div className="w-full flex items-center  mt-3">
              <input
                type="number"
                placeholder="Area"
                min="0"
                value={minArea}
                onChange={(e) => setMinArea(e.target.value)}
                className="w-full border py-3 px-5 outline-none text-sm rounded-l-full"
              />
              <h1 className="border py-3 px-5 rounded-r-full text-textSecondary text-sm">
                m<sup>2</sup>
              </h1>
            </div>
          </div>

          <div className="mt-5 md:mt-0">
            <label className="text-sm pb-1 px-3">Max Area:</label>
            <div className="w-full flex items-center  mt-3">
              <input
                type="number"
                placeholder="Area"
                min="0"
                value={maxArea}
                onChange={(e) => setMaxArea(e.target.value)}
                className="w-full border py-3 px-5 outline-none text-sm rounded-l-full"
              />
              <h1 className="border py-3 text-sm px-5 rounded-r-full text-textSecondary">
                m<sup>2</sup>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <hr className="mt-4" />
      {/* buttons */}
      <div className="flex justify-between flex-wrap md:flex-nowrap p-5 mt-5 items-center w-full">
        <Button
          value="Clear Filter"
          className="w-full md:w-fit px-10 border-red-500 text-red-500 py-3 border rounded-lg transition duration-1000 ease-in-out hover:bg-red-50 cursor-pointer"
          handleClick={handleClickClearFilter}
        />

        <Button
          value="Search"
          className="w-full mt-5 md:mt-0 md:w-fit px-10 py-4 rounded-lg bg-primary text-white"
          handleClick={handleClickSearch}
        />
      </div>
    </div>
  );
};

export default FilterMenu;
