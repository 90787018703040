export const SEARCH_LOADING = "SEARCH_LOADING";
export const GET_SEARCH_RESULT = "GET_SEARCH_RESULT";
export const GET_DEFAULT_SEARCH = "GET_DEFAULT_SEARCH";
export const SAVE_SEARCH_DATA_SUCCESS = "SAVE_SEARCH_DATA_SUCCESS";
export const SAVE_SEARCH_DATA_FAIL = "SAVE_SEARCH_DATA_FAIL";
export const GET_SAVE_SEARCH = "GET_SAVE_SEARCH";
export const DELETE_SAVE_SEARCH_SUCCESS = "DELETE_SAVE_SEARCH_SUCCESS";
export const DELETE_SAVE_SEARCH_FAIL = "DELETE_FAIL_SEARCH_FAIL";
export const GET_INSPECTION_DATA = "GET_INSPECTION_DATA";

export const EXPLORE_MORE = "EXPLORE_MORE";
export const REMOVE_SEARCH_DATA = "REMOVE_SEARCH_DATA";
