import React, { useEffect, useState } from "react";
import Header from "./Header";
import { BsArrowRight } from "react-icons/bs";
import { classiEstate } from "../../Asset/Assest";
import {
  Button,
  Download,
  ExploreCard,
  ExploreMore,
  FilterMenu,
  GridNewsCard,
  GridPropertyCard,
  NewsCard,
  PropertyCard,
} from "../../Component";
import { ExploreClassiEstate } from "../../Json";

import {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
} from "../../Resources/Resources";
import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetFeaturedProperties,
  fetchGetLatestProperties,
  removePropertiesData,
} from "../../Redux/Property/action";
import Loading from "../../Component/Loading/Loading";
import Cookies from "js-cookie";
import {
  fetchAddToWishlist,
  fetchRemoveWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";
import { fetchGetAllNewsData, removeNewsData } from "../../Redux/News/action";
import { fetchGetExploreSearch } from "../../Redux/Search/action";
import Footer from "./Footer";

const Home = () => {
  const [activeBuy, setActiveBuy] = useState(true);
  const [activeRent, setActiveRent] = useState(false);
  const [activeAgent, setActiveAgent] = useState(false);
  const [activeAgency, setActiveAgency] = useState(false);
  const [propertyCategory, setPropertyCategory] = useState("buy");
  const [showFilter, setShowFilter] = useState(false);
  const [value, setValue] = useState("");

  const loading = useSelector(
    (state) => state.property.isloading,
    shallowEqual
  );

  const latestLoading = useSelector(
    (state) => state.property.latestLoading,
    shallowEqual
  );
  const latestProperty = useSelector(
    (state) => state.property.latestProperty,
    shallowEqual
  );

  const featuredProperty = useSelector(
    (state) => state.property.featuredProperty,
    shallowEqual
  );

  const news = useSelector((state) => state.news.news, shallowEqual);
  const newsLoading = useSelector(
    (state) => state.news.isloading,
    shallowEqual
  );

  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.wishlist.errMsg,
    shallowEqual
  );

  const filters = useSelector((state) => state.property.filters, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = Cookies.get("token");

  const data = localStorage.getItem("search-property");
  const parsedData = JSON.parse(data);

  const handleClickBuy = () => {
    setActiveBuy(true);
    setActiveRent(false);
    setActiveAgent(false);
    setActiveAgency(false);
    setPropertyCategory("buy");
  };

  const handleClickRent = () => {
    setActiveBuy(false);
    setActiveRent(true);
    setActiveAgent(false);
    setActiveAgency(false);
    setPropertyCategory("rent");
  };

  const handleClickAgent = () => {
    setActiveBuy(false);
    setActiveRent(false);
    setActiveAgent(true);
    setActiveAgency(false);
  };

  const handleClickAgency = () => {
    setActiveBuy(false);
    setActiveRent(false);
    setActiveAgent(false);
    setActiveAgency(true);
  };

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddToWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlist(body, token));
  };

  const exploreMoreLastest = () => {
    dispatch(
      fetchGetExploreSearch(
        propertyCategory,
        25.2744,
        133.7751,
        2,
        null,
        null,
        null,
        [],
        null,
        null,
        null,
        null,
        1,
        token
      )
    );

    localStorage.removeItem("Search-value");
    navigate(
      `/search?property_category=${propertyCategory}&type="latest-properties"`
    );
  };

  const exploreMoreFeatured = () => {
    dispatch(
      fetchGetExploreSearch(
        propertyCategory,
        25.2744,
        133.7751,
        1,
        null,
        null,
        null,
        [],
        null,
        null,
        null,
        null,
        1,
        token
      )
    );
    localStorage.removeItem("Search-value");
    navigate(
      `/search?property_category=${propertyCategory}&type="featured-properties"`
    );
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetLatestProperties("Buy", token));
    dispatch(fetchGetFeaturedProperties("buy", token));
    scrollToTop();
    return () => {
      dispatch(removePropertiesData());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchGetAllNewsData());
    return () => {
      dispatch(removeNewsData());
    };
  }, []);

  useEffect(() => {
    message && dispatch(removePropertiesData());
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    message && dispatch(fetchGetLatestProperties("Buy", token));
    message && dispatch(fetchGetFeaturedProperties("buy", token));
  }, [message]);

  useEffect(() => {
    errMessage && dispatch(removePropertiesData());
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeWishlistData());
    errMessage && dispatch(fetchGetLatestProperties("Buy", token));
    errMessage && dispatch(fetchGetFeaturedProperties("buy", token));
  }, [errMessage]);

  if (loading || newsLoading || latestLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 relative">
      {showFilter && (
        <div
          className="w-full h-full bg-black z-50 top-0 absolute opacity-10"
          onClick={() => setShowFilter(false)}
        ></div>
      )}
      <FilterMenu
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        value={value}
        setValue={setValue}
        filters={filters}
        // filterData={parsedData}
      />
      <Header
        activeBuy={activeBuy}
        activeRent={activeRent}
        activeAgent={activeAgent}
        activeAgency={activeAgency}
        handleClickBuy={handleClickBuy}
        handleClickRent={handleClickRent}
        handleClickAgent={handleClickAgent}
        handleClickAgency={handleClickAgency}
        propertyCategory={propertyCategory}
        setShowFilter={setShowFilter}
        value={value}
        setValue={setValue}
      />

      {/* latest properties */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-28 md:mt-16">
        {latestProperty && latestProperty.length ? (
          <>
            {latestProperty.length > 3 ? (
              <PropertyCard
                title="Latest Property"
                data={latestProperty}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
              />
            ) : (
              <>
                <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
                  Latest Property
                </h4>
                <hr className="w-20 border border-primary mt-4" />
                <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                  {latestProperty.map((val) => {
                    return (
                      <div key={val.post_id}>
                        <GridPropertyCard
                          data={val}
                          handleAddToWishlist={handleAddToWishlist}
                          handleRemoveWishlist={handleRemoveWishlist}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <ExploreMore
              value="Explore more latest properties"
              handleClick={exploreMoreLastest}
            />
          </>
        ) : null}
      </div>

      {/* make a enquiry section */}
      <div
        className="w-full py-16 mt-12 flex px-5 flex-col justify-center border items-center"
        style={{ background: "#FAFBFB" }}
      >
        <div className="w-32 md:w-40 h-24 md:h-32">
          <img src={classiEstate} alt="logo" className="w-full h-full" />
        </div>

        <h3 className="text-primary font-semibold text-xl sm:text-2xl md:text-3xl lg:text-4xl mt-10">
          Looking for a property to buy or rent?
        </h3>
        <h5 className="text-textPrimary font-medium text-sm sm:text-lg md:text-xl lg:text-2xl mt-4">
          Don’t take a stress and leave the rest to our experts.
        </h5>
        <Button
          value="Make a Quick Enquiry"
          className="bg-primary rounded-xl text-white py-3 md:py-4 px-5 md:px-10 mt-10"
          handleClick={() => navigate("/agent-enquiry")}
        />
      </div>

      {/* featured properties */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-12">
        {featuredProperty && featuredProperty.length ? (
          <>
            {featuredProperty.length > 3 ? (
              <PropertyCard
                title="Featured Property"
                data={featuredProperty}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
              />
            ) : (
              <>
                <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
                  Featured Property
                </h4>
                <hr className="w-20 border border-primary mt-4" />
                <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                  {featuredProperty.map((val) => {
                    return (
                      <div key={val.post_id}>
                        <GridPropertyCard
                          data={val}
                          handleAddToWishlist={handleAddToWishlist}
                          handleRemoveWishlist={handleRemoveWishlist}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <ExploreMore
              value="Explore more featured properties"
              handleClick={exploreMoreFeatured}
            />
          </>
        ) : null}
      </div>

      {/* explore classiestate */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-20">
        <ExploreCard
          title="Explore property with Classiestate"
          data={ExploreClassiEstate}
        />
      </div>

      {/* agents in area */}
      <div
        className="w-full h-full py-8 md:py-20 mt-12"
        style={{ background: "#FAFBFB" }}
      >
        <div className="w-11/12 xl:w-3/4 mx-auto h-full grid grid-cols-12 lg:gap-x-10">
          <div className="col-span-12 lg:col-span-7 w-full h-full py-0 lg:py-12">
            <h4 className="text-textPrimary text-lg md:text-2xl xl:text-3xl font-semibold">
              Find real estate agents in your area
            </h4>
            <hr className="w-20 border border-primary mt-4" />
            <p className="text-lg text-textPrimary tracking-wider mt-12 xl:mt-16 w-11/12 ">
              Compare 60,000+ agents and property managers on Homely to find the
              best match for you. Narrow down your agent search by experience,
              ratings and more.
            </p>
            <Link to="/find-agents">
              <div className="mt-16 flex items-center gap-x-5 cursor-pointer">
                <h6 className="text-green-600 font-medium text-lg tracking-wider">
                  Find agents in all the suburbs
                </h6>
                <BsArrowRight size={22} className="text-green-600" />
              </div>
            </Link>
          </div>
          <div className="col-span-12 mt-7 lg:mt-0 lg:col-start-8 lg:col-end-13 w-full h-full flex flex-col items-center justify-center">
            {/* css for designing circle area */}
            <div className="w-[350px] h-[350px] xl:w-[450px] xl:h-[450px] border-2 border-dashed rounded-full relative flex justify-center items-center">
              <img
                src={img6}
                alt=""
                className="absolute top-0 left-10 w-20 h-20 xl:w-24 xl:h-24"
              />
              <img
                src={img7}
                alt=""
                className="absolute bottom-0 right-6 w-20 h-20 xl:w-24 xl:h-24"
              />

              <div className="w-64 h-64 xl:w-80 xl:h-80 border-2 rounded-full border-dashed relative flex justify-center items-center">
                <img
                  src={img3}
                  alt=""
                  className="absolute -left-10 top-20 w-20 h-20 xl:w-24 xl:h-24"
                />
                <img
                  src={img4}
                  alt=""
                  className="absolute left-10 -bottom-10 w-20 h-20 xl:w-24 xl:h-24"
                />
                <img
                  src={img5}
                  alt=""
                  className="absolute -right-10 bottom-16 w-20 h-20 xl:w-24 xl:h-24"
                />

                <div className="w-40 h-40 xl:w-48 xl:h-48 border-2 rounded-full border-dashed relative flex justify-center items-center">
                  <img
                    src={img2}
                    alt=""
                    className="absolute -top-8 xl:-top-10 right-0 w-20 h-20 xl:24 xl:h-24"
                  />

                  <div className="w-20 h-20 xl:w-24 xl:h-24">
                    <img
                      src={img1}
                      className="w-full h-full object-fill"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Articles */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-12">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Latest Property Articles
        </h4>
        <hr className="w-20 border border-primary mt-4" />
        {news.latest_news && news.latest_news.length ? (
          <>
            {news.latest_news.length > 4 ? (
              <NewsCard
                title="Latest Property Articles"
                data={news.latest_news}
              />
            ) : (
              <GridNewsCard
                title="Latest Property Articles"
                data={news.latest_news}
              />
            )}

            <ExploreMore
              value="Explore more articles about properties"
              handleClick={() => navigate("/news")}
            />
          </>
        ) : null}
      </div>

      {/* download the app section */}
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <Download />
      </div>
      {/* footer */}
      <div className="mt-14 md:mt-32 w-11/12 xl:w-3/4 mx-auto">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
