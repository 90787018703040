import React, { useEffect, useMemo, useState } from "react";
import Header from "./Header";
import { scrollToTop } from "../../Component/NavigateTop";
import { Home } from "../../Asset/Assest";
import {
  Download,
  ExploreCard,
  GridNewsCard,
  NewsCard,
  NewsCardSkeleton,
} from "../../Component";
import { ExploreAgent } from "../../Json";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetAgentAgencySearchSuggestion,
  removeAgentAgencyData,
} from "../../Redux/Agent-Agencies/action";
import debounce from "lodash.debounce";
import { fetchGetAllNewsData, removeNewsData } from "../../Redux/News/action";
import Loading from "../../Component/Loading/Loading";

const Agent = () => {
  const [toggle, setToggle] = useState({
    agent: true,
    agency: false,
  });
  const [searchAgents, setSearchAgents] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const news = useSelector((state) => state.news.news, shallowEqual);
  const newsLoading = useSelector(
    (state) => state.news.isloading,
    shallowEqual
  );

  const suggestions = useSelector(
    (state) => state.agentagencies.suggestions,
    shallowEqual
  );

  const tipForBuying = news?.categories?.filter(
    (val) => val.name === "Buying and Building New"
  );

  const agentSuggestion = suggestions.filter((val) => val.type === "Agent");
  const locationSuggestions = suggestions.filter(
    (val) => val.type === "Address"
  );

  const onchangeSearchHandler = (e) => {
    setSearchAgents(e.target.value);
  };
  useEffect(() => {
    dispatch(fetchGetAgentAgencySearchSuggestion(searchAgents));
    return () => {
      dispatch(removeAgentAgencyData());
    };
  }, [searchAgents]);

  useEffect(() => {
    dispatch(fetchGetAllNewsData());
    return () => {
      dispatch(removeNewsData());
    };
  }, []);

  const debouncedResults = useMemo(() => {
    return debounce(onchangeSearchHandler, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleClickSuggestion = (name) => {
    navigate(`/find-agents/agents?search=${name}`);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  if (newsLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="py-16 md:py-24">
      <Header
        title="Find top real estate agents in your area"
        subTitle="100% free to sellers - compare real estate agents by sales and reviews"
        img={Home}
        handleClick={() =>
          navigate(`/find-agents/agents?search=${searchAgents}`)
        }
        onchangeSearchHandler={debouncedResults}
        nameSuggestion={agentSuggestion}
        locationSuggestions={locationSuggestions}
        disable={false}
        handleClickSuggestion={handleClickSuggestion}
      />

      {/* explore gents */}
      <div className="w-11/12 md:w-3/4  mx-auto mt-10 md:mt-20">
        <ExploreCard title="Looking to buy a place?" data={ExploreAgent} />
      </div>
      {/* download the app section */}
      <div className="w-11/12 xl:w-3/4 mt-12 md:mt-28 mx-auto">
        <Download />
      </div>

      {/* Tips for buy properties */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-24">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Tips for Buying Properties
        </h4>
        <hr className="w-20 border border-primary mt-4" />
        {tipForBuying && tipForBuying.length ? (
          tipForBuying.length > 4 ? (
            <NewsCard
              title="Tips for Buying Properties"
              data={tipForBuying?.[0]?.news}
            />
          ) : (
            <GridNewsCard
              title="Tips for Buying Properties"
              data={tipForBuying?.[0]?.news}
            />
          )
        ) : (
          <NewsCardSkeleton />
        )}
      </div>

      {/* footer */}
      <div className="w-11/12 xl:w-3/4  mx-auto mt-14">
        <Footer toggle={toggle} setToggle={setToggle} />
      </div>
    </div>
  );
};

export default Agent;
