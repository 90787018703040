import React, { useEffect, useState } from "react";
import { logo } from "../../Asset/Assest";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Button, FormikControl } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchChangePassword, removeAuthData } from "../../Redux/Auth/action";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Component/Loading/Loading";
import { scrollToTop } from "../../Component/NavigateTop";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const email = localStorage.getItem("email");

  const message = useSelector((state) => state.auth.changeMsg, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  console.log("location", location.state);
  //   initial values
  const initialValues = {
    email: email,
    code: "",
    password: "",
    password_confirm: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Code is required"),
    password: Yup.string().required("Required*"),
    password_confirm: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchChangePassword(values));
    setInProgress(true);
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && navigate("/auth/sign-in");
    message && setInProgress(false);
    setTimeout(() => {
      message && dispatch(removeAuthData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setInProgress(false);
    setTimeout(() => {
      errMessage && dispatch(removeAuthData());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (inProgress) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-24 w-full">
      <div className="w-11/12 sm:w-3/4 lg:w-1/2 xl:w-1/3 mx-auto border bg-white shadow-md p-5 sm:p-7 sm:mt-10 rounded-xl">
        <div className="flex justify-center items-center flex-col">
          <div className="w-1/2 sm:w-5/12 h-8 sm:h-12 xl:h-9">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <h4 className="font-semibold text-textPrimary text-2xl mt-6">
            Change Password
          </h4>
        </div>

        <div className="mt-7">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div className="w-full">
                  <FormikControl
                    label="OTP"
                    control="input"
                    name="code"
                    placeholder="Enter Your OTP"
                  />
                </div>
                <div className="w-full mt-5">
                  <FormikControl
                    label="New Password"
                    control="password"
                    name="password"
                    placeholder="Enter Your Password"
                    type={`${showPassword ? "text" : "password"}`}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                </div>

                <div className="w-full mt-5">
                  <FormikControl
                    label="Confirm Password"
                    control="password"
                    name="password_confirm"
                    placeholder="Confirm Your Password"
                    type={`${showCPassword ? "text" : "password"}`}
                    setShowPassword={setShowCPassword}
                    showPassword={showCPassword}
                  />
                </div>

                <Button
                  value="Change Password"
                  type="submit"
                  className="bg-primary py-3.5 w-full mt-7 rounded-xl text-white"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
