import React from "react";
import { appStore, logo2, playStore } from "../../Asset/Assest";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full bg-primary py-16">
      <div className="w-11/12  mx-auto ">
        <div className="grid grid-cols-12 gap-x-5 h-full">
          <div className="col-span-12 lg:col-span-5 xl:col-span-6">
            <Link to="/">
              <img src={logo2} alt="" className="cursor-pointer" />
            </Link>

            <div className="flex items-center gap-x-5 xl:gap-x-10 mt-12">
              <a href="https://play.google.com/store/apps/details?id=com.classiestate">
                <div className="w-36 h-10 xl:w-44 xl:h-14 cursor-pointer overflow-hidden">
                  <img
                    src={playStore}
                    alt="play store"
                    className="w-full h-full object-contain"
                  />
                </div>
              </a>

              <a href="https://apps.apple.com/au/app/classiestate/id1562134094">
                <div className="w-36 h-10 xl:w-44 xl:h-14 cursor-pointer overflow-hidden">
                  <img
                    src={appStore}
                    alt="app store"
                    className="w-full h-full object-contain"
                  />
                </div>
              </a>
            </div>
            <p className="mt-12 text-white text-sm lg:text-base">
              Classiestate is a Real Estate Marketplace and an Information Hub
              to help the Real Estate Agencies, Private Seller and Buyers,
              Developers, and doesn't control in any manner any transactions
              between the Seller and the Agent or buyer.
            </p>
          </div>

          <div className="col-span-12 lg:col-start-6 xl:col-start-7 col-end-13 text-white ">
            <div className="flex justify-between w-full flex-wrap">
              <div className="w-fit mt-10 lg:mt-0">
                <h6 className="font-bold text-lg lg:text-xl tracking-wider">
                  Features
                </h6>

                <Link to="/advertise-with-us">
                  <p className="mt-5 hover:font-medium cursor-pointer text-sm lg:text-base hover:underline">
                    Advertise with us
                  </p>
                </Link>

                <a href="https://admin.classiestate.com.au" target="_blank">
                  <p className="mt-5 hover:font-medium cursor-pointer text-sm lg:text-base hover:underline">
                    Agent portal
                  </p>
                </a>
              </div>
              <div className="w-fit mt-10 lg:mt-0">
                <h6 className="font-bold text-lg lg:text-xl tracking-wider">
                  Classiestate
                </h6>
                <Link to="/contact-us">
                  <p className="mt-5 hover:font-medium cursor-pointer text-sm lg:text-base hover:underline">
                    Contact Us
                  </p>
                </Link>
                <Link to="/news">
                  <p className="mt-2 hover:font-medium cursor-pointer text-sm lg:text-base hover:underline">
                    Blogs
                  </p>
                </Link>
              </div>
              <div className="w-fit mt-10 lg:mt-0">
                <h5 className="font-bold text-lg lg:text-xl tracking-wider">
                  Quick Links
                </h5>
                <Link to="/">
                  <p className="mt-5 hover:font-medium cursor-pointer text-sm lg:text-base hover:underline">
                    Buy Property
                  </p>
                </Link>

                <Link to="/rent">
                  <p className="mt-2 hover:font-medium cursor-pointer text-sm lg:text-base hover:underline">
                    Rent Property
                  </p>
                </Link>

                <Link to="/find-agents">
                  <p className="mt-2 hover:font-medium cursor-pointer text-sm lg:text-base hover:underline">
                    Find Agents
                  </p>
                </Link>

                <Link to="/find-agencies">
                  <p className="mt-2 hover:font-medium cursor-pointer text-sm lg:text-base hover:underline">
                    Find Agency
                  </p>
                </Link>

                <Link to="/agent-enquiry">
                  <p className="mt-2 hover:font-medium cursor-pointer text-sm lg:text-base hover:underline">
                    Enquire Property
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-12" />
        <div className="w-full sm:w-3/4 lg:w-1/2 mx-auto mt-14">
          <div className="flex justify-evenly">
            <a href="https://www.facebook.com/people/Classiestate/100083437564700/">
              <FaFacebookF size={27} className="text-white cursor-pointer" />
            </a>
          </div>
          <div className="flex justify-between mt-16 full text-white">
            <p className="border-r w-full text-center text-sm lg:text-base cursor-pointer hover:underline">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>

            <p className="w-full text-center text-sm lg:text-base cursor-pointer hover:underline">
              <Link to="/terms-of-use">Terms & Conditions</Link>
            </p>
          </div>
          <p className="text-center mt-12 text-white text-sm lg:text-base">
            Copyright ©️ 2023 Classi International Pty Ltd. All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
