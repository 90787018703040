import React, { useEffect, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BsListCheck } from "react-icons/bs";
import { CiMap } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchDeleteSaveSearch,
  fetchGetDefaultSearch,
  fetchGetExploreSearch,
  fetchGetInspectionData,
  fetchGetSaveSearch,
  fetchGetSearchResults,
  fetchSaveSearches,
  removeSearchData,
} from "../../Redux/Search/action";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { MdNotificationsActive } from "react-icons/md";

const Header2 = ({
  showList,
  showMap,
  showInspection,
  setShowMap,
  setShowList,
  setShowInspection,
  searchValue,
  totalData,
  propertyCategory,
  page,
  inspection,
  setInspection,
}) => {
  const [checkSaveSearch, setCheckSaveSearch] = useState(false);
  const [searchId, setSearchId] = useState();
  const searchKey = useSelector(
    (state) => state.search.search_tags,
    shallowEqual
  );

  const message = useSelector((state) => state.search.message, shallowEqual);
  const errMessage = useSelector((state) => state.search.errMsg, shallowEqual);

  const saveSearches = useSelector(
    (state) => state.search.saveSearch,
    shallowEqual
  );
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const datas = localStorage.getItem("search-property");
  const parsedData = JSON.parse(datas);

  const handleClickSaveSearch = () => {
    const body = {
      searched_datas: searchKey,
    };
    dispatch(fetchSaveSearches(body, token));
  };

  const handleClickRemoveSearch = () => {
    const body = {
      searchid: searchId,
    };

    dispatch(fetchDeleteSaveSearch(body, token));
  };

  const handleClickList = () => {
    setShowList(true);
    setShowMap(false);
    setShowInspection(false);
    setInspection("");

    dispatch(removeSearchData());
    setTimeout(() => {
      parsedData && parsedData.lat && !parsedData.is_featured
        ? dispatch(
            fetchGetDefaultSearch(
              propertyCategory,
              parsedData.lat,
              parsedData.lng,
              parsedData.bedroom,
              parsedData.bathroom,
              parsedData.parking,
              parsedData.type,
              parsedData.minPrice,
              parsedData.maxPrice,
              parsedData.minArea,
              parsedData.maxArea,
              page,
              token
            )
          )
        : parsedData.is_featured
        ? dispatch(
            fetchGetExploreSearch(
              propertyCategory,
              parsedData.lat,
              parsedData.lng,
              parsedData.is_featured === 1 ? 1 : 2,
              parsedData.bedroom,
              parsedData.bathroom,
              parsedData.parking,
              parsedData.type,
              parsedData.minPrice,
              parsedData.maxPrice,
              parsedData.minArea,
              parsedData.maxArea,
              page,
              token
            )
          )
        : dispatch(
            fetchGetSearchResults(
              propertyCategory,
              parsedData.address,
              parsedData.streetName,
              parsedData.suburb,
              parsedData.state,
              parsedData.bedroom,
              parsedData.bathroom,
              parsedData.parking,
              parsedData.type,
              parsedData.minPrice,
              parsedData.maxPrice,
              parsedData.minArea,
              parsedData.maxArea,
              page,
              token
            )
          );
    }, 100);
  };

  const handleClickMap = () => {
    setShowList(false);
    setShowMap(true);
    setShowInspection(false);
    setInspection("");
  };

  const handleClickInspection = () => {
    setShowList(false);
    setShowMap(false);
    setShowInspection(true);
    setInspection("Today");
    dispatch(removeSearchData());

    setTimeout(() => {
      dispatch(
        fetchGetInspectionData(
          propertyCategory,
          "Today",
          parsedData?.address,
          parsedData.streetName,
          parsedData.suburb,
          parsedData.state,
          parsedData.bedroom,
          parsedData.bathroom,
          parsedData.parking,
          parsedData.type,
          parsedData.minPrice,
          parsedData.maxPrice,
          parsedData.minArea,
          parsedData.maxArea,
          page,
          token
        )
      );
    }, 100);
  };

  // function to check the search value from a list
  useEffect(() => {
    const address = saveSearches.filter(
      (item) =>
        item.searched_options.some(
          (option) => option.parameter === "property_address"
        ) &&
        item.searched_options.some(
          (option) => option.parameter === "property_category"
        )
    );

    const finalArray = address.filter(
      (item) =>
        item.searched_options.some(
          (option) =>
            option.parameter === "property_address" &&
            option.value.includes(searchValue)
        ) &&
        item.searched_options.some(
          (option) =>
            option.parameter === "property_category" &&
            option.value.includes(propertyCategory)
        )
    );

    setSearchId(finalArray && finalArray?.[0]?.id);

    setCheckSaveSearch(finalArray && finalArray.length ? true : false);
  }, [saveSearches]);

  useEffect(() => {
    token && dispatch(fetchGetSaveSearch(token));
  }, []);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeSearchData());
    setTimeout(() => {
      message &&
        parsedData &&
        dispatch(
          fetchGetSearchResults(
            parsedData.category,
            parsedData.address,
            parsedData.streetName,
            parsedData.suburb,
            parsedData.state,
            parsedData.bedroom,
            parsedData.bathroom,
            parsedData.parking,
            parsedData.type,
            parsedData.minPrice,
            parsedData.maxPrice,
            parsedData.minArea,
            parsedData.maxArea,
            page,
            token
          )
        );
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeSearchData());
    setTimeout(() => {
      errMessage &&
        parsedData &&
        dispatch(
          fetchGetSearchResults(
            parsedData.category,
            parsedData.address,
            parsedData.streetName,
            parsedData.suburb,
            parsedData.state,
            parsedData.bedroom,
            parsedData.bathroom,
            parsedData.parking,
            parsedData.type,
            parsedData.minPrice,
            parsedData.maxPrice,
            parsedData.minArea,
            parsedData.maxArea,
            page,
            token
          )
        );
    }, 1000);
  }, [errMessage]);

  return (
    <div className="w-full">
      <div className="w-full flex flex-col gap-y-5 lg:gap-y-0 lg:flex-row lg:items-center justify-between">
        <div className="w-full lg:w-4/6 2xl:w-3/4">
          <h6 className="text-textPrimary font-semibold text-xl tracking-wide">
            Real Estate & Property for sale in{" "}
            {searchValue ? searchValue : "Australia"}
          </h6>
          <p className="text-lg text-textPrimary tracking-wide mt-2">
            <span className="text-primary font-bold">{totalData}</span>{" "}
            properties found
          </p>
        </div>
        {searchValue ? (
          !checkSaveSearch ? (
            <div
              className="w-fit border rounded-xl py-2.5 px-2 gap-x-2 flex items-center cursor-pointer"
              onClick={handleClickSaveSearch}
            >
              <IoMdNotificationsOutline
                size={27}
                className="text-textSecondary"
              />
              <div className="text-textPrimary text-lg ">Save Search</div>
            </div>
          ) : (
            <div
              className="w-fit border rounded-xl bg-gray-100 py-2.5 px-2 gap-x-2 flex items-center cursor-pointer"
              onClick={handleClickRemoveSearch}
            >
              <MdNotificationsActive size={27} className="text-textSecondary" />
              <div className="text-textPrimary text-lg ">Saved Search</div>
            </div>
          )
        ) : null}
      </div>

      <div className="flex w-full flex-wrap items-center justify-between mt-6 md:mt-10 lg:mt-6">
        <div className="flex w-full items-center gap-x-1 sm:gap-x-6 flex-wrap">
          <div
            className={`w-fit border rounded-xl py-3 px-5 gap-x-3 flex items-center cursor-pointer ${
              showList
                ? "text-primary border-primary bg-purple-50"
                : "text-textPrimary"
            }`}
            onClick={handleClickList}
          >
            <BsListCheck className="text-lg sm:text-2xl" />
            <div className="text-sm sm:text-lg ">List</div>
          </div>
          <div
            className={`w-fit border rounded-xl py-3 px-5 gap-x-3 flex items-center cursor-pointer ${
              showMap
                ? "text-primary border-primary bg-purple-50"
                : "text-textPrimary"
            }`}
            onClick={handleClickMap}
          >
            <CiMap className="text-lg sm:text-2xl" />
            <div className="text-sm sm:text-lg">Map</div>
          </div>
          <div
            className={`w-fit border mt-0 md:mt-5 lg:mt-0 rounded-xl py-3 px-5 gap-x-3 flex items-center cursor-pointer ${
              showInspection
                ? "text-primary border-primary bg-purple-50"
                : "text-textPrimary"
            }`}
            onClick={handleClickInspection}
          >
            <SlCalender className="text-lg sm:text-2xl" />
            <div className="text-sm sm:text-lg">Inspection</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header2;
