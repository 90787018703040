import React, { useEffect } from "react";
import { Button, FormikControl } from "../../Component";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchAgentRequest,
  removeProfileData,
} from "../../Redux/Profile/action";
import Cookies from "js-cookie";

const AgentRequest = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const message = useSelector((state) => state.profile.message, shallowEqual);
  const errMessage = useSelector((state) => state.profile.errMsg, shallowEqual);

  //   initial values
  const initialValues = {
    company_abn: "",
    company_name: "",
    module_id: 2,
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    company_abn: Yup.string().required("Required*"),
    company_name: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const body = {
      company_abn: values.company_abn,
      company_name: values.company_name,
      module_id: values.module_id,
      app_name: "CLASSIESTATE",
    };
    dispatch(fetchAgentRequest(body, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({ values: "" });
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeProfileData());
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeProfileData());
  }, [errMessage]);

  return (
    <div className="w-full h-full">
      <div className="w-full h-fit border rounded-xl px-4 pt-4 bg-white">
        <h5 className="font-semibold text-xl md:text-2xl">Agent Request</h5>
        <hr className="w-full mt-5" />
      </div>
      <div className="mt-10 w-full lg:w-3/4 mx-auto border bg-white rounded-xl p-6">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full">
                <FormikControl
                  label="Realestate Name"
                  control="input"
                  name="company_name"
                  placeholder="Your Company Name"
                />
              </div>
              <div className="w-full  mt-5">
                <FormikControl
                  label="ABN"
                  control="input"
                  name="company_abn"
                  placeholder="Your ABN"
                />
              </div>
              <div className="w-full mt-7">
                <Button
                  value="Submit"
                  type="submit"
                  className="bg-primary py-3.5 w-full rounded-xl text-white"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AgentRequest;
