import React from "react";
import { Button, FormikControl } from "../../Component";
import { BsArrowLeft } from "react-icons/bs";
import { scrollToTop } from "../../Component/NavigateTop";

const PersonalDetail = ({ setCurrentCompo, setPrevCompo }) => {
  const handleClickBack = () => {
    scrollToTop();
    setCurrentCompo(false);
    setPrevCompo(true);
  };
  return (
    <div className="mt-5 md:mt-16 w-full md:w-11/12 lg:w-3/4 pb-5 md:mx-auto rounded-xl border bg-white">
      <div className="px-5 md:px-10 pt-5 md:pt-10">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Personal Details
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <div className="mt-12 md:mt-16 w-full px-5 py-1 border-l-4 border-l-primary ">
          <p className="text-primary font-medium text-base md:text-lg">
            Final Step!
          </p>
          <p className="text-textPrimary text-sm md:text-base mt-2">
            We will send a report with a summary and connect you with a an
            expert to discuss your results and explain potential property
            options.
          </p>
        </div>

        <div className="mt-10 w-full">
          <FormikControl
            label="Full Name"
            control="input"
            name="name"
            placeholder="Enter Your Full Name"
          />
        </div>

        <div className="mt-7 w-full flex flex-col md:flex-row gap-x-8 items-center justify-between">
          <div className="w-full">
            <FormikControl
              label="Phone Number"
              control="input"
              name="phone"
              placeholder="Enter Your Phone Number"
            />
          </div>

          <div className="w-full mt-7 md:mt-0">
            <FormikControl
              label="Email Address"
              control="input"
              name="email"
              placeholder="Enter Your Email Address"
            />
          </div>
        </div>

        <div className="w-full mt-7">
          <FormikControl
            label="Message"
            control="textarea"
            name="description"
            placeholder="Type Your Message Here"
          />
        </div>

        <p className="text-textSecondary text-xs mt-7 md:text-base">
          * Personal Details is only to be used for sending genuine email
          enquiries to the Agency. Classiestate Pty Ltd reserves its right to
          take any legal or other appropriate action in relation to misuse of
          this service.
        </p>
      </div>
      <hr className="mt-8" />
      <div className="px-5 md:px-10 mt-8">
        <Button
          value="Submit Enquiry"
          className="w-full text-center text-white bg-primary rounded-xl py-3 md:py-4"
          type="submit"
        />
      </div>
      <div
        className="flex items-center gap-x-2 justify-center mt-7 text-sm md:text-base cursor-pointer text-primary"
        onClick={handleClickBack}
      >
        <BsArrowLeft />
        <p>Previous Question</p>
      </div>

      <p className="w-full mx-auto mt-10 text-xs text-center md:text-base md:w-11/12 lg:w-3/4">
        By submitting your enquiry, you agree to the{" "}
        <span className="text-primary font-medium">Terms & Conditions </span>{" "}
        and <span className="text-primary font-medium">Privacy Policies </span>
      </p>
    </div>
  );
};

export default PersonalDetail;
