import React from "react";
import { BsArrowRight } from "react-icons/bs";

const ExploreMore = ({ value, handleClick }) => {
  return (
    <div
      className="mt-5 border py-4 rounded-xl flex items-center gap-x-2 md:gap-x-5 w-fit px-2 md:px-10 cursor-pointer transition duration-1000 ease-in-out hover:bg-gray-100"
      onClick={handleClick}
    >
      <h6 className="text-textPrimary text-sm md:text-lg tracking-wider">
        {value}
      </h6>
      <BsArrowRight className="md:text-2xl text-base" />
    </div>
  );
};

export default ExploreMore;
