import React from "react";
import { AiFillStar } from "react-icons/ai";
import { BiPhoneCall } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { classiBazarLogo } from "../../Asset/Assest";
import { Link } from "react-router-dom";
import { scrollToTop } from "../NavigateTop";

const AgenciesCard = ({ data, setShowEmailPopup, setAgencyId }) => {
  return (
    <div className="border rounded-2xl cursor-pointer transition-all duration-300 hover:scale-100 hover:shadow-md">
      <Link to={`/agency-detail?indx=${data.user_id}`}>
        <div
          className="w-full rounded-t-xl h-12 flex justify-start px-8 py-3 items-center"
          style={{ background: `${data.company_color}` }}
        >
          <div className="w-24 h-7 overflow-hidden">
            {data.company_logo ? (
              <img
                src={data.company_logo}
                alt="logo"
                className="w-full object-contain h-full"
              />
            ) : (
              <img src={classiBazarLogo} alt="logo" className="w-full h-full" />
            )}
          </div>
        </div>
      </Link>
      <div className="p-2 md:p-5 w-full">
        <div className="flex items-center justify-between gap-x-10">
          {/* profile */}
          <Link to={`/agency-detail?indx=${data.user_id}`}>
            <div className="w-full">
              <h4 className="font-medium text-xl md:text-2xl text-textPrimary">
                {data.company_name}
              </h4>
              <p className="text-textSecondary text-sm md:text-base mt-1">
                {data.street_address}
              </p>
            </div>
          </Link>
          {/* email and call */}
          <div className="flex gap-y-3 md:gap-y-4 flex-col">
            <div
              className="border py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center justify-center rounded-2xl"
              onClick={() => {
                scrollToTop();
                setShowEmailPopup(true);
                setAgencyId(data.user_id);
              }}
            >
              <HiOutlineMail className="text-textSecondary text-base md:text-2xl" />
              <p className="text-sm md:text-lg text-textPrimary">Email</p>
            </div>

            {/* <a href={`mailto: ${data.email_address}`}>
              <div className="border py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center justify-center rounded-2xl">
                <HiOutlineMail className="text-textSecondary text-base md:text-2xl" />
                <p className="text-sm md:text-lg text-textPrimary">Email</p>
              </div>
            </a> */}
            <a
              href={`tel: ${
                data.contact_number ? data.contact_number : data.mobile_number
              }`}
            >
              <div className="border py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center justify-center rounded-2xl">
                <BiPhoneCall className="text-textSecondary text-base md:text-2xl" />
                <p className="text-sm md:text-lg text-textPrimary">Call</p>
              </div>
            </a>
          </div>
        </div>
        <hr className="mt-6" />

        <Link to={`/agency-detail?indx=${data.user_id}`}>
          <div class="grid grid-cols-2 mt-6 divide-x">
            <div className="md:pr-10">
              <h5 className="font-medium text-sm text-center md:text-start sm:text-base md:text-xl text-textPrimary">
                Sales Performance
              </h5>
              <div className="flex items-center mt-7 md:mt-10 justify-between md:w-3/4 w-full">
                <div className="flex items-center flex-col gap-y-4">
                  <h3 className="font-medium text-base md:text-2xl">
                    {data.sale}
                  </h3>
                  <p className="text-textSecondary text-center text-[0.7rem] md:text-base">
                    Properties sold
                  </p>
                </div>
                {/* <div className="flex items-center flex-col gap-y-4">
                  <h3 className="font-medium text-base md:text-2xl">32</h3>
                  <p className="text-textSecondary text-center text-[0.7rem] md:text-base">
                    Avg days advertised
                  </p>
                </div> */}
              </div>
            </div>
            <div className="pl-5 md:pl-10">
              <h5 className="font-medium text-center md:text-start text-sm sm:text-base md:text-xl text-textPrimary">
                Rent Performance
              </h5>
              <div className="flex items-center mt-7 md:mt-10 justify-between w-full md:w-3/4">
                <div className="flex items-center flex-col gap-y-4">
                  <h3 className="font-medium text-base md:text-2xl">
                    {data.rent}
                  </h3>
                  <p className="text-textSecondary text-center text-[0.7rem] md:text-base">
                    Properties leased
                  </p>
                </div>
                {/* <div className="flex items-center flex-col gap-y-4">
                  <h3 className="font-medium text-base md:text-2xl">23</h3>
                  <p className="text-textSecondary text-center text-[0.7rem] md:text-base">
                    Avg days advertised
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AgenciesCard;

export const AgenciesCardSkeleton = () => {
  return (
    <div className="border border-slate-200 animate-pulse rounded-2xl">
      <div className="w-full rounded-t-xl h-12 flex justify-start px-8 py-3 items-center bg-slate-200">
        <div className="w-24 bg-slate-100 h-7 rounded-full"></div>
      </div>
      <div className="p-5 w-full">
        <div className="flex items-center justify-between gap-x-10">
          {/* profile */}
          <div className="flex items-center gap-x-7">
            <div>
              <h4 className="font-semibold text-2xl w-72 h-5 rounded-full bg-slate-200 text-textPrimary"></h4>
              <p className="text-textSecondary mt-4 h-4 rounded-full bg-slate-200"></p>
              <div className="flex items-center gap-x-4 mt-2">
                <div className="flex items-center gap-x-1 w-72 rounded-full mt-3 h-5 bg-slate-200">
                  <h6 className="font-semibold text-xl"></h6>
                </div>
                <p className="text-textSecondary bg-slate-200 h-5"></p>
              </div>
            </div>
          </div>
          {/* email and call */}
          <div className="flex gap-y-4 flex-col">
            <div className="border py-3 cursor-pointer px-28 w-full gap-x-3 h-5 bg-slate-200 flex items-center justify-center rounded-2xl">
              <p className="text-lg text-textPrimary"></p>
            </div>
            <div className="border py-3 px-10 cursor-pointer h-5 bg-slate-200 w-full gap-x-3 flex items-center justify-center rounded-2xl">
              <p className="text-lg text-textPrimary"></p>
            </div>
          </div>
        </div>
        <hr className="mt-6" />
        <div class="grid grid-cols-2 mt-6 divide-x">
          <div className="pr-10">
            <h5 className="font-semibold h-7 rounded-full bg-slate-200 text-xl text-textPrimary"></h5>
            <div className="flex items-center mt-10 justify-between w-3/4">
              <div className="flex items-center flex-col gap-y-4">
                <h3 className="font-semibold text-2xl h-5 w-28 rounded-full bg-slate-200"></h3>
                <p className="text-textSecondary h-6 rounded-full w-40 bg-slate-200"></p>
              </div>
              <div className="flex items-center flex-col gap-y-4">
                <h3 className="font-semibold text-2xl h-5 w-28 rounded-full bg-slate-200"></h3>
                <p className="text-textSecondary h-6 w-40 rounded-full bg-slate-200"></p>
              </div>
            </div>
          </div>
          <div className="pl-10">
            <h5 className="font-semibold text-xl text-textPrimary h-7 rounded-full bg-slate-200"></h5>
            <div className="flex items-center mt-10 justify-between w-3/4 mx-auto gap-x-10">
              <div className="flex items-center flex-col gap-y-4">
                <h3 className="font-semibold text-2xl h-5 w-28 rounded-full bg-slate-200"></h3>
                <p className="text-textSecondary h-6 w-40 rounded-full bg-slate-200"></p>
              </div>
              <div className="flex items-center flex-col gap-y-4">
                <h3 className="font-semibold text-2xl h-5 w-28 rounded-full bg-slate-200"></h3>
                <p className="text-textSecondary h-6 w-40 rounded-full bg-slate-200"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
