import React from "react";
import { Button } from "../../Component";

const ProfileSkeleton = () => {
  return (
    <div className="w-full h-full animate-pulse">
      <div className="font-semibold text-2xl p-4 bg-slate-200 w-full h-10 rounded-full"></div>
      <div className="mt-5 w-full h-fit bg-slate-200 border rounded-xl py-5">
        <div className="font-medium text-xl text-textPrimary px-5 w-11/12 mx-auto h-8 rounded-full bg-slate-100"></div>
        <div className="w-full h-full p-5 flex items-center gap-x-5">
          <div className="w-24 h-24 bg-slate-100 rounded-full"></div>

          <Button className="w-40 px-7 py-3 bg-slate-100 h-8 text-white rounded-xl ml-7" />
        </div>
      </div>

      <div className="mt-5 w-full h-fit bg-slate-200 border rounded-xl py-5">
        <div className="font-medium text-xl text-textPrimary px-5 w-11/12 mx-auto h-8 rounded-full bg-slate-100"></div>
        <div className="w-full pt-5 px-5 lg:px-10 lg:pt-10">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col gap-y-2 w-3/4">
              <div className="text-[0.6rem] md:text-sm text-textSecondary w-full rounded-full h-8 bg-slate-100"></div>
              <div className="text-xs md:text-base lg:text-lg text-textPrimary w-3/4 md:w-11/12 h-7 rounded-full mt-2 bg-slate-100"></div>
            </div>
            <Button className="py-2 w-40  rounded-xl border borer-xl px-5 bg-slate-100 h-8 transition ease-in-out duration-300 hover:bg-gray-50" />
          </div>
        </div>

        <div className="w-full pt-5 px-5 lg:px-10 lg:pt-10">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col gap-y-2 w-3/4">
              <div className="text-[0.6rem] md:text-sm text-textSecondary w-full rounded-full h-8 bg-slate-100"></div>
              <div className="text-xs md:text-base lg:text-lg text-textPrimary w-3/4 md:w-11/12 h-7 rounded-full mt-2 bg-slate-100"></div>
            </div>
            <Button className="py-2 w-40  rounded-xl border borer-xl px-5 bg-slate-100 h-8 transition ease-in-out duration-300 hover:bg-gray-50" />
          </div>
        </div>

        <div className="w-full pt-5 px-5 lg:px-10 lg:pt-10">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col gap-y-2 w-3/4">
              <div className="text-[0.6rem] md:text-sm text-textSecondary w-full rounded-full h-8 bg-slate-100"></div>
              <div className="text-xs md:text-base lg:text-lg text-textPrimary w-3/4 md:w-11/12 h-7 rounded-full mt-2 bg-slate-100"></div>
            </div>
            <Button className="py-2 w-40  rounded-xl border borer-xl px-5 bg-slate-100 h-8 transition ease-in-out duration-300 hover:bg-gray-50" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkeleton;
