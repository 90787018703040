import React from "react";
import { Outlet, matchPath, useLocation } from "react-router-dom";

const AuthRoute = ({ Component }) => {
  const { pathname } = useLocation();
  const pathroad = matchPath("/auth", pathname);

  return <div className="w-full">{pathroad ? <Component /> : <Outlet />}</div>;
};
export default AuthRoute;
