import React, { useEffect, useState } from "react";
import {
  Button,
  Pagination,
  QuickEnquiryChat,
  SinglePagnination,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetAgentEnquiries,
  removeProfileData,
} from "../../Redux/Profile/action";
import Loading from "../../Component/Loading/Loading";
import Cookies from "js-cookie";
import { BsChatDots } from "react-icons/bs";
import EnquiryDetail from "./EnquiryDetail";
import {
  fetchGetAgentEnquiryDetail,
  fetchGetQuickEnquiryChat,
  removeEnquiryData,
} from "../../Redux/Enquiry/action";
import { scrollToTop } from "../../Component/NavigateTop";

const AgentEnquiries = () => {
  const [page, setPage] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [userId, setUserId] = useState("");
  const [id, setId] = useState("");
  const [enquiryId, setEnquiryId] = useState("");
  const [userEnquiryId, setUserEnquiryId] = useState("");

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const agentEnquiry = useSelector(
    (state) => state.profile.agentEnquiries,
    shallowEqual
  );

  const enquiryDetail = useSelector(
    (state) => state.enquiry.enquiryDetail,
    shallowEqual
  );

  const approvedEnquiry = useSelector(
    (state) => state.enquiry.approvedEnquiry,
    shallowEqual
  );

  const totalData = useSelector(
    (state) => state.profile.totalData,
    shallowEqual
  );
  const totalPage = Math.ceil(totalData / 10);

  const loading = useSelector((state) => state.profile.isloading, shallowEqual);

  useEffect(() => {
    dispatch(fetchGetAgentEnquiries(page, token));
    return () => {
      dispatch(removeProfileData());
    };
  }, [page]);

  const handleClickDetail = (userId, enquiryId) => {
    setUserId(userId);
    setEnquiryId(enquiryId);
    setShowDetail(true);
    setShowChat(false);
    scrollToTop();
  };

  const handleClickChat = (enquiryId, userEnquiryId, userId) => {
    setEnquiryId(enquiryId);
    setUserEnquiryId(userEnquiryId);
    setId(userId);
    setShowChat(true);
    setShowDetail(false);
    scrollToTop(true);
  };

  useEffect(() => {
    userId &&
      enquiryId &&
      dispatch(fetchGetAgentEnquiryDetail(enquiryId, userId, token));
    return () => {
      dispatch(removeEnquiryData());
    };
  }, [userId && enquiryId]);

  useEffect(() => {
    userEnquiryId &&
      enquiryId &&
      dispatch(fetchGetQuickEnquiryChat(enquiryId, userEnquiryId, token));
    return () => {
      dispatch(removeEnquiryData());
    };
  }, [userEnquiryId && enquiryId]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      <div className="w-full h-fit border rounded-xl px-4 pt-4 bg-white">
        <h5 className="font-semibold text-xl md:text-2xl">Quick Enquiries</h5>
        <hr className="w-full mt-5" />
      </div>
      {agentEnquiry && agentEnquiry.length ? (
        <>
          {agentEnquiry.map((val) => {
            return (
              <div
                key={val.id}
                className={`mt-7 bg-white rounded-xl pb-4 block border ${
                  !enquiryId
                    ? "block"
                    : enquiryId === val.id
                    ? "block"
                    : "hidden"
                }`}
              >
                <div className="flex items-center justify-between p-5">
                  <h6 className="text-sm md:text-base lg:text-xl font-semibold">
                    Lead for {val.enquiry_for}
                  </h6>
                  <Button
                    value={val.when_need}
                    disable={true}
                    className="w-fit text-[0.5rem] md:text-xs lg:text-sm bg-purple-50 font-medium text-primary rounded-full py-1.5 md:py-2.5 px-5"
                  />
                </div>
                <hr />

                <div className="flex p-5 gap-x-3 w-full flex-wrap lg:flex-nowrap">
                  <div className="flex gap-x-3 w-full">
                    <div className="w-full h-full">
                      <p className="text-xs lg:text-sm text-textSecondary">
                        Property Type
                      </p>

                      <p className="text-xs mt-2 lg:text-sm text-textPrimary">
                        {val.property_type}
                      </p>
                    </div>
                    <div className="w-full h-full">
                      <p className="text-xs lg:text-sm text-textSecondary">
                        Location
                      </p>
                      {val.location.map((val) => {
                        return (
                          <p className="flex flex-wrap text-xs mt-2 lg:text-sm text-textPrimary">
                            {val},
                          </p>
                        );
                      })}
                    </div>
                    <div className="w-full h-full">
                      <p className="text-xs lg:text-sm text-textSecondary">
                        Enquire Date
                      </p>
                      <p className="text-xs mt-2 lg:text-sm text-textPrimary">
                        {new Date(val.added_at).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/4 flex flex-row lg:flex-col gap-x-5 md:gap-x-10 lg:gap-x-0 lg:gap-y-2 mt-10 lg:mt-0">
                    <div
                      className={`${
                        val.approved === 0
                          ? "cursor-not-allowed bg-gray-50 text-textPrimary border"
                          : "cursor-pointer bg-primary text-white"
                      } w-full text-xs lg:text-sm flex items-center justify-center gap-x-2 py-2 md:py-3 rounded-xl`}
                      onClick={() =>
                        val.approved === 1
                          ? handleClickChat(val.id, val.i_userid, val.user_id)
                          : null
                      }
                    >
                      <BsChatDots className="text-base lg:text-lg" />
                      <button disabled={val.approved === 0 ? true : false}>
                        Chat
                      </button>
                    </div>
                    <Button
                      value="View Details"
                      handleClick={() => handleClickDetail(val.user_id, val.id)}
                      className="border text-xs lg:text-sm rounded-xl w-full bg-gray-50 py-2 md:py-3 transition ease-in-out duration-1000 hover:bg-gray-100"
                    />
                  </div>
                </div>
              </div>
            );
          })}
          {showChat || showDetail ? null : (
            <div className="mt-10">
              {totalPage > 5 ? (
                <Pagination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              ) : (
                <SinglePagnination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              )}
            </div>
          )}
        </>
      ) : null}

      {showDetail && (
        <EnquiryDetail
          showDetail={showDetail}
          setShowDetail={setShowDetail}
          enquiryDetail={enquiryDetail}
          approvedEnquiry={approvedEnquiry}
          setEnquiryId={setEnquiryId}
          setUserEnquiryId={setUserEnquiryId}
        />
      )}

      {showChat && (
        <QuickEnquiryChat
          showChat={showChat}
          setShowChat={setShowChat}
          userId={id}
          setUserId={setId}
          setUserEnquiryId={setUserEnquiryId}
          setEnquiryId={setEnquiryId}
          userEnquiryId={userEnquiryId}
          enquiryId={enquiryId}
        />
      )}
    </div>
  );
};

export default AgentEnquiries;
