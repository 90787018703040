import React from "react";
import { classiBazarLogo } from "../../Asset/Assest";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import Loading from "../Loading/Loading";
import { shallowEqual, useSelector } from "react-redux";

const Notification = ({ notification, page, setPage }) => {
  const loading = useSelector(
    (state) => state.notification.isloading,
    shallowEqual
  );

  const has_next_page = useSelector(
    (state) => state.notification.has_next_page,
    shallowEqual
  );

  if (loading) {
    return (
      <div className="hidden lg:block w-1/3 shadow-md  rounded-xl bg-white z-40 border h-[25rem] overflow-y-auto absolute top-24 right-0 transition duration-1000 ease-in-out">
        <Loading />
      </div>
    );
  }

  return (
    <div className="hidden lg:block w-1/3 shadow-md  rounded-xl bg-white z-40 border h-[25rem] overflow-y-auto absolute top-24 right-0 transition duration-1000 ease-in-out">
      <div className="w-full h-14 sticky top-0 border-b bg-white p-3">
        <h5 className="font-medium text-xl">Notifications</h5>
      </div>

      <div className="px-3 w-full h-full">
        {notification && notification.length ? (
          <>
            {notification.map((val) => {
              return (
                <div
                  className="flex mt-5 gap-x-5 w-full cursor-pointer hover:shadow-md overflow-hidden  border rounded-lg p-2"
                  key={val.id}
                >
                  <div className="w-10 h-10">
                    <img
                      src={classiBazarLogo}
                      alt=""
                      className="w-full h-full rounded-full"
                    />
                  </div>

                  <div className="w-full overflow-hidden">
                    <h6 className="text-base font-medium truncate">
                      {val.title}
                    </h6>
                    <p className="text-textSecondary mt-2 text-sm">
                      {val.body}
                    </p>
                  </div>
                </div>
              );
            })}

            <div className="w-full mt-7 pb-5 flex items-center gap-x-7 justify-center">
              {has_next_page ? (
                <div
                  className=" bg-primary rounded-full p-5 w-fit text-white cursor-pointer"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  <AiOutlineArrowDown />
                </div>
              ) : null}
              {page !== 1 ? (
                <div
                  className=" bg-primary rounded-full p-5 w-fit text-white cursor-pointer"
                  onClick={() => {
                    setPage(page - 1);
                  }}
                >
                  <AiOutlineArrowUp />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <h5 className="flex justify-center items-center w-full h-full">
            No Notification Yet
          </h5>
        )}
      </div>
    </div>
  );
};

export default Notification;
