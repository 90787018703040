import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  options: [],
  enquiryDetail: "",
  approvedEnquiry: "",
  chats: [],
  myEnquiry: [],
  totalData: "",
};

export const Enquiry = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ENQUIRY_OPTIONS:
      return {
        ...state,
        isloading: false,
        success: true,
        options: action.payload,
      };

    case ActionTypes.SEND_AGENT_ENQUIRY_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.SEND_AGENT_ENQUIRY_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_AGENT_ENQUIRY_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        enquiryDetail: action.payload.i_detail,
        approvedEnquiry: action.payload.enquiry_users,
      };

    case ActionTypes.GET_MY_ENQUIRY:
      return {
        ...state,
        isloading: false,
        success: true,
        myEnquiry: action.payload.enquiries,
        totalData: action.payload.total_number,
      };

    case ActionTypes.SEND_PROPERTY_ENQUIRY_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.SEND_PROPERTY_ENQUIRY_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_QUICK_ENQUIRY_CHAT:
      return {
        ...state,
        isloading: false,
        success: true,
        chats: action.payload.chats,
      };

    case ActionTypes.SEND_AGENT_ENQUIRY_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Successful",
      };

    case ActionTypes.SEND_AGENT_ENQUIRY_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_ENQUIRY_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        options: [],
        enquiryDetail: "",
        myEnquiry: [],
        has_next_page: "",
        chats: [],
        approvedEnquiry: "",
      };

    default:
      return state;
  }
};
