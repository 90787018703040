import React, { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Button, Contain, GridNewsCard, NewsCard } from "../../Component";
import { appStore, mobileFrame, playStore } from "../../Asset/Assest";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetNewsById, removeNewsData } from "../../Redux/News/action";
import Loading from "../../Component/Loading/Loading";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { fetchGetDefaultSearch } from "../../Redux/Search/action";
import Cookies from "js-cookie";

const NewsDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("access_token");

  const loading = useSelector((state) => state.news.isloading, shallowEqual);
  const newsDetail = useSelector(
    (state) => state.news.newsDetail,
    shallowEqual
  );

  const URL = window.location.href;

  const handleClickSearch = () => {
    dispatch(
      fetchGetDefaultSearch(
        "buy",
        25.2744,
        133.7751,
        null,
        null,
        null,
        [],
        null,
        null,
        null,
        null,
        1,
        token
      )
    );
    navigate(`/search?property_category=buy`);
  };

  useEffect(() => {
    dispatch(fetchGetNewsById(id));
    return () => {
      dispatch(removeNewsData());
    };
  }, [id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-24">
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        {/* Bread crum */}
        <div className="flex items-center gap-x-3">
          <p className="text-textSecondary text-sm">News</p>
          <MdKeyboardArrowRight size={15} className="text-textSecondary" />
          <p className="text-textSecondary text-sm">Sydney</p>
          <MdKeyboardArrowRight size={15} className="text-textSecondary" />
          <p className="text-textPrimary font-medium">Things to do in Sydney</p>
        </div>

        {/* heading */}
        <div className="mt-10">
          <h4 className=" text-xl md:text-3xl font-semibold">
            {newsDetail.news_title}
          </h4>
        </div>

        <div className="w-full h-[350px] md:h-[512px] rounded-2xl mt-5">
          <img
            src={newsDetail.news_pic}
            alt=""
            className="w-full h-full object-cover rounded-2xl"
          />
        </div>

        <div className="w-full mt-10">
          <Contain paragraph={newsDetail.news_detail} />
        </div>

        <div
          className="w-full mt-10 rounded-lg py-16"
          style={{ background: "#FAFBFB" }}
        >
          <div className="w-11/12 xl:w-3/4 mx-auto flex flex-col justify-center items-center">
            <h3 className="font-semibold text-2xl md:text-3xl xl:text-4xl text-textPrimary text-center">
              Want to find the best property for you
            </h3>
            <p className="text-textSecondary mt-5 px-0 md:px-10">
              Lorem ipsum dolor sit amet consectetur. Ultrices in pharetra
              quisque quisque massa tempus tortor nunc. Venenatis donec
              porttitor sit a scelerisque vitae adipiscing cursus felis.
            </p>
            <Button
              value="Browse Listing"
              className="bg-primary text-base mt-10 text-white py-4 px-10 w-fit rounded-2xl"
              handleClick={handleClickSearch}
            />
          </div>
        </div>

        <hr className="mt-20" />
        {/* share articles */}
        <div className="mt-10">
          <h5 className="text-textPrimary text-xl font-medium">
            Share this article
          </h5>
          <div className="flex items-center gap-x-6 mt-5">
            <FacebookShareButton
              className="flex flex-col justify-center px-3 py-1 rounded-full bg-white border "
              url={URL}
              hashtag="classiEstate"
            >
              <div
                className="flex items-center gap-x-5 font-medium"
                style={{ color: "#00639e" }}
              >
                <FacebookIcon className="rounded-full w-10 h-10" />
              </div>
            </FacebookShareButton>
            <TwitterShareButton
              className="px-3 py-1 rounded-full bg-white border"
              url={URL}
              via="https://classiestate.classideals.com.au/"
            >
              <div
                className="flex items-center gap-x-5 font-medium"
                style={{ color: "#00639e" }}
              >
                <TwitterIcon className="rounded-full w-10 h-10" />
              </div>
            </TwitterShareButton>

            <WhatsappShareButton url={URL}>
              <div
                className="flex items-center gap-x-5 font-medium"
                style={{ color: "#00639e" }}
              >
                <WhatsappIcon className="rounded-full w-10 h-10" />
              </div>
            </WhatsappShareButton>
          </div>
        </div>
      </div>

      {/* mobile Icon section */}
      <div
        className="w-full h-96 mt-20 relative"
        style={{ background: "#FAFBFB" }}
      >
        <div className="grid grid-cols-12 md:gap-x-10 w-11/12 xl:w-3/4 mx-auto h-full">
          <div className="col-span-12 md:col-span-6 lg:col-span-7 py-10">
            <h3 className="text-2xl md:text-4xl font-semibold">
              Find the <span className="text-primary">latest properties </span>
              to hit the market
            </h3>
            <p className="text-textSecondary text-lg mt-5">
              Over 300,000 properties to choose from in the Homely app for
              iPhone and Android.
            </p>
            <div className="flex items-center gap-x-5 mt-10">
              <a href="https://play.google.com/store/apps/details?id=com.classiestate">
                <img
                  src={playStore}
                  alt="play store"
                  className="w-40 h-12 cursor-pointer"
                />
              </a>
              <a href="https://apps.apple.com/au/app/classiestate/id1562134094">
                <img
                  src={appStore}
                  alt="app store"
                  className="w-40 h-12 cursor-pointer"
                />
              </a>
            </div>
          </div>
          <div className="hidden md:block col-start-7 lg:col-start-9 col-end-13">
            <img src={mobileFrame} alt="" className="w-full h-[520px] -mt-14" />
          </div>
        </div>
      </div>

      {/* similar articles */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-24">
        {newsDetail.similar_news && newsDetail.similar_news.length ? (
          <>
            <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
              Read Similar Articles
            </h4>
            <hr className="w-20 border border-primary mt-4" />
            {newsDetail.similar_news.length > 4 ? (
              <NewsCard
                title="Latest Property Articles"
                data={newsDetail.similar_news}
              />
            ) : (
              <GridNewsCard
                title="Latest Property Articles"
                data={newsDetail.similar_news}
              />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default NewsDetail;
