export const ENQUIRY_LOADING = "ENQUIRY_LOADING";
export const GET_ENQUIRY_OPTIONS = "GET_ENQUIRY_OPTIONS";
export const SEND_AGENT_ENQUIRY_SUCCESS = "SEND_AGENT_ENQUIRY_SUCCESS";
export const SEND_AGENT_ENQUIRY_FAIL = "SEND_AGENT_ENQUIRY_FAIL";
export const GET_AGENT_ENQUIRY_DETAIL = "GET_AGENT_ENQUIRY_DETAIL";
export const GET_QUICK_ENQUIRY_CHAT = "GET_QUICK_ENQUIRY_CHAT";
export const GET_MY_ENQUIRY = "GET_MY_ENQUIRY";
export const SEND_PROPERTY_ENQUIRY_SUCCESS = "SEND_PROPERTY_ENQUIRY_SUCCESS";
export const SEND_PROPERTY_ENQUIRY_FAIL = "SEND_PROPERTY_ENQUIRY_FAIL";
export const SEND_ENQUIRY_REPLY_SUCCESS = "SEND_ENQUIRY_REPLY_SUCCESS";
export const SEND_ENQUIRY_REPLY_FAIL = "SEND_ENQUIRY_REPLY_FAIL";
export const REMOVE_ENQUIRY_DATA = "REMOVE_ENQUIRY_DATA";
