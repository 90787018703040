import React, { useEffect, useMemo, useState } from "react";
import Header from "./Header";
import { scrollToTop } from "../../Component/NavigateTop";
import { Home } from "../../Asset/Assest";
import {
  Download,
  ExploreCard,
  GridNewsCard,
  NewsCard,
  NewsCardSkeleton,
} from "../../Component";
import { ExploreAgencies } from "../../Json";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetAgentAgencySearchSuggestion,
  removeAgentAgencyData,
} from "../../Redux/Agent-Agencies/action";
import debouce from "lodash.debounce";
import { fetchGetAllNewsData, removeNewsData } from "../../Redux/News/action";
import Loading from "../../Component/Loading/Loading";

const Agencies = () => {
  const [toggle, setToggle] = useState({
    agent: false,
    agency: true,
  });
  const [searchAgencies, setSearchAgencies] = useState("");
  const [locationSuggestion, setLocationSuggestion] = useState();
  const [agencySuggestion, setAgencySuggestion] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const suggestions = useSelector(
    (state) => state.agentagencies.suggestions,
    shallowEqual
  );

  const news = useSelector((state) => state.news.news, shallowEqual);
  const newsLoading = useSelector(
    (state) => state.news.isloading,
    shallowEqual
  );

  const tipForBuying = news?.categories?.filter(
    (val) => val.name === "Buying and Building New"
  );

  const onchangeSearchHandler = (e) => {
    setSearchAgencies(e.target.value);
  };
  useEffect(() => {
    dispatch(fetchGetAgentAgencySearchSuggestion(searchAgencies));
    return () => {
      dispatch(removeAgentAgencyData());
    };
  }, [searchAgencies]);

  const debouncedResults = useMemo(() => {
    return debouce(onchangeSearchHandler, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    setLocationSuggestion(suggestions.filter((val) => val.type === "Address"));
    setAgencySuggestion(suggestions.filter((val) => val.type === "Agency"));
  }, [suggestions]);

  useEffect(() => {
    dispatch(fetchGetAllNewsData());
    return () => {
      dispatch(removeNewsData());
    };
  }, []);

  const handleClickSuggestion = (name) => {
    navigate(`/find-agencies/agencies?search=${name}`);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  if (newsLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="py-24">
      <Header
        title="Find top real estate agencies in your area"
        subTitle="100% free to sellers - compare real estate agencies by sales and reviews"
        img={Home}
        handleClick={() =>
          navigate(`/find-agencies/agencies?search=${searchAgencies}`)
        }
        onchangeSearchHandler={debouncedResults}
        locationSuggestions={locationSuggestion}
        nameSuggestion={agencySuggestion}
        disable={false}
        handleClickSuggestion={handleClickSuggestion}
      />
      {/* explore Agencies */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10 md:mt-20">
        <ExploreCard title="Looking to buy a place?" data={ExploreAgencies} />
      </div>
      {/* download the app section */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-6 md:mt-28">
        <Download />
      </div>

      {/* Tips for buy properties */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-14 md:mt-24">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Tips for Buying Properties
        </h4>
        <hr className="w-20 border border-primary mt-4" />
        {tipForBuying && tipForBuying.length ? (
          tipForBuying.length > 4 ? (
            <NewsCard
              title="Tips for Buying Properties"
              data={tipForBuying?.[0]?.news}
            />
          ) : (
            <GridNewsCard
              title="Tips for Buying Properties"
              data={tipForBuying?.[0]?.news}
            />
          )
        ) : (
          <NewsCardSkeleton />
        )}
      </div>

      {/* footer */}
      <div className="w-11/12 md:w-3/4 lg:w-11/12 xl:w-3/4 mx-auto mt-14">
        <Footer toggle={toggle} setToggle={setToggle} />
      </div>
    </div>
  );
};

export default Agencies;
