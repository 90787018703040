import React from "react";
import { Button } from "../../Component";
import { BsArrowLeft } from "react-icons/bs";
import { classiEstate } from "../../Asset/Assest";
import { scrollToTop } from "../../Component/NavigateTop";

const PropertyType = ({
  title,
  data,
  value,
  setValue,
  setCurrentCompo,
  setPrevCompo,
  setNextCompo,
}) => {
  const handleClick = (val) => {
    setValue(val);
  };

  const handleClickBack = () => {
    setCurrentCompo(false);
    setPrevCompo(true);
    scrollToTop();
  };

  const handleClickNext = () => {
    setCurrentCompo(false);
    setNextCompo(true);
    scrollToTop();
  };

  return (
    <div className="mt-5 md:mt-16 w-full md:w-11/12 lg:w-3/4 pb-5 mx-auto rounded-xl border bg-white">
      <div className="px-5 md:px-10 pt-5 md:pt-10">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          {title}
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <div className="w-full mt-10 md:mt-14 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-x-2 gap-y-2 md:gap-y-5 md:gap-x-5">
          {data.map((val) => {
            return (
              <div
                className={`w-full rounded-xl border flex flex-col justify-center items-center p-2 cursor-pointer ${
                  val.value === value
                    ? "text-primary bg-purple-50 border-primary"
                    : ""
                }`}
                onClick={() => handleClick(val.value)}
              >
                <img
                  src={val.img ? val.img : classiEstate}
                  alt=""
                  className="w-12 h-12 md:w-14 md:h-14"
                />
                <p className="text-xs 2xl:text-sm text-center text-textPrimary w-full mt-3 truncate">
                  {val.label}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <hr className="w-full mt-10" />
      <div className="px-5 md:px-10 mt-8 flex items-center justify-between">
        <div
          className="flex items-center gap-x-2 text-sm md:text-base cursor-pointer text-primary"
          onClick={handleClickBack}
        >
          <BsArrowLeft />
          <p>Previous Question</p>
        </div>

        <Button
          value="Next"
          className={`rounded-xl py-2 text-sm md:text-base md:py-3 px-10  ${
            value && value.length
              ? "bg-primary text-white"
              : "bg-gray-100 text-gray-400"
          }`}
          disable={value && value.length ? false : true}
          handleClick={handleClickNext}
        />
      </div>
    </div>
  );
};

export default PropertyType;
