import React from "react";
import { Button } from "../../Component";

const MyEnquirySkeleton = () => {
  return (
    <div className="w-full h-full animate-pulse">
      <div className="w-full h-fit border bg-slate-200 px-5 py-5 rounded-xl">
        <div className="font-semibold text-2xl w-full rounded-full h-7 bg-slate-100"></div>

        <hr className="w-full mt-5" />
        <div className="md:mt-8 w-fit rounded-xl border flex items-center gap-x-4">
          <Button className="w-28 lg:w-40 bg-slate-100 py-2 md:py-3 rounded-xl" />
          <Button className="w-28 lg:w-40 py-2 md:py-3 bg-slate-100 rounded-xl" />
        </div>
      </div>

      <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-x-5">
        <div className="w-full h-full bg-slate-200 border p-5 rounded-xl cursor-pointer hover:shadow-md ">
          <div className="w-full flex gap-x-3">
            <div className="w-14 h-14 lg:w-24 lg:h-24 border p-2 rounded-lg bg-slate-100"></div>
            <div className="w-full overflow-hidden">
              <div className="font-medium text-xl capitalize truncate h-6 bg-slate-100 rounded-full"></div>
              <div className="text-textSecondary mt-2 text-sm capitalize truncate2 h-5 bg-slate-100 rounded-full"></div>
            </div>
          </div>
        </div>

        <div className="w-full h-full mt-5 lg:mt-0 bg-slate-200 border p-5 rounded-xl cursor-pointer hover:shadow-md ">
          <div className="w-full flex gap-x-3">
            <div className="w-14 h-14 lg:w-24 lg:h-24 border p-2 rounded-lg bg-slate-100"></div>
            <div className="w-full overflow-hidden">
              <div className="font-medium text-xl capitalize truncate h-6 bg-slate-100 rounded-full"></div>
              <div className="text-textSecondary mt-2 text-sm capitalize truncate2 h-5 bg-slate-100 rounded-full"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-x-5">
        <div className="w-full mt-5 lg:mt-0 h-full bg-slate-200 border p-5 rounded-xl cursor-pointer hover:shadow-md ">
          <div className="w-full flex gap-x-3">
            <div className="w-14 h-14 lg:w-24 lg:h-24 border p-2 rounded-lg bg-slate-100"></div>
            <div className="w-full overflow-hidden">
              <div className="font-medium text-xl capitalize truncate h-6 bg-slate-100 rounded-full"></div>
              <div className="text-textSecondary mt-2 text-sm capitalize truncate2 h-5 bg-slate-100 rounded-full"></div>
            </div>
          </div>
        </div>

        <div className="w-full mt-10 lg:mt-0 h-full bg-slate-200 border p-5 rounded-xl cursor-pointer hover:shadow-md ">
          <div className="w-full flex gap-x-3">
            <div className="w-14 h-14 lg:w-24 lg:h-24 border p-2 rounded-lg bg-slate-100"></div>
            <div className="w-full overflow-hidden">
              <div className="font-medium text-xl capitalize truncate h-6 bg-slate-100 rounded-full"></div>
              <div className="text-textSecondary mt-2 text-sm capitalize truncate2 h-5 bg-slate-100 rounded-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyEnquirySkeleton;
