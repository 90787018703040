import React, { useEffect, useState } from "react";
import { Button, FormikControl } from "../../../Component";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { scrollToTop } from "../../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchGetAGentDetail,
  fetchGetAgencyDetail,
  fetchSendEnquiryToAgent,
  removeAgentAgencyData,
} from "../../../Redux/Agent-Agencies/action";
import { toast } from "react-toastify";
import { classiBazarLogo } from "../../../Asset/Assest";

const AgentEnquiryPopup = ({ data, setAgentEnquiry, agentId, agencyId }) => {
  const [selectToggle, setSelectToggle] = useState([]);
  const profile = useSelector((state) => state.profiles.profile, shallowEqual);

  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const message = useSelector(
    (state) => state.agentagencies.message,
    shallowEqual
  );
  const errMessage = useSelector(
    (state) => state.agentagencies.errMsg,
    shallowEqual
  );

  //   initial values
  const initialValues = {
    name: profile ? profile.first_name + " " + profile.last_name : "",
    email: profile ? profile.user_email : "",
    phone: profile ? profile.user_mobile : "",
    message: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required *"),
    name: Yup.string().required("Required*"),
    phone: Yup.number().positive().required("Required*"),
    message: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();
    if (selectToggle.length) {
      const body = {
        title: [selectToggle],
        message: values.message,
        name: values.name,
        email: values.email,
        phone: values.phone,
        agent_id: data.id,
        app_name: "CLASSIESTATE",
      };
      dispatch(fetchSendEnquiryToAgent(body, token));
    } else {
      toast.error("Please select your enquiry purpose");
    }
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({ values: "" });
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeAgentAgencyData());
    message && dispatch(fetchGetAGentDetail(agentId, token));
    message && agencyId && dispatch(fetchGetAgencyDetail(agencyId, 1));
    message && setAgentEnquiry(false);
  }, [message]);

  useEffect(() => {
    errMessage && toast.success(errMessage);
    errMessage && dispatch(removeAgentAgencyData());
    errMessage && dispatch(fetchGetAGentDetail(agentId, token));
  }, [errMessage]);

  return (
    <div className="w-11/12 md:w-3/4 lg:w-1/2 left-[4%] md:translate-x-[12%] lg:translate-x-1/2  border rounded-xl p-5 z-40 bg-white absolute">
      <h6 className="font-medium text-base md:text-xl">Send enquiry to</h6>

      <div className="mt-7 flex items-center gap-x-7">
        <div className="w-14 h-14 md:w-20 md:h-20">
          {data.thumbnail ? (
            <img
              src={data.thumbnail}
              alt=""
              className="w-full h-full rounded-full
            "
            />
          ) : (
            <img
              src={classiBazarLogo}
              alt=""
              className="w-full h-full rounded-full
            "
            />
          )}
        </div>
        <div>
          <h5 className="font-medium text-base md:text-lg">
            {data.first_name} {data.last_name}
          </h5>
          <h6 className="text-textSecondary text-xs md:text-sm mt-1">
            {data.company_name}
          </h6>
        </div>
      </div>

      <hr className="w-full mt-6" />

      <div className="mt-5">
        <h6 className="font-medium text-base md:text-xl">
          What's the enquiry about?
        </h6>

        <div className="flex justify-between items-center mt-5 gap-x-5">
          <Button
            value="Buy"
            handleClick={() => setSelectToggle("buy")}
            className={`${
              selectToggle === "buy"
                ? "border-primary text-primary bg-purple-50"
                : ""
            } w-full py-2 md:py-3 border rounded-xl text-center text-sm md:text-base transition duration-1000 ease-in-out hover:bg-gray-100`}
          />
          <Button
            value="Sell"
            handleClick={() => setSelectToggle("sell")}
            className={`${
              selectToggle === "sell"
                ? "border-primary text-primary bg-purple-50"
                : ""
            } w-full py-2 md:py-3 border rounded-xl text-center text-sm md:text-base transition duration-1000 ease-in-out hover:bg-gray-100`}
          />
          <Button
            value="Rent"
            handleClick={() => setSelectToggle("rent")}
            className={`${
              selectToggle === "rent"
                ? "border-primary text-primary bg-purple-50"
                : ""
            } w-full py-2 md:py-3 border rounded-xl text-center text-sm md:text-base transition duration-1000 ease-in-out hover:bg-gray-100`}
          />
        </div>
      </div>

      <hr className="w-full mt-6" />
      <div className="mt-5">
        <h6 className="font-medium text-base md:text-xl">Details</h6>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full flex flex-col md:flex-row items-center justify-between gap-x-5 mt-5">
                <div className="w-full">
                  <FormikControl
                    label="Full Name"
                    control="input"
                    name="name"
                    placeholder="Enter Your Full Name"
                  />
                </div>
                <div className="w-full mt-5 md:mt-0">
                  <FormikControl
                    label="Phone Number"
                    control="input"
                    name="phone"
                    type="number"
                    placeholder="Enter Your Phone Number"
                  />
                </div>
              </div>

              <div className="w-full mt-5">
                <FormikControl
                  label="Email Address"
                  control="input"
                  name="email"
                  type="email"
                  placeholder="Enter Your Email Address"
                />
              </div>

              <div className="w-full mt-5">
                <FormikControl
                  label="Message"
                  control="textarea"
                  name="message"
                  type="email"
                  placeholder="Type Your Message Here..."
                />
              </div>

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <div
                  className="border w-full rounded-2xl text-primary cursor-pointer font-medium py-4 text-center "
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setAgentEnquiry(false)}
                >
                  <Button value="Cancel" />
                </div>

                <Button
                  value="Submit"
                  type="submit"
                  className="border w-full rounded-2xl bg-primary cursor-pointer text-white text-sm md:text-base font-medium py-4 text-center"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AgentEnquiryPopup;
