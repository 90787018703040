import React, { useEffect } from "react";
import { GridNewsCard, NewsCard, NewsCardSkeleton } from "../../Component";
import Header from "./Header";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetAllNewsData, removeNewsData } from "../../Redux/News/action";
import NewsSkeleton from "./NewsSkeleton";

const NewsPage = () => {
  const news = useSelector((state) => state.news.news, shallowEqual);
  const loading = useSelector((state) => state.news.isloading, shallowEqual);

  const dispatch = useDispatch();

  console.log(loading);
  console.log(news);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAllNewsData());
    return () => {
      dispatch(removeNewsData());
    };
  }, []);

  if (loading) {
    return <NewsSkeleton />;
  }

  return (
    <div className="py-16 md:py-24 w-11/12 xl:w-3/4 mx-auto">
      <div className="mt-10">
        <Header news={news} />
      </div>
      {news.categories &&
        news.categories.map((data) => {
          return data.news && data.news.length ? (
            <div key={data.id} className="mt-16">
              <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
                {data.name}
              </h4>
              <hr className="w-20 border border-primary mt-4" />

              {data.news.length > 4 ? (
                <NewsCard data={data.news} />
              ) : (
                <GridNewsCard data={data.news} />
              )}
            </div>
          ) : null;
        })}
    </div>
  );
};

export default NewsPage;
