import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Button } from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";

const NextCommonCompo = ({
  title,
  data,
  value,
  setValue,
  setCurrentCompo,
  setPrevCompo,
  setNextCompo,
  className,
}) => {
  const handleClickBack = () => {
    setCurrentCompo(false);
    setPrevCompo(true);
    scrollToTop();
  };

  const handleClickNext = () => {
    setCurrentCompo(false);
    setNextCompo(true);
    scrollToTop();
  };
  return (
    <div className="mt-5 md:mt-16 w-full md:w-11/12 lg:w-3/4 pb-5 mx-auto rounded-xl border bg-white">
      <div className="px-5 md:px-10 pt-5 md:pt-10">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          {title}
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <div className={className}>
          {data.map((val, i) => {
            return (
              <div key={i} className="w-full mt-4 sm:mt-0">
                <Button
                  value={val}
                  className={`w-full rounded-xl border py-3 text-xs md:text-base transition ease-in-out duration-1000 hover:bg-purple-50 ${
                    value === val
                      ? "text-primary border-primary bg-purple-50"
                      : "text-textPrimary"
                  }`}
                  handleClick={() => setValue(val)}
                />
              </div>
            );
          })}
        </div>
      </div>

      <hr className="w-full mt-10" />
      <div className="px-5 md:px-10 mt-8 flex items-center justify-between">
        <div
          className="flex items-center gap-x-2 text-sm md:text-base cursor-pointer text-primary"
          onClick={handleClickBack}
        >
          <BsArrowLeft />
          <p>Previous Question</p>
        </div>

        <Button
          value="Next"
          className={`rounded-xl py-2 text-sm md:text-base md:py-3 px-10  ${
            value && value.length
              ? "bg-primary text-white"
              : "bg-gray-100 text-gray-400"
          }`}
          disable={value && value.length ? false : true}
          handleClick={handleClickNext}
        />
      </div>
    </div>
  );
};

export default NextCommonCompo;
