const ExploreAgent = [
  {
    id: 1,
    tag: "#1",
    title: "Search for an agent",
    description: "Enter your suburb to find agents specialised in your area.",
  },
  {
    id: 2,
    tag: "#2",
    title: "Compare their stats",
    description:
      "Compare agent stats and their reviews to see who might be right for you.",
  },

  {
    id: 3,
    tag: "#3",
    title: "Connect with the best",
    description:
      "Get a free market appraisal from agents before making your decision.",
  },
];

export default ExploreAgent;
