import React from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetPrivacyAndPolicy,
  removeTermsPrivacy,
} from "../../Redux/Terms-Privacy/action";
import { scrollToTop } from "../../Component/NavigateTop";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();

  const privacyPolicy = useSelector(
    (state) => state.termsprivacy.privacyPolicy,
    shallowEqual
  );

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetPrivacyAndPolicy());
    return () => {
      dispatch(removeTermsPrivacy());
    };
  }, []);

  return (
    <div className="py-16 md:py-24 w-11/12 xl:w-3/4 mx-auto">
      <p
        className="mt-10 text-textPrimary"
        dangerouslySetInnerHTML={{
          __html: privacyPolicy,
        }}
      ></p>
    </div>
  );
};

export default PrivacyPolicy;
