import React from "react";
import { NavLink } from "react-router-dom";

const menu = [
  {
    id: 1,
    name: "Profile",
    link: "/me",
  },

  {
    id: 20,
    name: "Inbox",
    link: "/me/my-enquiry",
  },

  {
    id: 2,
    name: "Agent Enquiries",
    link: "/me/agent-enquiry",
  },

  {
    id: 3,
    name: "My Inspections",
    link: "/me/inspections-list",
  },

  {
    id: 4,
    name: "Quick Enquiries",
    link: "/me/agent-enquiries",
  },

  {
    id: 5,
    name: "Saved Property",
    link: "/me/saved-properties",
  },

  {
    id: 6,
    name: "Saved Searches",
    link: "/me/saved-searches",
  },

  // {
  //   id: 7,
  //   name: "Notification Setting",
  //   link: "/me/notification-settings",
  // },
];

const data = [
  {
    id: 7,
    name: "Agent Request",
    link: "/me/agent-request",
  },
];
const AccountHeader = () => {
  return (
    <div className="w-full h-full">
      <div className="border w-full px-3 py-5 rounded-lg flex flex-col gap-y-2 bg-white">
        {menu.map((val) => {
          return (
            <NavLink
              key={val.id}
              end
              to={val.link}
              className={({ isActive, isPending }) =>
                isActive
                  ? " text-white rounded-xl text-base bg-primary py-3 px-5 font-medium"
                  : "text-sm py-3 text-textSecondary px-5 hover:text-textPrimary"
              }
            >
              {val.name}
            </NavLink>
          );
        })}
      </div>

      <div className="border w-full px-3 py-2 rounded-lg flex flex-col gap-y-2 bg-white mt-7">
        {data.map((val) => {
          return (
            <NavLink
              key={val.id}
              end
              to={val.link}
              className={({ isActive, isPending }) =>
                isActive
                  ? " text-white rounded-xl text-base bg-primary py-3 px-5 font-medium"
                  : "text-sm py-3 text-textSecondary px-5 hover:text-textPrimary"
              }
            >
              {val.name}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default AccountHeader;
