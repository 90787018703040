import React from "react";
import { Button, SearchCardSkeleton } from "../../Component";

const SearchSkeleton = () => {
  return (
    <div className="py-24 border h-full animate-pulse">
      <div className="w-3/4 mx-auto">{/* <Header /> */}</div>
      <hr className="mt-6" />
      <div className="grid grid-cols-12 sm:gap-x-10 xl:gap-x-16 mt-10 w-11/12 lg:w-3/4 mx-auto">
        <div className="col-span-12 sm:col-span-8 xl:col-span-7">
          <div className="w-full flex flex-col gap-y-5 sm:gap-y-0 sm:flex-row items-center justify-between">
            <div className="w-full sm:w-4/6 2xl:w-3/4">
              <div className="text-textPrimary w-full bg-slate-200 h-7 rounded-full font-semibold text-xl tracking-wide"></div>
              <p className="text-lg text-textPrimary tracking-wide mt-2 h-6 rounded-full bg-slate-200 "></p>
            </div>

            <div className="w-28 h-7 bg-slate-200 rounded-full border py-2.5 px-2 gap-x-2 flex items-center cursor-pointer"></div>
          </div>
          <div className="flex items-center justify-between mt-6">
            <div className="flex items-center gap-x-4 sm:gap-x-6">
              <div className="w-28 h-5 bg-slate-200 rounded-full"></div>

              <div className="w-28 h-5 bg-slate-200 rounded-full"></div>

              <div className="w-28 h-5 bg-slate-200 rounded-full"></div>
            </div>
          </div>

          <div className="mt-10">
            <SearchCardSkeleton />
          </div>
        </div>
        <div className="col-span-12 mt-10 sm:mt-0 sm:col-start-9 sm:col-end-13 border p-5 rounded-lg h-fit">
          <div className="text-2xl w-11/12 h-6 font-bold text-textPrimary bg-slate-200 rounded-full"></div>
          <p className="text-textSecondary mt-2 bg-slate-200 h-5 rounded-full"></p>
          <Button
            value="Make a Quick Enquiry"
            className="bg-slate-200  text-slate-200 w-full rounded-full py-3 mt-7"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchSkeleton;
