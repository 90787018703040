import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  privacyPolicy: "",
  termsofuse: "",
  addedAt: "",
};

export const TermsPrivacy = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PRIVACY_POLICIES:
      return {
        ...state,
        isloading: false,
        success: true,
        addedAt: action.payload.added_date,
        privacyPolicy: action.payload.article_details,
      };

    case ActionTypes.GET_TERMS_OF_USE:
      return {
        ...state,
        isloading: false,
        success: true,
        addedAt: action.payload.added_date,
        termsofuse: action.payload.article_details,
      };
    case ActionTypes.REMOVE_TERMS_PRIVACY_DATA:
      return {
        isloading: true,
        success: false,
        privacyPolicy: "",
        termsofuse: "",
        addedAt: "",
      };

    default:
      return state;
  }
};
