import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const enquiryLoading = () => ({
  type: ActionTypes.ENQUIRY_LOADING,
});

export const removeEnquiryData = () => ({
  type: ActionTypes.REMOVE_ENQUIRY_DATA,
});

export const fetchGetEnquiryOptions = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/v3/enquiry_options`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_ENQUIRY_OPTIONS,
      payload: data,
    });
  });
};

export const fetchSendAgentEnquiry = (body, token) => async (dispatch) => {
  await axios
    .post(
      `${BaseUrl}/v3/enquiry_options`,
      body,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_AGENT_ENQUIRY_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_AGENT_ENQUIRY_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchGetAgentEnquiryDetail =
  (enquiryId, userId, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/v3/agent_enquiry_users/${enquiryId}/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_AGENT_ENQUIRY_DETAIL,
          payload: data,
        });
      });
  };

export const fetchGetQuickEnquiryChat =
  (enquiryId, userEnquiryId, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/v3/agent_enquiry_users/${enquiryId}/${userEnquiryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_QUICK_ENQUIRY_CHAT,
          payload: data,
        });
      });
  };

export const fetchGetMyEnquiry = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v3/agent_enquiry?api_from=classiEstate&page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_ENQUIRY,
        payload: data,
      });
    });
};

export const fetchSendPropertyEnquiry = (body, token) => async (dispatch) => {
  await axios
    .post(
      `${BaseUrl}/v3/respond_ads`,
      body,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_PROPERTY_ENQUIRY_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_PROPERTY_ENQUIRY_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchSendEnquiryReply = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/add_enquiryChatMsg`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_AGENT_ENQUIRY_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_AGENT_ENQUIRY_FAIL,
        payload: e.response.data,
      });
    });
};
