import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const loading = () => ({
  type: ActionTypes.BANNER_LOADING,
});

export const removeBannerData = () => ({
  type: ActionTypes.REMOVE_BANNER_DATA,
});

export const fetchGetBanner = (state, suburb, subCat) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/v3/presets/banners?state=${state}&suburb=${suburb}&cat_id=8&subcat_id=${subCat}`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_BANNER,
        payload: data,
      });
    });
};
