import React from "react";
import { Button, Filter } from "../../Component";
import { BsListCheck } from "react-icons/bs";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import {
  fetchGetDefaultSearch,
  fetchGetExploreSearch,
  fetchGetSearchResults,
  removeSearchData,
} from "../../Redux/Search/action";
import { useEffect } from "react";
import Cookies from "js-cookie";

const Header = ({
  showMap,
  setShowList,
  setShowMap,
  propertyCategory,
  setShowFilter,
  value,
  setValue,
  filterData,
  page,
  setInspection,
}) => {
  const [propertyAddress, SetPropertyAddress] = useState([]);
  const [propertyState, setPropertyState] = useState([]);
  const [propertyStreetName, setPropertyStreetName] = useState([]);
  const [propertySuburb, setPropertySuburb] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = Cookies.get("token");

  const datas = localStorage.getItem("search-property");
  const parsedData = JSON.parse(datas);

  const getLocationData = async () => {
    if (value.length > 0) {
      const search = value.map((val) => val.label);

      const promises = search.map(async (item) => {
        return await geocodeByAddress(item);
      });
      const geocodes = await Promise.all(promises);
      const description = geocodes.map((item) => item[0]);

      // property address
      description.map((val, i) => {
        return SetPropertyAddress((pre) => [...pre, val.formatted_address]);
      });

      // property state
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes("administrative_area_level_1")
        );

        data.map((val) => {
          return setPropertyState((pre) => [...pre, val.short_name]);
        });
      });

      // property street name
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes("route")
        );

        data.map((val) => {
          return setPropertyStreetName((pre) => [...pre, val.long_name]);
        });
      });

      // property Suburb
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes(
            "locality" || "political" || "administrative_area_level_2"
          )
        );

        data.map((val) => {
          return setPropertySuburb((pre) => [...pre, val.long_name]);
        });
      });
      window.location.reload(false);

      localStorage.setItem("Search-value", JSON.stringify(value));
    }
  };

  useEffect(() => {
    if (
      (propertyAddress && propertyAddress.length) ||
      (propertyState && propertyState.length) ||
      (propertyStreetName && propertyStreetName.length) ||
      (propertySuburb && propertySuburb.length)
    ) {
      dispatch(
        fetchGetSearchResults(
          propertyCategory,
          propertyAddress,
          propertyStreetName,
          propertySuburb,
          propertyState,
          null,
          null,
          null,
          [],
          null,
          null,
          null,
          null,
          1,
          token
        )
      );

      navigate(
        `/search?property_category=${propertyCategory}&key=${propertyAddress}`
      );
      window.location.reload(false);
    }
  }, [
    propertyCategory,
    propertyAddress,
    propertyStreetName,
    propertySuburb,
    propertyState,
  ]);

  const handleClickList = () => {
    setShowList(true);
    setShowMap(false);
    setInspection("");
    dispatch(removeSearchData());
    setTimeout(() => {
      parsedData && parsedData.lat && !parsedData.is_featured
        ? dispatch(
            fetchGetDefaultSearch(
              propertyCategory,
              parsedData.lat,
              parsedData.lng,
              parsedData.bedroom,
              parsedData.bathroom,
              parsedData.parking,
              parsedData.type,
              parsedData.minPrice,
              parsedData.maxPrice,
              parsedData.minArea,
              parsedData.maxArea,
              page,
              token
            )
          )
        : parsedData.is_featured
        ? dispatch(
            fetchGetExploreSearch(
              propertyCategory,
              parsedData.lat,
              parsedData.lng,
              parsedData.is_featured === 1 ? 1 : 2,
              parsedData.bedroom,
              parsedData.bathroom,
              parsedData.parking,
              parsedData.type,
              parsedData.minPrice,
              parsedData.maxPrice,
              parsedData.minArea,
              parsedData.maxArea,
              page,
              token
            )
          )
        : dispatch(
            fetchGetSearchResults(
              propertyCategory,
              parsedData.address,
              parsedData.streetName,
              parsedData.suburb,
              parsedData.state,
              parsedData.bedroom,
              parsedData.bathroom,
              parsedData.parking,
              parsedData.type,
              parsedData.minPrice,
              parsedData.maxPrice,
              parsedData.minArea,
              parsedData.maxArea,
              page,
              token
            )
          );
    }, 100);
  };

  return (
    <div className="py-4 mt-6 w-full flex flex-wrap items-center justify-between">
      <div className="w-full sm:w-1/2 md:w-3/5 lg:w-[35%] 2xl:w-2/5">
        <GooglePlacesAutocomplete
          placeholder="Where is it?"
          textInputProps={{
            placeholderTextColor: "#32a852",
            returnKeyType: "search",
          }}
          autocompletionRequest={{
            componentRestrictions: { country: ["au"] },
          }}
          selectProps={{
            value,
            onChange: setValue,
            isMulti: true,
          }}
        />
      </div>
      <div className="flex items-center gap-x-3 lg:gap-x-3 mt-5 sm:mt-0">
        <Button
          value="Search"
          className="bg-primary text-xs sm:text-sm w-fit px-7 lg:px-5 xl:px-7 py-4 text-white rounded-xl"
          handleClick={getLocationData}
        />
        <Filter
          handleClick={() => setShowFilter(true)}
          className="border border-primary text-primary rounded-2xl text-xs sm:text-sm w-fit px-7 py-3 sm:py-3.5 flex items-center gap-x-3 justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50"
        />
      </div>

      <div className="flex items-center gap-x-2 mt-5 lg:mt-0">
        <Button
          handleClick={() => setShowFilter(true)}
          value={`${
            filterData && filterData.type
              ? filterData.type?.length === 1
                ? filterData.type
                : `Property Type` + " " + `(${filterData.type?.length})`
              : "Property Type"
          } `}
          className="w-fit px-2 sm:px-5 lg:px-3 2xl:px-5 text-xs sm:text-sm py-3.5 border rounded-xl text-textPrimary transition duration-1000 ease-in-out hover:bg-gray-100"
        />

        <Button
          handleClick={() => setShowFilter(true)}
          value="Price"
          className="w-fit px-5 lg:px-3 2xl:px-5 text-xs sm:text-sm py-3.5 border rounded-xl text-textPrimary transition duration-1000 ease-in-out hover:bg-gray-100"
        />

        <Button
          handleClick={() => setShowFilter(true)}
          value={`${
            filterData && filterData.bedroom
              ? filterData.bedroom + " " + `Beds`
              : "Bed"
          }`}
          className="w-fit px-5 lg:px-3 2xl:px-5 text-xs sm:text-sm py-3.5 border rounded-xl text-textPrimary transition duration-1000 ease-in-out hover:bg-gray-100"
        />
        {showMap && (
          <div
            className="w-fit border text-sm rounded-xl py-3 px-5 gap-x-3 flex items-center cursor-pointer transition duration-1000 ease-in-out hover:bg-gray-100"
            onClick={handleClickList}
          >
            <BsListCheck className="text-lg" />
            <div className="text-sm  ">List</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
