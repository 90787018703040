import React, { useState } from "react";
import { Button, Search } from "../../Component";

const Header = ({
  title,
  subTitle,
  img,
  handleClick,
  onchangeSearchHandler,
  locationSuggestions,
  nameSuggestion,
  disable,
  handleClickSuggestion,
}) => {
  const [location, setLocation] = useState(true);
  const [name, setName] = useState(false);

  const handleClickLocation = () => {
    setLocation(true);
    setName(false);
  };

  const hanldeClickName = () => {
    setLocation(false);
    setName(true);
  };
  return (
    <div className="w-full relative">
      <div className="h-[470px] md:h[580px] xl:h-[610px] w-full">
        <img src={img} alt="banner" className="w-full h-full hidden md:block" />
      </div>

      <div className="absolute w-full md:w-[700px] lg:w-[750px] xl:w-[900px] border xl:translate-x-1/4 2xl:translate-x-1/3 bg-white rounded-lg md:shadow-md top-0 sm:top-5 md:top-8 lg:top-16 xl:top-20 px-7 py-10 homeContainer">
        <h4 className="text-textPrimary font-semibold text-xl md:text-2xl xl:text-3xl">
          {title}
        </h4>
        <p className="text-textSecondary mt-2 text-lg">{subTitle}</p>

        <div className="border-b flex items-center gap-x-6 mt-6 md:mt-12">
          <h6
            className={`${
              location
                ? "text-primary border-b-2 font-medium border-primary "
                : "text-textSecondary"
            } text-sm md:text-base lg:text-lg xl:text-xl  px-6 py-3  w-fit cursor-pointer`}
            onClick={handleClickLocation}
          >
            Location
          </h6>
          <h6
            className={`${
              name
                ? "text-primary border-b-2 font-medium border-primary "
                : "text-textSecondary"
            } text-sm md:text-base lg:text-lg xl:text-xl  px-6 py-3  w-fit cursor-pointer`}
            onClick={hanldeClickName}
          >
            Name
          </h6>
        </div>

        <div className="mt-8 flex items-center flex-wrap md:flex-nowrap gap-x-10 justify-between">
          <div className="w-full relative">
            <Search
              placeholder={`${
                location
                  ? "Search region, suburb or postcode"
                  : "Search by Name"
              }`}
              onchangeSearchHandler={onchangeSearchHandler}
            />
            {(location && locationSuggestions && locationSuggestions.length) ||
            (name && nameSuggestion && nameSuggestion.length) ? (
              <div className="w-full absolute border h-40 overflow-y-auto bg-white mt-1 rounded-xl">
                {name &&
                  nameSuggestion &&
                  nameSuggestion.map((val) => {
                    return (
                      <h6
                        key={val.id}
                        className="px-5 py-2.5 cursor-pointer hover:bg-slate-50 text-sm text-textPrimary"
                        onClick={() => handleClickSuggestion(val.name)}
                      >
                        {val.name}
                      </h6>
                    );
                  })}

                {location && locationSuggestions
                  ? locationSuggestions.map((val) => {
                      return (
                        <h6
                          key={val.id}
                          className="px-5 py-2.5 cursor-pointer hover:bg-slate-50 text-sm text-textPrimary"
                          onClick={() => handleClickSuggestion(val.name)}
                        >
                          {val.name}
                        </h6>
                      );
                    })
                  : null}
              </div>
            ) : null}
          </div>

          <Button
            value="Search"
            className="bg-primary py-2 mt-5 md:mt-0 md:py-3.5 px-5 md:px-10 rounded-xl text-white"
            handleClick={handleClick}
            disable={disable}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
