import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const notificationLoading = () => ({
  type: ActionTypes.NOTIFICATION_LOADING,
});

export const removeNotificationData = () => ({
  type: ActionTypes.REMOVE_NOTIFICATIOn,
});

export const fetchGetNotification = (page, token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/v3/user_notifications_new?api_from=classiEstate&slug=real-estate&page=${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_NOTIICATION,
        payload: data,
      });
    });
};

export const fetchMakeNotificationSeen = (token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/v3/seen_notifications?api_from=classiEstate&slug=real-estate`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.MAKE_NOTIFICATION_SEEN,
        payload: data,
      });
    });
};
