import React, { useEffect } from "react";
import { BsImageAlt } from "react-icons/bs";
import { RiImageLine } from "react-icons/ri";
import { SiYoutubemusic } from "react-icons/si";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { scrollToTop } from "../NavigateTop";
import { AiOutlineClose } from "react-icons/ai";

const PreviewImages = ({
  images,
  setShowPreview,
  handleClickImages,
  handleClickFloor,
  handleClickVideo,
  handleClickClose,
  showImages,
  showFloors,
  showVideos,
}) => {
  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowBack />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowForward />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleClick = () => {
    handleClickClose();
    setShowPreview(false);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className="w-full text-end flex px-5 justify-end">
        <AiOutlineClose
          className="text-white text-4xl cursor-pointer hover:text-gray-200"
          onClick={handleClick}
        />
      </div>
      <div className="w-fit mt-6 sm:mt-0 mx-auto z-40 rounded-full border bg-white py-2 px-5 flex items-center gap-x-2 sm:gap-x-7">
        <div className="flex items-center gap-x-5">
          <div
            className={`flex items-center w-fit gap-x-3 cursor-pointer ${
              showImages ? "text-primary" : "text-textSecondary"
            }`}
            onClick={handleClickImages}
          >
            <BsImageAlt className="text-sm sm:text-lg" />
            <p className="font-medium text-xs sm:text-base">Photos</p>
          </div>

          <div className="border-r-2 h-5"></div>
        </div>

        <div className="flex items-center gap-x-5">
          <div
            className={`flex items-center w-fit gap-x-3 cursor-pointer ${
              showFloors ? "text-primary" : "text-textSecondary"
            }`}
            onClick={handleClickFloor}
          >
            <RiImageLine className="text-sm sm:text-lg" />
            <p className="font-medium text-xs sm:text-base">Floor Plan</p>
          </div>

          <div className="border-r-2 h-5"></div>
        </div>

        <div className="flex items-center gap-x-5">
          <div
            className={`${
              showVideos ? "text-primary" : "text- text-textSecondary"
            } flex items-center w-fit gap-x-3 cursor-pointer`}
            onClick={handleClickVideo}
          >
            <SiYoutubemusic className="text-sm sm:text-lg" />
            <p className="font-medium text-xs sm:text-base">Videos</p>
          </div>
        </div>
      </div>

      <div className="w-11/12 sm:w-3/4 lg:w-10/12 xl:w-2/3 mx-auto h-[55vh] lg:h-[75vh] xl:h-[70vh] rounded-md mt-10">
        <Slider {...settings} className="z-40">
          {images &&
            images.map((val) => {
              return (
                <div
                  key={val.image_id}
                  className="w-full h-[55vh] lg:h-[75vh] xl:[70vh] px-1"
                >
                  {val.media_type === "video" ||
                  val.media_type === "virtualTour" ||
                  val.media_type === "webLink" ? (
                    val.link_url ? (
                      <iframe
                        src={val.link_url}
                        width="100%"
                        height="100%"
                        frameborder="0"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <h1 className="text-xl text-white">{val.message}</h1>
                    )
                  ) : val.image_name ? (
                    <img
                      src={val.image_name}
                      alt=""
                      className="w-full h-full rounded-md object-cover"
                    />
                  ) : (
                    <h1 className="text-3xl flex justify-center items-center mt-10 text-white">
                      {val.message}
                    </h1>
                  )}
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
};

export default PreviewImages;
