export const AGENT_AGENCIES_LOADING = "AGENT_AGENCIES_LOADING";
export const GET_SEARCH_SUGGESTIONS = "GET_SEARCH_SUGGESTIONS";
export const GET_AGENT_SEARCH_RESULT = "GET_AGENT_SEARCH_RESULT";
export const GET_AGENCIES_SEARCH_RESULT = "GET_AGENCIES_SEARCH_RESULT";
export const GET_AGENT_DETAIL = "GET_AGENT_DETAIL";
export const GET_AGENCY_DETAIL = "GET_AGENCY_DETAIL";
export const SEND_ENQUIRY_TO_AGENT_SUCCESS = "SEND_ENQUIRY_TO_AGENT_SUCCESS";
export const SEND_ENQUIRY_TO_AGENT_FAIL = "SEND_ENQUIRY_TO_AGENT_FAIL";
export const SEND_AGENCY_ENQUIRY_SUCCESS = "SEND_AGENCY_ENQUIRY_SUCCESS";
export const SEND_AGENCY_ENQUIRY_FAIL = "SEND_AGENCY_ENQUIRY_FAIL";
export const REMOVE_AGENT_AGENCY_DATA = "REMOVE_AGENT_AGENCY_DATA";
