import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetSoldProperties,
  removeSoldData,
} from "../../Redux/Sold/action";
import Skeleton from "./Skeleton";
import {
  GridPropertyCard,
  Pagination,
  SinglePagnination,
} from "../../Component";
import {
  fetchAddToWishlist,
  fetchRemoveWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { scrollToTop } from "../../Component/NavigateTop";
import { toast } from "react-toastify";
import { useState } from "react";

const Sold = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const loading = useSelector((state) => state.sold.isloading, shallowEqual);
  const properties = useSelector((state) => state.sold.posts, shallowEqual);
  const totalData = useSelector((state) => state.sold.totalData, shallowEqual);
  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.wishlist.errMsg,
    shallowEqual
  );

  const totalPage = Math.ceil(totalData / 8);

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddToWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlist(body, token));
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetSoldProperties(page, token));
    return () => {
      dispatch(removeSoldData());
    };
  }, [page]);

  useEffect(() => {
    // message && dispatch(removeSoldData());
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    message && dispatch(fetchGetSoldProperties(page, token));
  }, [message]);

  useEffect(() => {
    // errMessage && dispatch(removeSoldData());
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeWishlistData());
    errMessage && dispatch(fetchGetSoldProperties(page, token));
  }, [errMessage]);

  if (loading) {
    return (
      <div className="py-16 md:py-24 w-11/12 xl:w-3/4 mx-auto">
        <Skeleton />
      </div>
    );
  }
  return (
    <div className="py-16 md:py-24 relative">
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Our Sold Property
        </h4>
        <hr className="w-20 border border-primary mt-4" />
        <h4 className="text-textPrimary font-medium text-sm sm:text-base md:text-xl mt-10 tracking-wide">
          View the sold properties from classiEstate
        </h4>

        {properties && properties.length ? (
          <>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
              {properties.map((val) => {
                return (
                  <div key={val.post_id}>
                    <GridPropertyCard
                      data={val}
                      handleAddToWishlist={handleAddToWishlist}
                      handleRemoveWishlist={handleRemoveWishlist}
                    />
                  </div>
                );
              })}
            </div>

            <div className="mt-14">
              {totalPage > 5 ? (
                <Pagination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              ) : (
                <SinglePagnination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Sold;
