import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetMyEnquiry,
  removeEnquiryData,
} from "../../Redux/Enquiry/action";
import {
  Button,
  MyEnquiriesCard,
  Pagination,
  SinglePagnination,
} from "../../Component";
import { useState } from "react";
import MyEnquirySkeleton from "./MyEnquirySkeleton";
import { scrollToTop } from "../../Component/NavigateTop";
import { noData } from "../../Asset/Assest";
import OfferMessages from "../Inbox/OfferMessages";
import { useNavigate } from "react-router-dom";
import MyEnquiryDetail from "./Popup/MyEnquiryDetail";

const MyEnquiry = () => {
  const [page, setPage] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const [enquiryDetail, setEnquiryDetail] = useState();

  const [toggle, setToggle] = useState({
    enquiry: true,
    inbox: false,
  });
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const navigate = useNavigate();

  const myEnquiries = useSelector(
    (state) => state.enquiry.myEnquiry,
    shallowEqual
  );
  const totalData = useSelector(
    (state) => state.enquiry.totalData,
    shallowEqual
  );

  const loading = useSelector((state) => state.enquiry.isloading, shallowEqual);

  const totalPage = Math.ceil(totalData / 10);

  const handleClickEnquiry = () => {
    setToggle({
      enquiry: true,
      inbox: false,
    });
  };

  const handleClickInbox = () => {
    setToggle({
      enquiry: false,
      inbox: true,
    });

    navigate("/me/inbox/enquires-list");
  };

  const handleClickViewDetail = (id) => {
    const test = myEnquiries.find((val) => val.id === id);
    setEnquiryDetail(test);
    setShowDetail(true);
    scrollToTop();
  };

  useEffect(() => {
    dispatch(fetchGetMyEnquiry(page, token));
    return () => {
      dispatch(removeEnquiryData());
    };
  }, [page]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="w-full">
        <MyEnquirySkeleton />
      </div>
    );
  }

  return (
    <div className="w-full h-full relative">
      <div className="w-full h-fit border bg-white px-5 py-5 rounded-xl">
        <h5 className="font-semibold text-2xl">Agent Enquiries</h5>

        {/* <hr className="w-full mt-5" />
        <div className="mt-8 w-fit rounded-xl border">
          <Button
            value="Enquiry"
            handleClick={handleClickEnquiry}
            className={`w-fit px-7 py-2 md:py-3 rounded-xl transition duration-300 ease-in-out ${
              toggle.enquiry
                ? " bg-primary text-white"
                : "bg-white text-textSecondary"
            }`}
          />

          <Button
            value="Inbox"
            handleClick={handleClickInbox}
            className={`w-fit px-7 py-2 md:py-3 rounded-xl transition duration-300 ease-in-out ${
              toggle.inbox
                ? " bg-primary text-white"
                : "bg-white text-textSecondary"
            }`}
          />
        </div> */}
      </div>
      <div
        className={`w-full relative ${showDetail ? "min-h-screen" : "h-full"}`}
      >
        {toggle.enquiry ? (
          myEnquiries && myEnquiries.length ? (
            <>
              <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-x-5">
                {myEnquiries.map((val) => {
                  return (
                    <div className="mt-3" key={val.id}>
                      <MyEnquiriesCard
                        myEnquiry={val}
                        handleClickViewDetail={handleClickViewDetail}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="mt-10">
                {totalPage > 5 ? (
                  <Pagination
                    setPage={setPage}
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                  />
                ) : (
                  <SinglePagnination
                    setPage={setPage}
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="relative">
              <img
                src={noData}
                alt=""
                className="w-full h-full object-contain"
              />
              <h2 className="absolute top-1/2 left-[7%] lg:left-[20%] 2xl:left-1/4 text-white font-semibold text-sm md:text-xl lg:text-2xl text-center w-full lg:w-3/4 2xl:w-2/3 mx-auto">
                No Inquiries Found
              </h2>
            </div>
          )
        ) : (
          <OfferMessages />
        )}

        {showDetail && (
          <MyEnquiryDetail
            enquiryDetail={enquiryDetail}
            setShowDetail={setShowDetail}
          />
        )}
      </div>
      {showDetail && (
        <div
          className="absolute bg-black w-full h-full top-0 opacity-10"
          onClick={() => setShowDetail(false)}
        >
          {" "}
        </div>
      )}
    </div>
  );
};

export default MyEnquiry;
