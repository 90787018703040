import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { fetchDeleteInspection } from "../../Redux/Profile/action";
import Cookies from "js-cookie";
import { classiBazarLogo, noProperty } from "../../Asset/Assest";
import { useNavigate } from "react-router-dom";

const MyInspectionCard = ({ myInspection }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const navigate = useNavigate();

  const handleDeleteInspection = (e, id) => {
    e.stopPropagation();
    const body = {
      inspection_id: id,
    };
    dispatch(fetchDeleteInspection(body, token));
  };
  return myInspection && myInspection.length ? (
    myInspection.map((val, i) => {
      return val.inspection_times.map((itm) => {
        return (
          <div
            className="border rounded-xl p-5 mt-5 hover:shadow-md cursor-pointer"
            key={i}
            onClick={() =>
              navigate(
                `/property-detail?property_category=${val.property_category}&indx=${itm.post_id}&key=${val.title}`
              )
            }
          >
            <div className="flex gap-x-5">
              <div className="w-40 h-20 md:w-48 md:h-32">
                {val.thumbnail ? (
                  <img
                    src={val.thumbnail}
                    alt=""
                    className="w-full h-full rounded-lg"
                  />
                ) : (
                  <img
                    src={classiBazarLogo}
                    alt=""
                    className="w-full h-full rounded-lg"
                  />
                )}
              </div>
              <div className="w-full h-full">
                <h6 className="font-semibold text-sm md:text-base lg:text-lg text-primary">
                  {val.title}
                </h6>
                <div className="w-full flex items-center justify-between mt-1">
                  <h6 className="font-semibold text-sm md:text-base lg:text-lg text-textPrimary">
                    {itm.inspection_date}
                  </h6>
                  <div
                    onClick={(e) =>
                      handleDeleteInspection(e, itm.inspection_id)
                    }
                    className="p-2 rounded-full hover:bg-gray-100 cursor-pointer transition duration-700 ease-in-out"
                  >
                    <RiDeleteBin6Line className="text-sm text-textSecondary md:text-lg" />
                  </div>
                </div>

                <h6 className="font-medium mt-1 md:mt-2 text-xs md:text-sm lg:text-base text-textPrimary">
                  {itm.start_hr}:{itm.start_min} - {itm.end_hr}:{itm.end_min}
                </h6>
                <p className="text-[0.5rem] md:text-sm text-textSecondary mt-3">
                  {val.ad_address}
                </p>
                {/* icons */}
                <div className="flex flex-wrap items-center gap-x-3 md:gap-x-5 mt-2 md:mt-4">
                  {val.ads_data &&
                    val.ads_data.map((item, i) => {
                      return item.value === null ||
                        item.value === "0" ||
                        item.value === " m sq." ? null : (
                        <div className="flex items-center gap-x-2" key={i}>
                          <img
                            src={item.img}
                            alt="parking"
                            className="w-3 h-3 md:w-5 md:h-5"
                          />
                          <h6 className="text-textPrimary text-[0.6rem] md:text-base lg:text-lg">
                            {item.value}
                          </h6>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        );
      });
    })
  ) : (
    <div className="">
      <img src={noProperty} alt="" className="w-full h-80 object-contain" />
      <h2 className="text-textPrimary font-semibold text-2xl lg:text-4xl text-center w-full lg:w-3/4 2xl:w-2/3 mx-auto">
        No Inspection Found
      </h2>
    </div>
  );
};

export default MyInspectionCard;

export const MyInspectionCardSkeleton = () => {
  return (
    <div className="border rounded-xl p-5 animate-pulse mt-5 hover:shadow-md bg-slate-200 cursor-pointer">
      <div className="flex gap-x-5">
        <div className="w-40 h-20 md:w-40 md:h-32 bg-slate-100 rounded-full"></div>
        <div className="w-full h-full">
          <div className="w-full flex items-center justify-between">
            <div className="font-semibold w-3/4 h-7 bg-slate-100 rounded-full text-sm md:text-base lg:text-lg text-textPrimary"></div>
            <div className="p-2 rounded-full w-7 h-7 bg-slate-100 cursor-pointer transition duration-700 ease-in-out"></div>
          </div>

          <div className="font-medium mt-1 bg-slate-100 w-3/4 h-7 rounded-full md:mt-2 text-xs md:text-sm lg:text-base text-textPrimary"></div>
          <div className="text-[0.5rem] md:text-sm bg-slate-100 h-5 w-3/4 rounded-full text-textSecondary mt-3"></div>
          {/* icons */}
          <div className="flex flex-wrap items-center gap-x-5 mt-3">
            <div className="flex items-center gap-x-2">
              <div className="w-5 h-5 bg-slate-100 rounded-full"></div>
              <div className="text-textPrimary bg-slate-100 w-10 h-3 rounded-full text-base lg:text-lg"></div>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="w-5 h-5 bg-slate-100 rounded-full"></div>
              <div className="text-textPrimary bg-slate-100 w-10 h-3 rounded-full text-base lg:text-lg"></div>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="w-5 h-5 bg-slate-100 rounded-full"></div>
              <div className="text-textPrimary bg-slate-100 w-10 h-3 rounded-full text-base lg:text-lg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
