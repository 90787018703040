import React from "react";
import { classiBazarLogo, noData } from "../../Asset/Assest";
import { Button, Pagination, SinglePagnination } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useEffect } from "react";
import {
  fetchGetAllOfferMessages,
  removeInboxData,
} from "../../Redux/Inbox/action";
import { useState } from "react";
import MyEnquirySkeleton from "../Profile/MyEnquirySkeleton";
import { Navigate, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";

const OfferMessages = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const navigate = useNavigate();

  const offerMessages = useSelector(
    (state) => state.inbox.offerMessages,
    shallowEqual
  );

  const totalData = useSelector((state) => state.inbox.totalData, shallowEqual);

  const loading = useSelector((state) => state.inbox.isloading, shallowEqual);

  const totalPage = Math.floor(totalData / 10);

  const handleClickDetails = (id) => {
    navigate(`/my-inbox?indx=${id}`);
  };

  useEffect(() => {
    dispatch(fetchGetAllOfferMessages(page, token));
    return () => {
      dispatch(removeInboxData());
    };
  }, [page]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (!token) {
    return <Navigate to={`/auth/sign-in`} replace />;
  }

  if (loading) {
    return (
      <div className="w-full mt-5">
        <MyEnquirySkeleton />
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      <div className="w-full h-fit border bg-white px-5 py-5 rounded-xl">
        <h5 className="font-semibold text-2xl">My Enquiries</h5>
      </div>
      <div className="w-full h-full relative">
        {offerMessages && offerMessages.length ? (
          <>
            <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-x-5">
              {offerMessages.map((val, i) => {
                return (
                  <div
                    className="w-full bg-white border p-5 rounded-xl hover:shadow-md mt-5"
                    key={i}
                  >
                    <div className="w-full flex gap-x-5 lg:gap-x-3">
                      <div className="w-28 h-20">
                        {val.image ? (
                          <img
                            src={val.image}
                            alt=""
                            className="w-full h-full object-fill rounded-xl"
                          />
                        ) : (
                          <img
                            src={classiBazarLogo}
                            alt=""
                            className="w-full h-full object-fill rounded-xl"
                          />
                        )}
                      </div>
                      <div className="w-full overflow-hidden">
                        <h5 className="font-medium text-sm md:text-base capitalize truncate">
                          {val.title}
                        </h5>
                        <p className="text-textSecondary mt-2 text-xs md:text-sm capitalize truncate2">
                          Replies: {val.reply_number}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <Button
                        handleClick={() => handleClickDetails(val.post_id)}
                        value="View Details"
                        className="border py-2 rounded-xl px-5 transition duration-1000 ease-in-out hover:bg-gray-100 text-textPrimary mt-4 md:py-3"
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-10">
              {totalPage > 5 ? (
                <Pagination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              ) : (
                <SinglePagnination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              )}
            </div>
          </>
        ) : (
          <div className="relative">
            <img src={noData} alt="" className="w-full h-full object-contain" />
            <h2 className="absolute top-1/2 left-[7%] lg:left-[20%] 2xl:left-1/4 text-white font-semibold text-sm md:text-xl lg:text-2xl text-center w-full lg:w-3/4 2xl:w-2/3 mx-auto">
              No Inquiries Found
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferMessages;
