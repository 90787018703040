import React from "react";
import { Link } from "react-router-dom";

const Header = ({ news }) => {
  return (
    <>
      <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
        Latest Property News
      </h4>
      <hr className="w-20 border border-primary mt-4" />
      <h4 className="text-textPrimary font-medium text-sm sm:text-base md:text-xl mt-10 tracking-wide">
        Read the latest real estate & finance news, property market trends and
        housing information and insights.
      </h4>

      <div className="grid grid-cols-12 gap-x-5 md:gap-x-10 w-full mt-7">
        <div className="col-span-7 h-full w-full relative">
          <Link to={`/news/${news.latest_news?.[0]?.news_id}`}>
            <img
              src={news.latest_news?.[0]?.news_pic}
              alt=""
              className="w-full h-full object-cover rounded-xl cursor-pointer"
            />
            <div className="absolute w-full h-full bg-black top-0 opacity-20 cursor-pointer rounded-xl"></div>
            <div className="absolute w-full px-2 sm:px-5 bottom-5 h-12 sm:h-16 lg:h-20 z-40 text-white cursor-pointer">
              <p className="text-xs sm:text-sm lg:text-base">News</p>
              <h6 className="font-semibold text-sm sm:text-base lg:text-lg w-full mt-1.5 truncate2">
                {news.latest_news?.[0]?.news_title}
              </h6>
            </div>
          </Link>
        </div>
        <div className="col-start-8 col-end-13 h-full w-full">
          <div className="flex flex-col gap-y-7">
            <div className="relative w-full h-full">
              <Link to={`/news/${news.latest_news?.[2]?.news_id}`}>
                <img
                  src={news.latest_news?.[2]?.news_pic}
                  alt=""
                  className="w-full h-full object-cover rounded-xl cursor-pointer"
                />
                <div className="absolute w-full h-full bg-black top-0 opacity-20 cursor-pointer rounded-xl"></div>
                <div className="absolute w-full px-2 sm:px-5 bottom-5 h-12 sm:h-16 lg:h-20 z-40 text-white cursor-pointer">
                  <p className="text-xs sm:text-sm lg:text-base">News</p>
                  <h6 className="font-semibold text-sm sm:text-base lg:text-lg w-full mt-1.5 truncate2">
                    {news.latest_news?.[2]?.news_title}
                  </h6>
                </div>
              </Link>
            </div>

            <div className="relative">
              <Link to={`/news/${news.latest_news?.[3]?.news_id}`}>
                <img
                  src={news.latest_news?.[3]?.news_pic}
                  alt=""
                  className="w-full h-full object-cover rounded-xl cursor-pointer"
                />
                <div className="absolute w-full h-full bg-black top-0 opacity-20 cursor-pointer rounded-xl"></div>
                <div className="absolute w-full px-2 sm:px-5 bottom-5 h-12 sm:h-16 lg:h-20 z-40 text-white cursor-pointer">
                  <p className="text-xs sm:text-sm lg:text-base">News</p>
                  <h6 className="font-semibold text-sm sm:text-base lg:text-lg w-full mt-1.5 truncate2">
                    {news.latest_news?.[3]?.news_title}
                  </h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
