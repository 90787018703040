import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const propertiesLoading = () => ({
  type: ActionTypes.PROPERTY_LOADING,
});

export const removePropertiesData = () => ({
  type: ActionTypes.REMOVE_PROPERTIES_DATA,
});

export const fetchGetLatestProperties =
  (category, token) => async (dispatch) => {
    return await axios
      .get(
        `${BaseUrl}/v3/ads_list?slug=real-estate&api_from=classiEstate&property_category=${category}&realestate_type=Residential`,
        token && token !== undefined
          ? { headers: { Authorization: `Bearer ${token}` } }
          : null
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.LATEST_PROPERTIES,
          payload: data,
        });
      });
  };

export const fetchGetFeaturedProperties =
  (category, token) => async (dispatch) => {
    return await axios
      .get(
        `${BaseUrl}/v3/ads_list?slug=real-estate&api_from=classiEstate&property_category=${category}&is_featured=1&realestate_type=Residential`,
        token && token !== undefined
          ? { headers: { Authorization: `Bearer ${token}` } }
          : null
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.FREATURED_PROPERTIES,
          payload: data,
        });
      });
  };

export const fetchGetPropertyDetails = (postId, token) => async (dispatch) => {
  return await axios
    .get(
      `${BaseUrl}/v3/ads_detail/${postId}`,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.PROPERTY_DETAILS,
        payload: data,
      });
    });
};
