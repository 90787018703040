import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  suggestions: [],
  agencies: [],
  agents: [],
  totalData: "",
  agentDetail: "",
  agencyDetail: "",
  message: "",
  errMsg: "",
};

export const AgentAndAgencies = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SEARCH_SUGGESTIONS:
      return {
        ...state,
        isloading: false,
        success: true,
        suggestions: action.payload,
      };

    case ActionTypes.GET_AGENT_SEARCH_RESULT:
      return {
        ...state,
        isloading: false,
        success: true,
        agents: action.payload.agents,
        totalData: action.payload.agents_numbers,
      };

    case ActionTypes.GET_AGENCIES_SEARCH_RESULT:
      return {
        ...state,
        isloading: false,
        success: true,
        agencies: action.payload.agencies,
        totalData: action.payload.agencies_number,
      };

    case ActionTypes.GET_AGENT_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        agentDetail: action.payload,
      };

    case ActionTypes.GET_AGENCY_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        agencyDetail: action.payload,
        totalData: action.payload.total_numbers,
      };

    case ActionTypes.SEND_ENQUIRY_TO_AGENT_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.SEND_ENQUIRY_TO_AGENT_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.SEND_AGENCY_ENQUIRY_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.SEND_AGENCY_ENQUIRY_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_AGENT_AGENCY_DATA:
      return {
        isloading: true,
        success: false,
        suggestions: [],
        agencies: [],
        agents: [],
        agentDetail: "",
        agencyDetail: "",
        message: "",
        errMsg: "",
        totalData: "",
      };

    default:
      return state;
  }
};
