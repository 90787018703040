import React from "react";
import { PropertyCardSkeleton } from "../../Component";

const Skeleton = () => {
  return (
    <div className="mt-10">
      <h4 className="text-textPrimary text-xl md:text-3xl font-semibold bg-slate-200 animate-pulse tracking-wide h-10 w-80 md:w-96 rounded-full"></h4>
      <hr className="w-20 md:w-40  mt-4 h-3 bg-slate-200 animate-pulse rounded-full" />
      <h4 className="text-textPrimary font-medium text-base md:text-xl mt-7 w-full h-8 rounded-full bg-slate-200 animate-pulse tracking-wide"></h4>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
        <PropertyCardSkeleton />
        <PropertyCardSkeleton />
        <PropertyCardSkeleton />
      </div>
    </div>
  );
};

export default Skeleton;
