const ExploreClassiEstate = [
  {
    id: 1,
    tag: "For Sale",
    title: "Find Homes for Sale",
    description:
      "With new homes added daily and 45+ search filters, Classiestate makes finding your next home to purchase simpler.",
    link: "Explore properties for Sale",
    url: "/",
  },
  {
    id: 2,
    tag: "For Rent",
    title: "Find Homes for Rent",
    description:
      "Finding properties for lease has never been easier. Classiestate makes finding and applying for renters online easily.",
    link: "Explore properties for Rent",
    url: "/rent",
  },

  {
    id: 3,
    tag: "For Agency",
    title: "Find Agencies",
    description:
      "Find about property deals only from the best agencies out there.",
    link: "Explore best Agencies",
    url: "/find-agencies",
  },
];

export default ExploreClassiEstate;
