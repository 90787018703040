import React from "react";
import { GrClose } from "react-icons/gr";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import Cookies from "js-cookie";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetNotification,
  fetchMakeNotificationSeen,
} from "../../Redux/Notification/action";

const MobileMenu = ({
  menu,
  setMobileMenu,
  mobileMenu,
  setShowNotification,
  page,
}) => {
  const navigate = useNavigate();

  const token = Cookies.get("token");

  const dispatch = useDispatch();

  const noOfUnseen = useSelector(
    (state) => state.notification.unSeen,
    shallowEqual
  );

  const handleClickLogout = () => {
    Cookies.remove("token");
    window.location.reload(false);
  };
  return (
    <div
      className={` bg-white fixed right-0 top-0 pb-10 h-full w-2/3 sm:w-2/6 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        mobileMenu ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="flex justify-end py-4 px-5">
        <div
          className="hover:border cursor-pointer hover:bg-gray-100 rounded-full p-2"
          onClick={() => setMobileMenu(false)}
        >
          <GrClose className="text-xl sm:text-2xl md:text-3xl" />
        </div>
      </div>
      {/* <hr className="mt-3" /> */}

      <div className="flex flex-col mt-6 gap-y-5 w-full z-50">
        {menu.map((val) => {
          return (
            <NavLink
              key={val.id}
              className="text-base md:text-lg px-7 hover:text-textSecondary text-textPrimary"
              to={val.link}
              onClick={() => setMobileMenu(false)}
            >
              {val.name}
            </NavLink>
          );
        })}

        <NavLink
          onClick={() => {
            setMobileMenu(false);
            setShowNotification(true);
            dispatch(fetchMakeNotificationSeen(token));
            setTimeout(() => {
              dispatch(fetchGetNotification(page, token));
            }, 1000);
          }}
          className="text-base w-fit md:text-lg px-7 hover:text-textSecondary text-textPrimary relative"
        >
          Notification
          {noOfUnseen ? (
            <p className="absolute text-xs top-0 right-0 text-white px-1 bg-red-500 rounded-full border">
              {noOfUnseen}
            </p>
          ) : null}
        </NavLink>

        <h6
          className="text-base md:text-lg px-7 hover:text-textSecondary text-textPrimary"
          style={{ color: "rgb(227 112 58)" }}
        >
          <Link to="https://commercial.classiestate.com.au/">Commercial</Link>
        </h6>

        <hr className="mt-4" />
        {token ? (
          <div className="flex flex-col gap-y-5 px-5 h-full">
            <Button
              value="My Account"
              className="rounded-xl py-4 bg-primary text-white"
              handleClick={() => {
                navigate("/me");
                setMobileMenu(false);
              }}
            />

            <Button
              value="Logout"
              className="border py-4 border-primary text-primary rounded-xl"
              handleClick={() => {
                handleClickLogout();
                setMobileMenu(false);
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-y-5 px-5 h-full">
            <Button
              value="Sign in"
              className="text-primary border border-primary py-3 rounded-lg font-medium text-base xl:text-lg"
              handleClick={() => {
                navigate("/auth/sign-in");
                setMobileMenu(false);
              }}
            />
            <Button
              value="Sign up"
              className="bg-primary py-3 text-base xl:text-lg rounded-lg text-white"
              handleClick={() => {
                navigate("/auth/sign-up");
                setMobileMenu(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
