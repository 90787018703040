import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const newsLoading = () => ({
  type: ActionTypes.NEWS_LOADING,
});

export const removeNewsData = () => ({
  type: ActionTypes.REMOVE_NEWS_DATA,
});

export const fetchGetAllNewsData = () => (dispatch) => {
  dispatch(newsLoading());
  return axios
    .get(
      `${BaseUrl}/v3/presets/news_categories?api_from=classiEstate&slug=real-estate`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ALL_NEWS_DATA,
        payload: data,
      });
    });
};

export const fetchGetNewsById = (id) => (dispatch) => {
  dispatch(newsLoading());
  return axios
    .get(`${BaseUrl}/v3/presets/news_detail/${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_NEWS_BY_ID,
        payload: data,
      });
    });
};
