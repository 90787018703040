import React from "react";
import { GrClose } from "react-icons/gr";

const MyEnquiryDetail = ({ enquiryDetail, setShowDetail }) => {
  return (
    <div className="absolute top-0 bg-white w-11/12 left-4 md:left-5 lg:left-8 rounded-lg h-fit p-5 z-40">
      <div className="flex justify-end">
        <div
          className="hover:border cursor-pointer hover:bg-gray-100 rounded-full p-2"
          onClick={() => {
            setShowDetail(false);
          }}
        >
          <GrClose className="text-xl sm:text-2xl" />
        </div>
      </div>
      <h4 className="text-textPrimary text-sm md:text-xl font-semibold">
        Enquiry Details
      </h4>
      <hr className="w-12 border border-primary mt-4" />
      <div className="flex gap-x-5 md:gap-x-7 lg:gap-x-12 2xl:gap-x-16 w-full flex-wrap mt-5">
        <div className="w-fit h-fit mt-5">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Enquiry For
          </p>

          <p className="text-[0.6rem] md:text-xs capitalize mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.title}
          </p>
        </div>

        <div className="w-fit h-fit mt-5">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Name
          </p>

          <p className="text-[0.6rem] md:text-xs capitalize mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.name}
          </p>
        </div>

        <div className="w-fit h-fit mt-5">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Email
          </p>

          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.email}
          </p>
        </div>

        <div className="w-fit h-fit mt-5">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Phone
          </p>

          <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.phone}
          </p>
        </div>
      </div>
      <div className="w-fit h-fit mt-5">
        <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
          Message
        </p>

        <p className="text-[0.6rem] md:text-xs mt-2 lg:text-sm text-textPrimary">
          {enquiryDetail.message}
        </p>
      </div>
      <hr className="mt-8" />
      <h4 className="text-textPrimary text-sm md:text-xl font-semibold mt-7">
        Agent Details
      </h4>
      <hr className="w-12 border border-primary mt-4" />
      <div className="flex gap-x-5 md:gap-x-7 lg:gap-x-12 2xl:gap-x-16 w-full flex-wrap mt-3">
        <div className="w-fit h-fit mt-5">
          <p className="text-[0.5rem] md:text-xs lg:text-sm text-textSecondary">
            Name
          </p>

          <p className="text-[0.6rem] md:text-xs capitalize mt-2 lg:text-sm text-textPrimary">
            {enquiryDetail.first_name} {enquiryDetail.last_name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MyEnquiryDetail;
