import React, { useEffect, useState } from "react";
import { Button } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchDeleteSaveSearch,
  fetchGetSaveSearch,
  fetchGetSearchResults,
  removeSearchData,
} from "../../Redux/Search/action";
import Cookies from "js-cookie";
import Loading from "../../Component/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { noProperty } from "../../Asset/Assest";

const MySavedSearches = () => {
  const [category, setCategory] = useState("");
  const [address, setAddress] = useState([]);
  const [streetname, setStreetname] = useState([]);
  const [suburb, setSuburb] = useState([]);
  const [state, setState] = useState([]);
  const [bedroom, setBedroom] = useState("");
  const [bathroom, setBathroom] = useState("");
  const [parking, setParking] = useState("");
  const [type, setType] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("");
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const navigate = useNavigate();

  const saveSearches = useSelector(
    (state) => state.search.saveSearch,
    shallowEqual
  );

  const message = useSelector((state) => state.search.message, shallowEqual);
  const errMessage = useSelector((state) => state.search.errMsg, shallowEqual);

  const loading = useSelector((state) => state.search.isloading, shallowEqual);

  // handle navigate function
  const handleNavigate = (value) => {
    const category = value.find((val) => val.parameter === "property_category");
    setCategory(category?.value);

    const address = value.find((val) => val.parameter === "property_address");
    setAddress(address?.value);

    const streetname = value.find(
      (val) => val.parameter === "property_street_name"
    );
    setStreetname(streetname?.value);

    const subrub = value.find((val) => val.parameter === "property_suburb");
    setSuburb(subrub?.value);

    const state = value.find((val) => val.parameter === "property_state");
    setState(state?.value);

    const bedroom = value.find((val) => val.parameter === "no_of_room");
    setBedroom(bedroom?.value);

    const bathroom = value.find((val) => val.parameter === "no_of_bathroom");
    setBathroom(bathroom?.value);

    const parking = value.find((val) => val.parameter === "no_of_carspace");
    setParking(parking?.value);

    const type = value.find((val) => val.parameter === "property_type");
    setType(type?.value);

    const price = value.find((val) => val.parameter === "price");
    if (price) {
      const [min, max] = price?.value?.split("__");
      setMinPrice(parseInt(min));
      setMaxPrice(parseInt(max));
    }

    const area = value.find((val) => val.parameter === "area");
    if (area) {
      const [minarea, maxarea] = area?.value?.split("__");

      setMinArea(parseInt(minarea ? minarea : null));
      setMaxArea(parseInt(maxarea ? maxarea : null));
    }
  };

  if (
    (category && category.length) ||
    (address && address.length) ||
    (streetname && streetname.length) ||
    (state && state.length) ||
    (suburb && suburb.length) ||
    (bathroom && bathroom.length) ||
    (bedroom && bedroom.length) ||
    (parking && parking.length) ||
    (minPrice && minPrice.length) ||
    (maxPrice && maxPrice.length) ||
    (minArea && minArea.length) ||
    (maxArea && maxArea.length)
  ) {
    dispatch(
      fetchGetSearchResults(
        category,
        address,
        streetname,
        suburb,
        state,
        bedroom,
        bathroom,
        parking,
        type,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        1,
        token
      )
    );
    localStorage.removeItem("Search-value");

    navigate(`/search?property_category=${category}&key=${address[0]}`);
  }

  useEffect(() => {
    dispatch(fetchGetSaveSearch(token));
    return () => {
      dispatch(removeSearchData());
    };
  }, []);

  const handleDeleteSearches = (e, id) => {
    e.preventDefault();
    const body = {
      searchid: id,
    };

    dispatch(fetchDeleteSaveSearch(body, token));
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeSearchData());
    setTimeout(() => {
      message && dispatch(fetchGetSaveSearch(token));
    });
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeSearchData());
    setTimeout(() => {
      errMessage && dispatch(fetchGetSaveSearch(token));
    });
  }, [errMessage]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      <div className="w-full h-fit border rounded-xl px-4 pt-4 bg-white">
        <h5 className="font-semibold text-xl md:text-2xl">Saved Searches</h5>
        <hr className="w-full mt-5" />
      </div>
      {saveSearches && saveSearches.length ? (
        saveSearches.map((val, i) => {
          const address = val.searched_options.find(
            (itm) => itm.parameter === "property_address"
          );

          const category = val.searched_options.find(
            (itm) => itm.parameter === "property_category"
          );

          const price = val.searched_options.find(
            (itm) => itm.parameter === "price"
          );

          const parking = val.searched_options.find(
            (itm) => itm.parameter === "no_of_carspace"
          );
          const bathroom = val.searched_options.find(
            (itm) => itm.parameter === "no_of_bathroom"
          );
          const room = val.searched_options.find(
            (itm) => itm.parameter === "no_of_room"
          );

          const area = val.searched_options.find(
            (itm) => itm.parameter === "area"
          );
          const propertyType = val.searched_options.find(
            (itm) => itm.parameter === "property_type"
          );

          return (
            <div
              className="mt-5 w-full  border rounded-xl pb-2 bg-white cursor-pointer hover:shadow-md"
              key={i}
            >
              <h6 className="font-medium p-5">Saved Search #{i + 1}</h6>
              <hr className="w-full text-textSecondary" />

              <div className="p-5 w-full flex justify-between">
                <div
                  className="w-full"
                  onClick={() => handleNavigate(val.searched_options)}
                >
                  <h6 className="font-medium text-xs md:text-sm lg:text-base">
                    {address && address.value
                      ? address.value.map((val) => {
                          return val;
                        })
                      : "-"}
                  </h6>

                  <div className="mt-2 flex items-center gap-x-4">
                    <p className="text-textSecondary border-r pr-5">
                      {category?.value}
                    </p>

                    <p className="text-textSecondary border-r pr-5">
                      {price?.label}
                    </p>
                    <p className="text-textSecondary">{propertyType?.label}</p>
                  </div>

                  <div className="flex flex-wrap items-center gap-x-5 mt-4">
                    {parking?.value ? (
                      <div className="flex items-center gap-x-2">
                        <img
                          src={parking?.icon}
                          alt="parking"
                          className="w-4 h-4"
                        />
                        <h6 className="text-textSecondary text-sm">
                          {parking?.value}
                        </h6>
                      </div>
                    ) : null}

                    {bathroom?.value ? (
                      <div className="flex items-center gap-x-2">
                        <img
                          src={bathroom?.icon}
                          alt="parking"
                          className="w-4 h-4"
                        />
                        <h6 className="text-textSecondary text-sm">
                          {bathroom?.value}
                        </h6>
                      </div>
                    ) : null}

                    {room?.value ? (
                      <div className="flex items-center gap-x-2">
                        <img
                          src={room?.icon}
                          alt="parking"
                          className="w-4 h-4"
                        />
                        <h6 className="text-textSecondary text-sm">
                          {room?.value}
                        </h6>
                      </div>
                    ) : null}

                    {area?.label ? (
                      <div className="flex items-center gap-x-2">
                        <img
                          src={area?.icon}
                          alt="parking"
                          className="w-4 h-4"
                        />
                        <h6 className="text-textSecondary text-sm">
                          {area?.label}
                        </h6>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="flex flex-col gap-y-2 ">
                  <Button
                    value="Delete"
                    handleClick={(e) => handleDeleteSearches(e, val.id)}
                    className="w-20 text-sm lg:text-base md:w-24 lg:w-32 py-2 lg:py-3 border rounded-xl transition duration-1000 ease-in-out hover:bg-gray-50"
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="">
          <img src={noProperty} alt="" className="w-full h-80 object-contain" />
          <h2 className="text-textPrimary font-semibold text-2xl lg:text-4xl text-center w-full lg:w-3/4 2xl:w-2/3 mx-auto">
            No Property Found
          </h2>
        </div>
      )}
    </div>
  );
};

export default MySavedSearches;
