import React, { useEffect, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { BsCheck, BsCheck2All } from "react-icons/bs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import { GrClose } from "react-icons/gr";
import { classiBazarLogo } from "../../Asset/Assest";
import {
  fetchGetQuickEnquiryChat,
  fetchSendEnquiryReply,
} from "../../Redux/Enquiry/action";
import Cookies from "js-cookie";
import ScrollToBottom from "react-scroll-to-bottom";
import { scrollToTop } from "../NavigateTop";
import { RiAttachment2 } from "react-icons/ri";
import { fetchSendFile, removeInboxData } from "../../Redux/Inbox/action";
import { toast } from "react-toastify";

const QuickEnquiryChat = ({
  showChat,
  setShowChat,
  userId,
  setUserId,
  setUserEnquiryId,
  setEnquiryId,
  userEnquiryId,
  enquiryId,
}) => {
  const [userName, setUserName] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [image, setImage] = useState("");

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const chats = useSelector((state) => state.enquiry.chats, shallowEqual);
  const file = useSelector((state) => state.inbox.file, shallowEqual);
  const errMsg = useSelector((state) => state.inbox.errMsg, shallowEqual);

  useEffect(() => {
    const test = chats.filter(
      (val) => val.user_id !== userId || val.user_id === userId
    );
    setUserName(test?.[0]?.first_name + " " + test?.[0]?.last_name);

    const sortedDateTimes = chats.reverse();
    setMessages(sortedDateTimes);
  }, [chats]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      const body = {
        user_enquiry_id: userEnquiryId,
        txt_reply: message,
        chatfile: file.src,
        app_name: "CLASSIESTATE",
      };

      dispatch(fetchSendEnquiryReply(body, token));
      setTimeout(() => {
        dispatch(fetchGetQuickEnquiryChat(enquiryId, userEnquiryId, token));
        setMessage("");
        dispatch(removeInboxData());
      }, 100);
    }
  };

  useEffect(() => {
    file && setMessage(image?.name);
  }, [file]);

  const handleClickSend = () => {
    const body = {
      user_enquiry_id: userEnquiryId,
      txt_reply: message,
      chatfile: file?.src,
      app_name: "CLASSIESTATE",
    };

    dispatch(fetchSendEnquiryReply(body, token));
    setTimeout(() => {
      dispatch(fetchGetQuickEnquiryChat(enquiryId, userEnquiryId, token));
      setMessage("");
      dispatch(removeInboxData());
    }, 100);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    setTimeout(() => {
      errMsg && dispatch(removeInboxData());
    }, 100);
  }, [errMsg]);

  if (chats && !chats.length) {
    return (
      <div className="w-full">
        <Loading />
      </div>
    );
  }
  return (
    <div className="mt-2 w-full bg-pink-50 h-[60vh] lg:h-[80vh] xl:h-[80vh] 2xl:h-[90vh] border overflow-y-auto rounded-xl relative">
      <div className="flex items-center justify-between w-full bg-white px-5 py-2 md:py-5">
        <h5 className="font-medium text-xs md:text-sm lg:text-base  w-full bg-white">
          {userName ? userName : null}
        </h5>
        <div
          className="hover:border cursor-pointer hover:bg-gray-100 rounded-full p-2"
          onClick={() => {
            setEnquiryId("");
            setUserEnquiryId("");
            setUserId("");
            setShowChat(false);
          }}
        >
          <GrClose className="text-sm md:text-xl lg:text-2xl" />
        </div>
      </div>
      <hr className="w-full" />

      <div className="w-full h-[73.7%] md:h-[75.8%] lg:h-[73%] xl:h-[77.9%]  pb-6 overflow-y-auto">
        <ScrollToBottom className="h-full w-full overflow-y-scroll px-5">
          {messages
            ? messages.map((val) => {
                return (
                  <div
                    key={val.id}
                    className={`${
                      userName === val.first_name + " " + val.last_name
                        ? ""
                        : "flex-row-reverse"
                    } flex w-full p-2 gap-x-5`}
                  >
                    <div className="w-[2.6rem] mt-2 h-10 overflow-hidden rounded-full">
                      {val.thumbnail ? (
                        <img
                          src={val.thumbnail}
                          alt=""
                          className="w-full h-full"
                        />
                      ) : (
                        <img
                          src={classiBazarLogo}
                          alt=""
                          className="w-full h-full"
                        />
                      )}
                    </div>
                    <div className="flex flex-col w-fit">
                      <div
                        className={` ${
                          userName === val.first_name + " " + val.last_name
                            ? "text-left text-textSecondary bg-white rounded-r-xl rounded-bl-xl w-fit"
                            : " text-white bg-primary rounded-l-xl rounded-br-xl w-fit"
                        } py-2 px-4 mt-2 `}
                      >
                        {val.file_url ? (
                          <a
                            className={`text-sm ${
                              val.file_url ? "text-blue-500" : ""
                            }`}
                            href={val.file_url}
                            download={val.file_url}
                          >
                            {val.msg}
                          </a>
                        ) : (
                          <p
                            className={`text-sm ${
                              val.file_url ? "text-blue-500" : ""
                            }`}
                          >
                            {val.msg}
                          </p>
                        )}
                      </div>
                      <div className="flex items-center mt-2 gap-x-2">
                        <p className="text-[0.6rem]  text-textSecondary">
                          {val.added_at}
                        </p>
                        {val.seen_at !== null ? (
                          <BsCheck2All className="text-xs text-primary" />
                        ) : (
                          <BsCheck className="text-sm text-textSecondary" />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </ScrollToBottom>
      </div>
      <div className="w-full h-[14%] xl:h-[12%] absolute bottom-0 gap-x-5 bg-white flex justify-between items-center px-5">
        <div
          className={`border rounded-full w-11/12 outline-none text-sm text-textSecondary flex items-center ${
            message === image?.name ? "pl-5" : "px-5"
          }`}
        >
          <input
            type="text"
            placeholder="Write message in here..."
            onKeyDown={handleKeyPress}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            disabled={message === image?.name ? true : false}
            className="outline-none py-2 md:py-3 w-full"
          />
          {message === image?.name ? (
            <span
              className="bg-red-700 w-14 cursor-pointer py-2 text-center md:py-3 text-white rounded-r-full"
              onClick={() => {
                setMessage("");
                setImage("");
                dispatch(removeInboxData());
              }}
            >
              X
            </span>
          ) : null}
        </div>

        <label className="w-fit p-3 bg-primary text-white rounded-full cursor-pointer">
          <RiAttachment2 className="text-white text-xl" />
          <input
            type="file"
            className="w-full h-full border hidden"
            accept=".doc, .docx, .pdf"
            onChange={(e) => {
              setImage(e.target.files[0]);
              const formData = new FormData();
              formData.append("files", e.target.files[0]);

              dispatch(fetchSendFile(formData, token));
            }}
          />
        </label>

        <div
          className="p-2 md:p-3 cursor-pointer bg-primary text-white rounded-full"
          onClick={handleClickSend}
        >
          <AiOutlineSend className="text-xl" />
        </div>
      </div>
    </div>
  );
};

export default QuickEnquiryChat;
