import React from "react";

const Contain = ({ title, paragraph }) => {
  return (
    <div className="w-full">
      <h6 className="text-xl text-primary font-semibold">{title}</h6>
      <p
        className="text-textSecondary mt-4 text-sm md:text-base"
        dangerouslySetInnerHTML={{
          __html: paragraph,
        }}
      ></p>
    </div>
  );
};

export default Contain;
