import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { BsArrowLeft } from "react-icons/bs";
import { Button } from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";

const LocationCompo = ({
  value,
  setValue,
  setStartCompo,
  setLocationCompo,
  setEnquiryCompo,
  getLocationData,
}) => {
  const handleClickBack = () => {
    setLocationCompo(false);
    setStartCompo(true);
    scrollToTop();
  };

  const handleClickNext = () => {
    if (value && value.length) {
      getLocationData();
      setLocationCompo(false);
      setEnquiryCompo(true);
      scrollToTop();
    }
  };
  return (
    <div className="mt-5 md:mt-16 w-full md:w-11/12 lg:w-3/4 pb-5 md:mx-auto rounded-xl border bg-white">
      <div className="px-5 md:px-10 pt-5 md:pt-10">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Where are you looking?
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <div className="mt-10 bg-white w-full">
          <GooglePlacesAutocomplete
            placeholder="Where is it?"
            textInputProps={{
              placeholderTextColor: "#32a852",
              returnKeyType: "search",
            }}
            autocompletionRequest={{
              componentRestrictions: { country: ["au"] },
            }}
            selectProps={{
              value,
              onChange: setValue,
              isMulti: true,
              placeholder: "Enter your Preferred Location",
            }}
          />
          <p className="text-textPrimary text-sm mt-3 md:text-base">
            *You can add multiple locations as per required
          </p>
        </div>
        {value && value.length ? (
          <div className="mt-12 md:mt-16 w-full px-5 py-1 border-l-4 border-l-primary ">
            <p className="text-primary font-medium text-base md:text-lg">
              Awesome!{" "}
            </p>
            <p className="text-textPrimary text-sm md:text-base mt-2">
              Let’s make sure we get all the info we need, so we can help you
              get into your home sooner - an agent will be in touch to explain
              all your options.
            </p>
          </div>
        ) : null}
      </div>

      <hr className="w-full mt-10" />
      <div className="px-5 md:px-10 mt-8 flex items-center justify-between">
        <div
          className="flex items-center gap-x-2 text-sm md:text-base cursor-pointer text-primary"
          onClick={handleClickBack}
        >
          <BsArrowLeft />
          <p>Back to Start</p>
        </div>

        <Button
          value="Next"
          className={`rounded-xl py-2 text-sm md:text-base md:py-3 px-10  ${
            value && value.length
              ? "bg-primary text-white"
              : "bg-gray-100 text-gray-400"
          }`}
          disable={value && value.length ? false : true}
          handleClick={handleClickNext}
        />
      </div>
    </div>
  );
};

export default LocationCompo;
