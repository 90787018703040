import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loading from "../../Component/Loading/Loading";
import { useLocation } from "react-router-dom";
import {
  fetchGetAGentDetail,
  removeAgentAgencyData,
} from "../../Redux/Agent-Agencies/action";
import { Button, GridPropertyCard } from "../../Component";
import { HiOutlineMail } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import { scrollToTop } from "../../Component/NavigateTop";
import Cookies from "js-cookie";
import {
  fetchAddToWishlist,
  fetchRemoveWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";
import AgentEnquiryPopup from "./Popup/AgentEnquiryPopup";
import { classiBazarLogo, noData } from "../../Asset/Assest";

const AgentDetailPage = () => {
  const [agentId, setAgentId] = useState("");
  const [agentEnquiry, setAgentEnquiry] = useState(false);
  const [toggle, setToggle] = useState({
    all: true,
    buy: false,
    rent: false,
    sold: false,
  });

  const [status, setStatus] = useState("All");

  const dispatch = useDispatch();

  const search = useLocation().search;
  const location = useLocation();

  const token = Cookies.get("token");

  useEffect(() => {
    setAgentId(new URLSearchParams(search).get("indx"));
  }, [location.search]);

  const agentDetail = useSelector(
    (state) => state.agentagencies.agentDetail,
    shallowEqual
  );
  const loading = useSelector(
    (state) => state.agentagencies.isloading,
    shallowEqual
  );
  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.wishlist.errMsg,
    shallowEqual
  );

  const [properties, setProperties] = useState(agentDetail.posts);

  useEffect(() => {
    setProperties(agentDetail.posts);
  }, [agentDetail]);

  const handleClickAll = () => {
    setToggle({
      all: true,
      buy: false,
      rent: false,
      sold: false,
    });

    setStatus("All");
    setProperties(agentDetail.posts);
  };

  const handleClickBuy = () => {
    setToggle({
      all: false,
      buy: true,
      rent: false,
      sold: false,
    });

    setStatus("buy");
    setProperties(
      agentDetail.posts.filter(
        (val) => val.property_category === "buy" && val.ad_status !== "4"
      )
    );
  };

  const handleClickRent = () => {
    setToggle({
      all: false,
      buy: false,
      rent: true,
      sold: false,
    });

    setStatus("rent");
    setProperties(
      agentDetail.posts.filter(
        (val) => val.property_category === "rent" && val.ad_status !== "4"
      )
    );
  };

  const handleClickSold = () => {
    setToggle({
      all: false,
      buy: false,
      rent: false,
      sold: true,
    });

    setStatus(1);
    setProperties(agentDetail.posts.filter((val) => val.ad_status === "4"));
  };

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddToWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlist(body, token));
  };

  useEffect(() => {
    message && dispatch(removeAgentAgencyData());
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    message && dispatch(fetchGetAGentDetail(agentId, status, token));
  }, [message]);

  useEffect(() => {
    errMessage && dispatch(removeAgentAgencyData());
    errMessage && toast.success(errMessage);
    errMessage && dispatch(removeWishlistData());
    errMessage && dispatch(fetchGetAGentDetail(agentId, status, token));
  }, [errMessage]);

  useEffect(() => {
    agentId && dispatch(fetchGetAGentDetail(agentId, status, token));
    return () => {
      dispatch(removeAgentAgencyData());
    };
  }, [agentId, status]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="py-16 md:py-24 w-full relative">
      {agentEnquiry && (
        <div
          className="w-full h-full bg-black absolute top-0 z-40 opacity-10"
          onClick={() => setAgentEnquiry(false)}
        >
          {" "}
        </div>
      )}
      <div className="absolute w-full top-[8%] md:top-[7%]">
        {agentEnquiry && (
          <AgentEnquiryPopup
            data={agentDetail.agent}
            setAgentEnquiry={setAgentEnquiry}
            agentId={agentId}
          />
        )}
      </div>
      <div
        className="h-14 md:h-16 xl:h-20  w-full flex justify-center items-center"
        style={{ background: `${agentDetail.agent?.company_color}` }}
      >
        <div className="w-24 h-8 md:w-36 md:h-12 xl:w-40 xl:h-14">
          {agentDetail.agent?.company_logo ? (
            <img
              src={agentDetail.agent?.company_logo}
              alt="logo"
              className="w-full h-full object-contain"
            />
          ) : (
            <img src={classiBazarLogo} alt="logo" className="w-full h-full" />
          )}
        </div>
      </div>

      {/* profile */}
      <div className="w-11/12 md:w-1/2 lg:w-1/3 mx-auto mt-3 md:mt-7 p-5">
        <div className="w-24 h-24 mx-auto ">
          {agentDetail.agent?.thumbnail ? (
            <img
              src={agentDetail.agent?.thumbnail}
              alt=""
              className="w-full h-full rounded-full"
            />
          ) : (
            <img
              src={classiBazarLogo}
              alt=""
              className="w-full h-full rounded-full"
            />
          )}
        </div>
        <h4 className="text-center mt-6 font-medium text-xl md:text-2xl">
          {agentDetail.agent?.first_name} {agentDetail.agent?.last_name}
        </h4>
        <p className="mt-2 text-sm md:text-base text-primary underline text-center">
          {agentDetail.agent?.company_name}
        </p>
        <Button
          value="Contact Agent"
          handleClick={() => {
            scrollToTop();
            setAgentEnquiry(true);
          }}
          className="w-full py-2.5 md:py-3 bg-primary text-white text-center rounded-xl mt-5 text-sm md:text-base"
        />

        {/* email and call */}
        <div className="flex justify-center gap-x-2 md:gap-x-5 w-full flex-row mt-5">
          <div
            className="border py-2.5 md:py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center transition duration-1000 ease-in-out hover:bg-gray-100 justify-center rounded-2xl"
            onClick={() => {
              scrollToTop();
              setAgentEnquiry(true);
            }}
          >
            <HiOutlineMail className="text-textSecondary text-base md:text-2xl" />
            <p className="text-sm md:text-lg text-textPrimary">Email</p>
          </div>
          <a href={`tel: ${agentDetail.agent?.mobile}`} className="w-full">
            <div className="border py-2.5 md:py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center justify-center rounded-2xl transition duration-1000 ease-in-out hover:bg-gray-100">
              <BiPhoneCall className="text-textSecondary text-base md:text-2xl" />
              <p className="text-sm md:text-lg text-textPrimary">Call</p>
            </div>
          </a>
        </div>
      </div>
      <hr className="w-full" />

      {/* about us  */}
      <div className="lg:w-3/4 w-11/12 mt-7 md:mt-10 mx-auto">
        <h4 className="text-textPrimary text-xl md:text-2xl font-semibold">
          About {agentDetail.agent?.first_name} {agentDetail.agent?.last_name}
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <p className="mt-7 text-sm md:text-base">
          {agentDetail.agent?.about_me}
        </p>
      </div>

      <hr className="w-full mt-7" />

      {/* Performance stat  */}
      <div className="lg:w-3/4 w-11/12 mt-7 md:mt-10 mx-auto">
        <h4 className="text-textPrimary text-xl md:text-2xl font-semibold">
          Performance stats
        </h4>
        <hr className="w-20 border border-primary mt-4" />
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center flex-col">
            <h4 className="font-medium text-xs md:text-2xl text-primary mt-10">
              {agentDetail.agent?.sold?.length}
            </h4>
            <h5 className="text-[0.5rem] md:text-sm lg:text-lg">Total Sales</h5>
          </div>

          <div className="flex items-center flex-col">
            <h4 className="font-medium text-xs md:text-2xl text-primary mt-10">
              ${agentDetail.agent?.average_sale_price}
            </h4>
            <h5 className="text-[0.5rem] md:text-sm lg:text-lg">
              Average sales price
            </h5>
          </div>

          <div className="flex items-center flex-col">
            <h4 className="font-medium text-xs md:text-2xl text-primary mt-10">
              ${agentDetail.agent?.sold_amount}
            </h4>
            <h5 className="text-[0.5rem] md:text-sm lg:text-lg">
              Total sales value
            </h5>
          </div>

          <div className="flex items-center flex-col">
            <h4 className="font-medium text-xs md:text-2xl text-primary mt-10">
              {agentDetail.agent?.days_on_market}
            </h4>
            <h5 className="text-[0.5rem] md:text-sm lg::text-lg">
              Average days on market
            </h5>
          </div>
        </div>

        {/* table */}

        <div className="w-full border mt-5 md:mt-10 rounded-xl">
          <div className="flex w-full p-2 rounded-t-xl md:p-5 gap-x-6 justify-between items-center bg-primary text-white">
            <div className="w-full text-xs md:text-sm lg:text-base">
              <h6>Properties Types</h6>
            </div>
            <div className=" flex items-center text-xs md:text-sm lg:text-base w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 justify-between">
              <h6>Sales</h6>
              <h6>Average</h6>
            </div>
          </div>

          {agentDetail.property_types && agentDetail.property_types.length
            ? agentDetail.property_types.map((val, i) => {
                return (
                  <div
                    className="flex border-b w-full p-2 md:p-5 gap-x-6 justify-between items-center"
                    key={i}
                  >
                    <div className="w-full text-[0.6rem] md:text-sm lg:text-base">
                      <h6>{val.name}</h6>
                    </div>
                    <div className=" flex items-center text-[0.6rem] md:text-sm lg:text-base w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 justify-between">
                      <h6>{val.sold}</h6>
                      <h6>{val.average}</h6>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <hr className="w-full mt-7" />

      {/* Property Listing  */}
      <div className="lg:w-3/4 w-11/12 mt-7 md:mt-10 mx-auto">
        <h4 className="text-textPrimary text-xl md:text-2xl font-semibold">
          Current Properties Listing
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <div className="flex items-center md:gap-x-10 mt-10 md:mt-10 border-b">
          <h5
            onClick={handleClickAll}
            className={`${
              toggle.all
                ? "font-medium text-primary text-base md:text-lg border-b border-b-primary"
                : " text-textPrimary text-sm md:text-base"
            } px-4 md:px-7 cursor-pointer pb-2`}
          >
            All
          </h5>
          <h5
            onClick={handleClickBuy}
            className={`${
              toggle.buy
                ? "font-medium text-primary text-base md:text-lg border-b border-b-primary"
                : " text-textPrimary text-sm md:text-base"
            } px-4 md:px-7 cursor-pointer pb-2`}
          >
            Buy
          </h5>
          <h5
            onClick={handleClickRent}
            className={`${
              toggle.rent
                ? "font-medium text-primary text-base md:text-lg border-b border-b-primary"
                : " text-textPrimary text-sm md:text-base"
            } px-4 md:px-7 cursor-pointer pb-2`}
          >
            Rent
          </h5>

          <h5
            onClick={handleClickSold}
            className={`${
              toggle.sold
                ? "font-medium text-primary text-base md:text-lg border-b border-b-primary"
                : " text-textPrimary text-sm md:text-base"
            } px-4 md:px-7 cursor-pointer pb-2`}
          >
            Sold/Leased
          </h5>
        </div>
        <div className="w-full h-full relative">
          {properties && properties.length ? (
            <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
              {properties.map((val) => {
                return (
                  <div key={val.post_id}>
                    <GridPropertyCard
                      data={val}
                      handleAddToWishlist={handleAddToWishlist}
                      handleRemoveWishlist={handleRemoveWishlist}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="relative">
              <img
                src={noData}
                alt=""
                className="w-full md:w-3/4 lg:w-2/3 h-full object-contain"
              />
              <h2 className="absolute top-1/2 left-[6%] md:left-[1%] 2xl:left-[5%]  text-white font-semibold text-xs sm:text-sm md:text-base 2xl:text-2xl text-center w-full md:w-10/12 lg:w-3/4 2xl:w-2/3 mx-auto">
                No Properties Added
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentDetailPage;
