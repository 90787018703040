import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchVerifyCode,
  removeProfileData,
} from "../../../Redux/Profile/action";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { fetchGetUserProfile } from "../../../Redux/UserProfile/action";

const OTPpopup = ({ setOtpPopup }) => {
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });

  const { otp1, otp2, otp3, otp4 } = otp;

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();

  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const message = useSelector((state) => state.profile.otpSucc, shallowEqual);
  const errMessage = useSelector((state) => state.profile.otpErr, shallowEqual);

  const handleOtpChange = (e, inputRef) => {
    const inputOtp = e.target.value.split("");
    if (e.target.value.length === 1 && inputRef?.current) {
      inputRef.current.focus();
    }
    if (inputOtp.length > 1 && inputOtp.length <= 4) {
      inputOtp.map((num, index) => {
        setOtp((prev) => {
          return { ...prev, [`otp${index + 1}`]: num };
        });
      });
    } else {
      setOtp((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  const handleSubmitOtp = () => {
    const body = {
      status: 1,
      code: otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4,
    };
    dispatch(fetchVerifyCode(body, token));
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeProfileData());
    message && dispatch(fetchGetUserProfile(token));
    message && setOtpPopup(false);
  }, [message]);

  useEffect(() => {
    errMessage && toast.success(errMessage);
    errMessage && dispatch(removeProfileData());
    errMessage && dispatch(fetchGetUserProfile(token));
    errMessage && setOtpPopup(false);
  }, [errMessage]);

  return (
    <div className="absolute top-10 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 lg:w-3/4 left-[5%] lg:left-[10%] pt-5 md:pt-10 pb-16 ">
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <h4 className="font-bold text-xl md:text-3xl text-primary text-center mb-8">
          OTP
        </h4>
        <div className="otp--input--field flex flex-row justify-center gap-4 m-8">
          <input
            maxLength={1}
            onChange={(e) => handleOtpChange(e, input2)}
            name="otp1"
            value={otp1}
            className="border w-14 h-14 rounded-lg outline-primary text-center"
            style={{ background: "#F7F9FC" }}
            type="number"
            required
            ref={input1}
          />
          <input
            maxLength={1}
            name="otp2"
            value={otp2}
            onChange={(e) => handleOtpChange(e, input3)}
            className="border w-14 h-14 rounded-lg outline-primary text-center"
            style={{ background: "#F7F9FC" }}
            type="number"
            required
            ref={input2}
          />
          <input
            maxLength={1}
            name="otp3"
            value={otp3}
            onChange={(e) => handleOtpChange(e, input4)}
            className="border w-14 h-14 rounded-lg outline-primary text-center"
            style={{ background: "#F7F9FC" }}
            type="number"
            required
            ref={input3}
          />
          <input
            maxLength={1}
            name="otp4"
            value={otp4}
            onChange={(e) => handleOtpChange(e)}
            className="border w-14 h-14 rounded-lg outline-primary text-center"
            style={{ background: "#F7F9FC" }}
            type="number"
            required
            ref={input4}
          />
        </div>

        <div className="flex justify-between items-center gap-x-10 mt-8">
          <div
            className="border w-full rounded-2xl text-primary cursor-pointer font-medium py-4 text-center "
            style={{ background: "#EBF0FA" }}
            onClick={() => setOtpPopup(false)}
          >
            <Button value="Cancel" />
          </div>

          <div
            className="border w-full rounded-2xl bg-primary cursor-pointer text-white text-sm md:text-base font-medium py-4 text-center"
            onClick={handleSubmitOtp}
          >
            <Button value="Save Changes" type="submit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPpopup;
