import React from "react";

const Button = ({ value, className, type, handleClick, disable }) => {
  return (
    <button
      className={className}
      type={type}
      onClick={handleClick}
      disabled={disable}
    >
      {value}
    </button>
  );
};

export default Button;
