import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { classiBazarLogo } from "../../Asset/Assest";

const NewsCard = ({ data }) => {
  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowBack />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowForward />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="w-full">
      <Slider {...settings}>
        {data &&
          data.map((data) => {
            return (
              <div
                className="px-1 2xl:px-3 pb-5 slider-card"
                key={data.news_id}
              >
                <Link to={`/news/${data.news_id}`}>
                  <div className="mt-10 h-[18rem] rounded-xl relative hover:shadow-md pb-5 border cursor-pointer">
                    <div className="w-full h-40 overflow-hidden">
                      {data.news_pic ? (
                        <img
                          src={data.news_pic}
                          alt="img"
                          className="w-full h-full object-cover rounded-t-xl thumb"
                        />
                      ) : (
                        <img
                          src={classiBazarLogo}
                          alt="img"
                          className="w-full h-full rounded-t-xl thumb"
                        />
                      )}
                    </div>
                    <div className="w-10/12 mx-auto mt-4">
                      <h5 className="text-primary font-medium text-sm truncate2">
                        {data.news_title}
                      </h5>
                    </div>
                    <h4
                      className="text-textPrimary px-5 font-medium absolute bottom-3 w-full text-base truncate2 h-12 overflow-hidden"
                      dangerouslySetInnerHTML={{
                        __html: data.news_detail,
                      }}
                    ></h4>
                  </div>
                </Link>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default NewsCard;

export const GridNewsCard = ({ data }) => {
  return (
    <div className="w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {data &&
          data.map((data) => {
            return (
              <div className="px-1 lg:px-3 slider-card" key={data.news_id}>
                <Link to={`/news/${data.news_id}`}>
                  <div className="mt-10 h-[18rem] rounded-xl relative hover:shadow-md pb-5 border cursor-pointer slider-card">
                    <div className="w-full h-40 overflow-hidden">
                      {data.news_pic ? (
                        <img
                          src={data.news_pic}
                          alt="img"
                          className="w-full h-full rounded-t-xl thumb"
                        />
                      ) : (
                        <img
                          src={classiBazarLogo}
                          alt="img"
                          className="w-full h-full rounded-t-xl thumb"
                        />
                      )}
                    </div>
                    <div className="w-10/12 mx-auto mt-4">
                      <h5 className="text-primary font-medium text-sm truncate2">
                        {data.news_title}
                      </h5>
                    </div>
                    <h4
                      className="text-textPrimary px-5 font-medium absolute bottom-3 w-full text-base truncate2 h-12 overflow-hidden"
                      dangerouslySetInnerHTML={{
                        __html: data.news_detail,
                      }}
                    ></h4>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const NewsCardSkeleton = () => {
  return (
    <div className="w-full">
      <div className="grid animate-pulse  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <div className="px-1 lg:px-3">
          <div className="mt-10 rounded-xl hover:shadow-md pb-5 border border-slate-100 cursor-pointer transition-all duration-300 hover:scale-105">
            <div className="w-full h-40 bg-slate-200 rounded-t-xl"></div>
            <div className="w-11/12 mx-auto mt-5">
              <div className="text-primary w-full bg-slate-200 h-4 rounded-full font-medium text-sm truncate2"></div>

              <div className="text-textPrimary w-full bg-slate-200 h-5 rounded-full font-medium text-base mt-4 truncate2"></div>
            </div>
          </div>
        </div>

        <div className="px-1 lg:px-3">
          <div className="mt-10 rounded-xl hover:shadow-md pb-5 border border-slate-100 cursor-pointer transition-all duration-300 hover:scale-105">
            <div className="w-full h-40 bg-slate-200 rounded-t-xl"></div>
            <div className="w-11/12 mx-auto mt-5">
              <div className="text-primary w-full bg-slate-200 h-4 rounded-full font-medium text-sm truncate2"></div>

              <div className="text-textPrimary w-full bg-slate-200 h-5 rounded-full font-medium text-base mt-4 truncate2"></div>
            </div>
          </div>
        </div>

        <div className="px-1 lg:px-3">
          <div className="mt-10 rounded-xl hover:shadow-md pb-5 border border-slate-100 cursor-pointer transition-all duration-300 hover:scale-105">
            <div className="w-full h-40 bg-slate-200 rounded-t-xl"></div>
            <div className="w-11/12 mx-auto mt-5">
              <div className="text-primary w-full bg-slate-200 h-4 rounded-full font-medium text-sm truncate2"></div>

              <div className="text-textPrimary w-full bg-slate-200 h-5 rounded-full font-medium text-base mt-4 truncate2"></div>
            </div>
          </div>
        </div>

        <div className="px-1 lg:px-3">
          <div className="mt-10 rounded-xl hover:shadow-md pb-5 border border-slate-100 cursor-pointer transition-all duration-300 hover:scale-105">
            <div className="w-full h-40 bg-slate-200 rounded-t-xl"></div>
            <div className="w-11/12 mx-auto mt-5">
              <div className="text-primary w-full bg-slate-200 h-4 rounded-full font-medium text-sm truncate2"></div>

              <div className="text-textPrimary w-full bg-slate-200 h-5 rounded-full font-medium text-base mt-4 truncate2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
