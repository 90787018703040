import React from "react";
import { appStore, mobileIcon, playStore } from "../../Asset/Assest";

const Download = () => {
  return (
    <div className="w-full bg-primary mt-20 rounded-3xl border grid gap-x-5 grid-cols-12">
      <div className="col-span-12 md:col-span-7 lg:col-span-8 w-full h-full p-6 md:p-10">
        <h4 className="text-white text-xl md:text-3xl font-semibold">
          Download the App?
        </h4>
        <hr className="w-20 border border-white mt-4" />
        <p className="text-white text-xs md:text-sm lg:text-base xl:text-lg mt-7 lg:mt-12 tracking-wide">
          Get instant or daily emails and push notifications to be alerted about
          homes that match what you're looking for!
        </p>
        <div className="flex items-center gap-x-2 lg:gap-x-10 mt-6 lg:mt-10">
          <a href="https://play.google.com/store/apps/details?id=com.classiestate">
            <img
              src={playStore}
              alt="play store"
              className="w-28 h-8 md:w-36 md:h-9 lg:w-40 lg:h-12 xl:w-44 xl:h-14 cursor-pointer"
            />
          </a>

          <a href="https://apps.apple.com/au/app/classiestate/id1562134094">
            <img
              src={appStore}
              alt="app store"
              className="w-28 h-8 md:w-36 md:h-9 lg:w-40 lg:h-12 xl:w-44 xl:h-14 cursor-pointer"
            />
          </a>
        </div>
      </div>
      <div className="hidden md:block col-start-8 md:col-start-9 col-end-13 h-full relative">
        <img
          src={mobileIcon}
          alt=""
          className="absolute -top-10 xl:-top-14 left-0 lg:left-10 h-[400px] xl:h-[450px]"
        />
      </div>
    </div>
  );
};

export default Download;
