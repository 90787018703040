import React, { useEffect, useMemo, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import Header from "./Header";
import {
  AgentsCard,
  AgentsCardSkeleton,
  Button,
  Pagination,
  SinglePagnination,
} from "../../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchGetAgentAgencySearchSuggestion,
  fetchGetAgentSearchResult,
  removeAgentAgencyData,
} from "../../../Redux/Agent-Agencies/action";
import debounce from "lodash.debounce";
import { noData } from "../../../Asset/Assest";
import { scrollToTop } from "../../../Component/NavigateTop";
import AgentEmailPopup from "./Popup/AgentEmailPopup";

const SearchAgent = () => {
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState("Agents");
  // Get the current URL search parameters
  const searchParams = new URLSearchParams(window.location.search);
  // Read the value of the 'search' parameter
  const searchValue = searchParams.get("search");

  const [searchAgents, setSearchAgents] = useState(searchValue);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [agentId, setAgentId] = useState();

  console.log("agentId", agentId);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const agents = useSelector(
    (state) => state.agentagencies.agents,
    shallowEqual
  );

  const loading = useSelector(
    (state) => state.agentagencies.isloading,
    shallowEqual
  );

  const suggestions = useSelector(
    (state) => state.agentagencies.suggestions,
    shallowEqual
  );

  const totalData = useSelector(
    (state) => state.agentagencies.totalData,
    shallowEqual
  );

  const totalPage = Math.ceil(totalData / 14);

  const onchangeSearchHandler = (e) => {
    setSearchAgents(e.target.value);
  };

  const suggest = suggestions.filter(
    (val) => val.type === "Agent" || val.type === "Address"
  );

  useEffect(() => {
    dispatch(fetchGetAgentAgencySearchSuggestion(searchAgents));
    return () => {
      dispatch(removeAgentAgencyData());
    };
  }, [searchAgents]);

  const debouncedResults = useMemo(() => {
    return debounce(onchangeSearchHandler, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleClickSearch = () => {
    dispatch(fetchGetAgentSearchResult(searchValue, page));
    navigate(`/find-agents/agents?search=${searchAgents}`);
  };

  const handleClickSuggestion = (name) => {
    dispatch(fetchGetAgentSearchResult(searchValue, page));
    navigate(`/find-agents/agents?search=${name}`);
    setSearchAgents(name);
  };

  useEffect(() => {
    dispatch(fetchGetAgentSearchResult(searchValue, page));
    return () => {
      dispatch(removeAgentAgencyData());
    };
  }, [searchValue, page]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="py-16 sm:py-24 relative">
      {showEmailPopup && (
        <div
          className="absolute top-0 w-full h-full bg-black z-30 opacity-10"
          onClick={() => setShowEmailPopup(false)}
        >
          {" "}
        </div>
      )}
      <div className="w-full relative">
        {showEmailPopup && (
          <div className="absolute top-20 w-full">
            <AgentEmailPopup
              setAgentEnquiry={setShowEmailPopup}
              agentId={agentId}
              searchValue={searchAgents}
            />
          </div>
        )}
      </div>
      {/* Bread crum */}
      <div className="flex items-center gap-x-1 md:gap-x-3 mt-7 w-11/12 xl:w-3/4 mx-auto">
        <Link to="/">
          <p className="text-textSecondary text-xs md:text-sm underline">
            Home
          </p>
        </Link>
        <MdKeyboardArrowRight size={15} className="text-textSecondary" />
        <Link to="/find-agents">
          <p className="text-textSecondary text-xs md:text-sm underline">
            Find Agents
          </p>
        </Link>
        <MdKeyboardArrowRight size={15} className="text-textSecondary" />
        <p className="text-textPrimary font-medium text-sm md:text-base">
          {searchValue}
        </p>
      </div>
      <hr className="mt-6" />
      {/* header */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-4 md:mt-10">
        <Header
          toggle={toggle}
          setToggle={setToggle}
          searchValue={searchAgents}
          searchKey={searchValue}
          onchangeSearchHandler={onchangeSearchHandler}
          handleClickSearch={handleClickSearch}
          handleClickSuggestion={handleClickSuggestion}
          suggestions={suggest}
        />
      </div>
      <hr className="mt-6" />

      {loading ? (
        <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
          <AgentsCardSkeleton />
        </div>
      ) : (
        <div className="w-11/12 xl:w-3/4 mx-auto mt-5 md:mt-10 grid grid-cols-12 lg:gap-x-16">
          <div className="col-span-12 lg:col-span-7">
            <h5 className="text-textPrimary font-semibold text-xl md:text-2xl">
              Agents active in {searchValue}
            </h5>
            <p className="text-textSecondary mt-3 text-sm md:text-base">
              {totalData} agents found
            </p>
            <hr className="mt-5" />
            <div className="md:mt-10 w-full">
              {agents && agents.length ? (
                <>
                  {agents.map((val) => {
                    return (
                      <div key={val.id} className="mt-6 md:mt-10">
                        <AgentsCard
                          data={val}
                          setShowEmailPopup={setShowEmailPopup}
                          setAgentId={setAgentId}
                        />
                      </div>
                    );
                  })}
                  <div className="mt-10">
                    {totalPage > 5 ? (
                      <Pagination
                        setPage={setPage}
                        page={page}
                        totalData={totalData}
                        totalPage={totalPage}
                      />
                    ) : (
                      <SinglePagnination
                        setPage={setPage}
                        page={page}
                        totalData={totalData}
                        totalPage={totalPage}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="relative">
                  <img
                    src={noData}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                  <h2 className="absolute top-1/2 left-[7%] lg:left-[20%] 2xl:left-1/4 text-white font-semibold text-sm md:text-3xl lg:text-2xl text-center w-full lg:w-3/4 2xl:w-2/3 mx-auto">
                    No Agents Found
                  </h2>
                </div>
              )}
            </div>
          </div>
          <div className="col-span-12 mt-10 lg:mt-0 lg:col-start-8 xl:col-start-9 lg:col-end-13 border rounded-xl px-5 py-7 h-fit">
            <h5 className="font-semibold text-xl md:text-2xl text-textPrimary">
              Didn’t find what you are looking for?
            </h5>
            <p className="text-textSecondary text-base md:text-lg mt-3">
              Get help from the experts today!
            </p>
            <Button
              value="Make an Enquiry"
              handleClick={() => navigate("/agent-enquiry")}
              className="bg-primary rounded-xl text-white py-3.5 px-7 mt-10 w-full"
            />
          </div>
        </div>
      )}

      {showSuggestion && (
        <div
          className="absolute w-full h-screen bg-black top-0 z-50 opacity-100"
          onClick={setShowSuggestion(false)}
        ></div>
      )}
    </div>
  );
};

export default SearchAgent;
