import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const menu = [
  {
    id: 1,
    name: "NSW",
  },
  {
    id: 2,
    name: "VIC",
  },
  {
    id: 3,
    name: "QLD",
  },
  {
    id: 4,
    name: "WA",
  },
  {
    id: 5,
    name: "SA",
  },
  {
    id: 6,
    name: "TAS",
  },
  {
    id: 7,
    name: "ACT",
  },
  {
    id: 8,
    name: "NT",
  },
];

const Footer = ({ toggle, setToggle }) => {
  const navigate = useNavigate();
  const handleClickAgent = () => {
    setToggle({
      agent: true,
      agency: false,
    });
  };

  const handleClickAgency = () => {
    setToggle({
      agency: true,
      agent: false,
    });
  };
  const handleClickState = (value) => {
    if (toggle.agent && value) {
      navigate(`/find-agents/agents?search=${value}`);
    } else if (toggle.agency && value) {
      navigate(`/find-agencies/agencies?search=${value}`);
    }
  };
  return (
    <>
      <div className="border-b flex items-center gap-x-3">
        <h6
          className={`text-textSecondary cursor-pointer text-xs md:text-sm lg:text-base px-1 md:px-3 lg:px-7 2xl:px-10  py-3 w-fit ${
            toggle.agent ? "border-b border-b-primary" : ""
          }`}
          onClick={handleClickAgent}
        >
          Agents in Popular Suburb
        </h6>
        <h6
          className={`text-textSecondary cursor-pointer text-xs md:text-sm lg:text-base px-1 md:px-3 lg:px-7 2xl:px-10  py-3 w-fit ${
            toggle.agency ? "border-b border-b-primary" : ""
          }`}
          onClick={handleClickAgency}
        >
          Agencies in Popular Suburb
        </h6>
      </div>

      <div className="mt-10 grid grid-cols-2 gap-x-1 w-3/4 md:w-2/3">
        {menu.map((val) => {
          return (
            <div key={val.id}>
              <p
                className="text-textSecondary hover:font-medium underline cursor-pointer text-[0.5rem] md:text-sm lg:text-base px-1 md:px-3 lg:px-7 2xl:px-10  py-3 w-fit"
                onClick={() => handleClickState(val.name)}
              >
                {val.name}{" "}
                {toggle.agent
                  ? "Real Estate Agents"
                  : toggle.agency
                  ? "Real Estate Agenices"
                  : ""}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Footer;
