import React from "react";
import { Button, Search } from "../../../Component";
import { useNavigate } from "react-router-dom";

const Header = ({
  handleClickSearch,
  searchValue,
  onchangeSearchHandler,
  handleClickSuggestion,
  suggestions,
  searchKey,
  toggle,
  setToggle,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-full grid grid-cols-12 h-full gap-x-3 lg:gap-x-7">
      <div className="col-span-12 md:col-span-4 lg:col-span-3 border rounded-2xl">
        <div className="flex items-center w-full justify-between">
          <Button
            value="Agents"
            handleClick={() =>
              navigate(`/find-agents/agents?search=${searchValue}`)
            }
            className={`py-3.5 w-full px-5 lg:text-base text-sm ${
              toggle === "Agents"
                ? "bg-primary text-white rounded-2xl"
                : "text-primary"
            }`}
          />
          <Button
            value="Agencies"
            handleClick={() =>
              navigate(`/find-agencies/agencies?search=${searchValue}`)
            }
            className={`py-3.5 px-5 w-full lg:text-base text-sm ${
              toggle === "Agencies"
                ? "bg-primary text-white rounded-2xl"
                : "text-primary"
            } `}
          />
        </div>
      </div>
      <div className="mt-8 sm:mt-0 col-start-1 col-end-13 md:col-start-5 lg:col-start-4 md:col-end-11 relative">
        <Search
          searchValue={searchValue}
          onchangeSearchHandler={onchangeSearchHandler}
        />
        {suggestions.length ? (
          <div
            className={`w-full absolute border h-40 overflow-y-auto bg-white mt-1 rounded-xl ${
              searchKey === searchValue ? "hidden" : "block"
            }`}
          >
            {suggestions.map((val) => {
              return (
                <h6
                  key={val.id}
                  className="px-5 py-2.5 cursor-pointer hover:bg-slate-50 text-sm text-textPrimary"
                  onClick={() => handleClickSuggestion(val.name)}
                >
                  {val.name}
                </h6>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className="col-span-12 md:col-span-2 ">
        <div className="flex items-center gap-x-5 w-full mt-7 md:mt-0 ">
          <Button
            value="Search"
            className="bg-primary text-white rounded-2xl text-sm lg:text-base w-fit px-7 md:px-0 md:w-full py-3.5"
            handleClick={handleClickSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
