import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  posts: [],
  filters: [],
  search_tags: [],
  saveSearch: [],
  message: "",
  errMsg: "",
  totalData: "",
};

export const Search = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SEARCH_RESULT:
      return {
        ...state,
        isloading: false,
        success: true,
        posts: action.payload.posts,
        filters: action.payload.filters.static_fields,
        search_tags: action.payload.search_tags,
        totalData: action.payload.total_numbers,
      };

    case ActionTypes.GET_INSPECTION_DATA:
      return {
        ...state,
        isloading: false,
        success: true,
        posts: action.payload.posts,
        filters: action.payload.filters.static_fields,
        search_tags: action.payload.search_tags,
        totalData: action.payload.total_numbers,
      };

    case ActionTypes.GET_DEFAULT_SEARCH:
      return {
        ...state,
        isloading: false,
        success: true,
        posts: action.payload.posts,
        filters: action.payload.filters.static_fields,
        search_tags: action.payload.search_tags,
        totalData: action.payload.total_numbers,
      };

    case ActionTypes.EXPLORE_MORE:
      return {
        ...state,
        isloading: false,
        success: true,
        posts: action.payload.posts,
        filters: action.payload.filters.static_fields,
        search_tags: action.payload.search_tags,
        totalData: action.payload.total_numbers,
      };

    case ActionTypes.GET_SAVE_SEARCH:
      return {
        ...state,
        isloading: false,
        success: true,
        saveSearch: action.payload.saved_searches,
      };

    case ActionTypes.SAVE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.SAVE_SEARCH_DATA_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.DELETE_SAVE_SEARCH_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.DELETE_SAVE_SEARCH_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_SEARCH_DATA:
      return {
        isloading: true,
        success: false,
        posts: [],
        filters: [],
        saveSearch: [],
        message: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
