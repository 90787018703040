import React from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import { Link } from "react-router-dom";
import { classiBazarLogo, noProperty } from "../../Asset/Assest";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const SearchCard = ({ data, handleAddToWishlist, handleRemoveWishlist }) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          // background: "red",
          position: "absolute",
          right: "10px",
          color: "#101828",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <IoIosArrowForward />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          // background: "green",
          position: "absolute",
          left: "10px",
          color: "#101828",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <IoIosArrowBack />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      {data && data.length ? (
        data.map((data) => {
          return (
            <div
              className="w-full border rounded-2xl relative hover:shadow-md h-[28rem] sm:h-[31rem] mt-7 slider-card"
              key={data.post_id}
            >
              <Link
                to={`/property-detail?property_category=${data.property_category}&indx=${data.post_id}&key=${data.title}`}
              >
                <div
                  className="w-full rounded-t-xl h-16 flex justify-between px-8 py-3 items-center"
                  style={{ background: `${data.company_color}` }}
                >
                  <div className="flex items-center gap-x-4">
                    <div className="w-12 h-12 rounded-full">
                      {data.athumbnail ? (
                        <img
                          src={data.athumbnail}
                          alt=""
                          className="w-full h-full object-fill rounded-full"
                        />
                      ) : (
                        <img
                          src={classiBazarLogo}
                          alt=""
                          className="w-full h-full object-fill rounded-full"
                        />
                      )}
                    </div>

                    <h4
                      className="font-medium text-lg "
                      style={{ color: `${data.agent_color}` }}
                    >
                      {data.afname} {data.alname}
                    </h4>
                  </div>
                  <div className="w-28 h-10">
                    {data.company_logo ? (
                      <img
                        src={data.company_logo}
                        alt="logo"
                        className="w-full h-full object-contain"
                      />
                    ) : (
                      <img
                        src={classiBazarLogo}
                        alt="logo"
                        className="w-full h-full object-fill"
                      />
                    )}
                  </div>
                </div>
                <Slider {...settings}>
                  {data.images &&
                    data.images.map((val) => {
                      return (
                        <div
                          className="w-full h-52 lg:h-64 relative overflow-hidden"
                          key={val.image_id}
                        >
                          {val.orinal_img ? (
                            <img
                              src={val.orinal_img}
                              alt="img"
                              className="w-full h-full object-fill thumb"
                            />
                          ) : (
                            <img
                              src={classiBazarLogo}
                              alt="img"
                              className="w-full h-full object-contain thumb"
                            />
                          )}

                          {data.ad_status !== "4" && data.spoted === "1" ? (
                            <div className="absolute top-1 text-white flex items-center justify-center gap-x-1 bg-primary left-0 py-1 px-5 rounded-r-lg">
                              <p className="font-medium capitalize">Spotted</p>
                            </div>
                          ) : null}

                          {data.ad_status === "5" ? null : (
                            <div className="absolute bottom-5 text-primary flex items-center justify-center gap-x-1 bg-white left-10 md:left-5 py-1 pl-1 pr-3 rounded-lg">
                              <GoPrimitiveDot size={20} />
                              <p className="font-medium capitalize">
                                {data.property_category}
                              </p>
                            </div>
                          )}

                          {data.ad_status !== "4" && data.urgent === "1" ? (
                            <div className="absolute bottom-5 text-white flex items-center justify-center gap-x-1 bg-red-500 right-10 md:right-5 py-1 pl-3 pr-3 rounded-lg">
                              <p className="font-medium capitalize">Urgent</p>
                            </div>
                          ) : null}

                          {data.ad_status === "4" ? (
                            <div className="absolute bottom-5 text-white flex items-center justify-center gap-x-1 bg-red-500 right-10 md:right-5 py-1 pl-3 pr-3 rounded-lg">
                              <p className="font-medium capitalize">Sold</p>
                            </div>
                          ) : null}

                          {data.ad_status === "5" ? (
                            <div className="absolute bottom-5 text-white flex items-center justify-center gap-x-1 bg-primary left-10 md:left-5 py-1 pl-3 pr-3 rounded-lg">
                              <p className="font-medium capitalize">
                                Under offer
                              </p>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                </Slider>
              </Link>
              <div className="w-11/12 mx-auto mt-4">
                <div className="flex items-center justify-between w-full">
                  <h5 className="text-primary font-semibold text-base lg:text-lg truncate">
                    <Link
                      to={`/property-detail?property_category=${data.property_category}&indx=${data.post_id}&key=${data.title}`}
                    >
                      {data.title}
                    </Link>
                  </h5>

                  <div className="hover:bg-gray-100 rounded-full p-2 cursor-pointer">
                    {data.is_wishlist ? (
                      <AiFillHeart
                        size={27}
                        className="text-red-500"
                        onClick={() => handleRemoveWishlist(data.post_id)}
                      />
                    ) : (
                      <AiOutlineHeart
                        size={27}
                        className="text-textSecondary"
                        onClick={() => handleAddToWishlist(data.post_id)}
                      />
                    )}
                  </div>
                </div>
                <Link
                  to={`/property-detail?property_category=${data.property_category}&indx=${data.post_id}&key=${data.title}`}
                >
                  <h4 className="text-textPrimary font-semibold text-xl lg:text-2xl">
                    {data.price !== "0"
                      ? data.price_type !== "2"
                        ? `$${data.price} ${
                            data.property_category === "rent"
                              ? `/ ${data.price_type2}`
                              : ""
                          }`
                        : `$${data.price} - $${data.to_price}  ${
                            data.property_category === "rent"
                              ? `/  ${data.price_type2}`
                              : ""
                          }`
                      : ""}
                  </h4>
                  <p className="text-textSecondary text-sm mt-3">
                    {data.ad_address}
                  </p>

                  {/* icons */}
                  <div className="flex flex-wrap items-center gap-x-5 absolute bottom-2">
                    {data.ads_data &&
                      data.ads_data.map((item, i) => {
                        return item.value === null ||
                          item.value === "0" ||
                          item.value === " m sq." ? null : (
                          <div className="flex items-center gap-x-2" key={i}>
                            <img
                              src={item.img}
                              alt="parking"
                              className="w-5 h-5"
                            />

                            <h6 className="text-textPrimary text-base lg:text-lg flex items-center">
                              {item.label === "Area"
                                ? item.value.replace("m sq.", " ")
                                : item.value}
                              <p
                                className={` ${
                                  item.label === "Area" ? "block" : "hidden"
                                }`}
                              >
                                {" "}
                                m <sup>2</sup>
                              </p>
                            </h6>
                          </div>
                        );
                      })}

                    <h6 className="border-l px-2 capitalize text-textSecondary text-sm lg:text-base">
                      {data.property_type}
                    </h6>
                  </div>
                </Link>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <img
            src={noProperty}
            alt=""
            className="w-full h-full object-contain"
          />
          <h2 className="text-textPrimary font-semibold text-2xl lg:text-4xl text-center w-full lg:w-3/4 2xl:w-2/3 mx-auto">
            No Property Found
          </h2>
          <p className="text-textSecondary text-center text-sm lg:text-base w-full lg:w-3/4 2xl:w-2/3 mx-auto mt-2 md:mt-3 lg:mt-5">
            Please try different search criteria for a wider selection of
            listings on our real estate website.
          </p>
        </div>
      )}
    </>
  );
};

export default SearchCard;

export const SearchCardSkeleton = () => {
  return (
    <div className="w-full animate-pulse border border-slate-200 rounded-2xl relative h-[28rem] sm:h-[31rem]">
      <div className="w-full rounded-t-xl h-16 flex bg-slate-200 justify-between px-8 py-3 items-center">
        <div className="flex items-center gap-x-4">
          <div className="w-12 h-12 bg-slate-100  rounded-full"></div>

          <div className="font-medium text-lg h-6 bg-slate-100 w-32 rounded-full text-white"></div>
        </div>
        <div className="w-28 h-7 bg-slate-100 rounded-full"></div>
      </div>
      <hr className="text-slate-100 w-full h-0.5" />

      <div className="w-full h-52 lg:h-64 bg-slate-200 "></div>
      <div className="w-11/12 mx-auto mt-4">
        <div className="flex items-center justify-between">
          <div className="text-primary font-semibold h-7 w-3/4 bg-slate-200 rounded-full text-base lg:text-lg truncate"></div>
          <div className=" cursor-pointer p-3 w-25 bg-slate-200 rounded-full"></div>
        </div>
        <div className="text-textPrimary bg-slate-200 w-4/6 h-6 mt-3 rounded-full font-black text-xl lg:text-2xl"></div>
        <p className="text-textSecondary text-sm mt-3 w-3/6 bg-slate-200 h-5 rounded-full"></p>

        {/* icons */}
        <div className="flex flex-wrap items-center gap-x-5 absolute bottom-2">
          <div className="flex items-center gap-x-2">
            <div className="w-7 h-7 bg-slate-200 rounded-full"></div>
            <div className="text-textPrimary bg-slate-200 w-20 h-4 rounded-full text-base lg:text-lg"></div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="w-7 h-7 bg-slate-200 rounded-full"></div>
            <div className="text-textPrimary bg-slate-200 w-20 h-4 rounded-full text-base lg:text-lg"></div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="w-7 h-7 bg-slate-200 rounded-full"></div>
            <div className="text-textPrimary bg-slate-200 w-20 h-4 rounded-full text-base lg:text-lg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MapCard = ({ data }) => {
  return (
    <div className="w-full border rounded-lg relative h-52">
      <div
        className="w-full rounded-t-lg h-8 flex justify-between py-3 items-center"
        style={{ background: `${data.company_color}` }}
      >
        <div className="flex items-center gap-x-1">
          <div className="w-3 h-3 rounded-full">
            {data.athumbnail ? (
              <img
                src={data.athumbnail}
                alt=""
                className="w-full h-full object-fill rounded-full"
              />
            ) : (
              <img
                src={classiBazarLogo}
                alt=""
                className="w-full h-full object-fill rounded-full"
              />
            )}
          </div>

          <h4
            className="font-medium text-[0.5rem]"
            style={{ color: `${data.agent_color}` }}
          >
            {data.afname} {data.alname}
          </h4>
        </div>
        <div className="w-10 h-3">
          {data.company_logo ? (
            <img
              src={data.company_logo}
              alt="logo"
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src={classiBazarLogo}
              alt="logo"
              className="w-full h-full object-cover"
            />
          )}
        </div>
      </div>

      <div className="w-full h-20 relative ">
        {data.image_name ? (
          <img
            src={data.image_name}
            alt="img"
            className="w-full h-full object-cover"
          />
        ) : (
          <img
            src={classiBazarLogo}
            alt="img"
            className="w-full h-full object-contain"
          />
        )}

        <div className="absolute bottom-5 text-primary flex items-center gap-x-1 bg-white left-2 py-0.5 px-2 rounded-lg">
          <GoPrimitiveDot className="mt-1 text-[0.5rem]" />
          <p className="font-semibold text-[0.5rem]">
            {data.property_category}
          </p>
        </div>
      </div>
      <div className="w-11/12 mx-auto mt-1">
        <div className="flex items-center justify-between">
          <h5 className="text-primary font-semibold text-[0.5rem] w-full truncate">
            {data.title}
          </h5>
          <div className="hover:bg-gray-100 rounded-full cursor-pointer p-2">
            <AiOutlineHeart className="text-textSecondary text-[0.5rem]" />
          </div>
        </div>
        <h4 className="text-textPrimary font-black text-[0.5rem]">
          {data.price}
        </h4>
        <p className="text-textSecondary text-[0.5rem] mt-1">
          {data.ad_address}
        </p>

        {/* icons */}
        <div className="flex flex-wrap items-center gap-x-5 absolute bottom-2">
          {data.ads_data &&
            data.ads_data.map((item, i) => {
              return (
                <div className="flex items-center gap-x-2" key={i}>
                  <img src={item.img} alt="parking" className="w-2 h-2" />
                  <h6 className="text-textPrimary text-[0.5rem]">
                    {item.value}
                  </h6>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
