import React from "react";
import { MyInspectionCard, MyInspectionCardSkeleton } from "../../Component";

const MyInspectionSkeleton = () => {
  return (
    <div className="w-full h-full">
      <div className="w-full h-fit border bg-slate-200 p-5 animate-pulse rounded-xl">
        <div className="font-semibold text-2xl w-full rounded-full bg-slate-100 h-8"></div>
        <hr className="mt-5" />
        <div className="w-full pb-5 overflow-x-auto flex items-center mt-5 gap-x-5">
          <div className="border bg-slate-100 lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full"></div>
          <div className="border bg-slate-100 lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full"></div>
          <div className="border bg-slate-100 lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full"></div>
          <div className="border bg-slate-100 lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full"></div>
        </div>
      </div>
      <MyInspectionCardSkeleton />
    </div>
  );
};

export default MyInspectionSkeleton;
