import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetSavedProperty,
  removeProfileData,
} from "../../Redux/Profile/action";
import { GridPropertyCard } from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import SavedPropertySkeleton from "./SavedPropertySkeleton";
import {
  fetchRemoveWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";
import { noProperty } from "../../Asset/Assest";
import { fetchGetUserProfile } from "../../Redux/UserProfile/action";

const SavedProperty = () => {
  const [selectAll, setSelectAll] = useState(true);
  const [selectBuy, setSelectBuy] = useState(false);
  const [selectRent, setSelectRent] = useState(false);
  const [myWishlist, setMyWishlist] = useState([]);

  const token = Cookies.get("token");

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.profile.isloading, shallowEqual);
  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMsg = useSelector((state) => state.wishlist.errMsg, shallowEqual);

  const savedProperty = useSelector(
    (state) => state.profile.savedProperty,
    shallowEqual
  );

  const handleSelectAll = () => {
    setSelectAll(true);
    setSelectBuy(false);
    setSelectRent(false);
    setMyWishlist(savedProperty);
  };

  const handleSelectBuy = () => {
    setSelectAll(false);
    setSelectBuy(true);
    setSelectRent(false);
    setMyWishlist(
      savedProperty.filter((val) => val.property_category === "buy")
    );
  };

  const handleSelectRent = () => {
    setSelectAll(false);
    setSelectBuy(false);
    setSelectRent(true);
    setMyWishlist(
      savedProperty.filter((val) => val.property_category === "rent")
    );
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlist(body, token));
  };

  useEffect(() => {
    dispatch(fetchGetSavedProperty(token));
    dispatch(fetchGetUserProfile(token));
    return () => {
      dispatch(removeProfileData());
    };
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    !selectAll && !selectBuy && !selectRent && setMyWishlist(savedProperty);
    savedProperty && selectAll && setMyWishlist(savedProperty);
    savedProperty &&
      selectBuy &&
      setMyWishlist(
        savedProperty.filter((val) => val.property_category === "buy")
      );
    savedProperty &&
      selectRent &&
      setMyWishlist(
        savedProperty.filter((val) => val.property_category === "rent")
      );
  }, [savedProperty]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    message && dispatch(fetchGetSavedProperty(token));
  }, [message]);

  useEffect(() => {
    errMsg && toast.success(errMsg);
    errMsg && dispatch(removeWishlistData());
    errMsg && dispatch(fetchGetSavedProperty(token));
  }, [errMsg]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <SavedPropertySkeleton />
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      <div className="w-full h-fit border bg-white px-5 pt-5 rounded-xl">
        <h5 className="font-semibold text-2xl">Saved Property</h5>

        <div className="flex items-center gap-x-3 mt-8">
          <p
            className={`px-7 text-sm md:text-base md:px-10 pb-5 cursor-pointer ${
              selectAll
                ? "text-textPrimary border-b border-b-primary"
                : "text-textSecondary"
            }`}
            onClick={handleSelectAll}
          >
            All
          </p>
          <p
            className={`px-7 text-sm md:text-base md:px-10 pb-5 cursor-pointer ${
              selectBuy
                ? "text-textPrimary border-b border-b-primary"
                : "text-textSecondary"
            }`}
            onClick={handleSelectBuy}
          >
            Buy
          </p>
          <p
            className={`px-7 text-sm md:text-base md:px-10 pb-5 cursor-pointer ${
              selectRent
                ? "text-textPrimary border-b border-b-primary"
                : "text-textSecondary"
            }`}
            onClick={handleSelectRent}
          >
            Rent
          </p>
        </div>
      </div>

      {myWishlist && myWishlist.length ? (
        <div className="w-full grid grid-cols-1 pb-5 lg:grid-cols-2 gap-x-3 xl:gap-x-6">
          {myWishlist.map((val) => {
            return (
              <div key={val.post_id}>
                <GridPropertyCard
                  data={val}
                  handleRemoveWishlist={handleRemoveWishlist}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="">
          <img src={noProperty} alt="" className="w-full h-80 object-contain" />
          <h2 className="text-textPrimary font-semibold text-2xl lg:text-4xl text-center w-full lg:w-3/4 2xl:w-2/3 mx-auto">
            No Property Found
          </h2>
        </div>
      )}
    </div>
  );
};

export default SavedProperty;
