import React from "react";
import { enquiryImg } from "../../Asset/Assest";
import { Button } from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";

const StartComponent = ({ setStartCompo, setLocationCompo }) => {
  const handleClick = () => {
    setStartCompo(false);
    setLocationCompo(true);
    scrollToTop();
  };
  return (
    <div className="mt-5 md:mt-10 grid grid-cols-12 gap-x-5">
      <div className="col-span-12 sm:col-span-6 lg:col-span-8 w-full h-full">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Looking for a property to buy or rent?
        </h4>
        <hr className="w-20 border border-primary mt-4" />
        <p className="text-textSecondary text-sm md:text-lg mt-10">
          Don’t take a stress and leave the rest to our experts.
        </p>
        <p className="text-textSecondary text-sm md:text-lg mt-5">
          We will only take a few minutes of your time and get the best property
          according to your enquiry.
        </p>

        <Button
          value="Get Started"
          handleClick={handleClick}
          className="w-1/2 sm:w-3/4 md:w-1/2 lg:w-1/4 bg-primary rounded-xl text-white py-3 px-7 mt-10"
        />

        <div className="mt-12 md:mt-16 w-full p-5 border-l-4 border-l-primary ">
          <p className="text-textPrimary text-sm md:text-base">
            We respect and protect your privacy and take every reasonable
            measure to protect your personal information. Find out more in our{" "}
            <span className="text-primary font-medium">Privacy Policy </span>{" "}
            and{" "}
            <span className="text-primary font-medium">
              {" "}
              Consumer Protection{" "}
            </span>{" "}
            policies and see product warnings below.
          </p>
        </div>
      </div>

      <div className="hidden sm:block col-start-7 lg:col-start-9 col-end-13 w-full h-full">
        <img src={enquiryImg} alt="" className="w-full h-full" />
      </div>
    </div>
  );
};

export default StartComponent;
