import React, { useEffect, useState } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { classiBazarLogo, noData } from "../../Asset/Assest";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "../../Component/Loading/Loading";
import {
  fetchGetAgencyDetail,
  removeAgentAgencyData,
} from "../../Redux/Agent-Agencies/action";
import {
  Button,
  GridPropertyCard,
  Pagination,
  SinglePagnination,
} from "../../Component";
import { HiOutlineMail } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import AgentEnquiryPopup from "./Popup/AgentEnquiryPopup";
import AgencyEnquiryPopup from "./Popup/AgencyEnquiryPopup";
import EmailPopup from "../Agent-Agencies/SearchAgent-Agencies/Popup/EmailPopup";
import {
  fetchAddToWishlist,
  fetchRemoveWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const AgencyDetailPage = () => {
  const [page, setPage] = useState(1);
  const [agencyId, setAgencyId] = useState("");
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [agencyEnquiry, setAgencyEnquiry] = useState(false);
  const [agentEnquiry, setAgentEnquiry] = useState(false);
  const [agentId, setAgentId] = useState("");
  const [agentDetail, setAgentDetail] = useState({
    id: "",
    thumbnail: "",
    first_name: "",
    last_name: "",
    company_name: "",
  });

  const [toggle, setToggle] = useState({
    all: true,
    buy: false,
    rent: false,
    sold: false,
  });

  const [status, setStatus] = useState("All");

  const dispatch = useDispatch();
  const search = useLocation().search;
  const location = useLocation();

  const token = Cookies.get("token");

  const agencyDetail = useSelector(
    (state) => state.agentagencies.agencyDetail,
    shallowEqual
  );

  const totalData = useSelector(
    (state) => state.agentagencies.totalData,
    shallowEqual
  );
  const totalPage = Math.ceil(totalData / 16);
  const [properties, setProperties] = useState(agencyDetail.posts);

  const loading = useSelector(
    (state) => state.agentagencies.isloading,
    shallowEqual
  );

  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.wishlist.errMsg,
    shallowEqual
  );

  useEffect(() => {
    setProperties(agencyDetail.posts);
  }, [agencyDetail]);

  const handleClickAll = () => {
    setToggle({
      all: true,
      buy: false,
      rent: false,
      sold: false,
    });
    setStatus("All");
    setProperties(agencyDetail.posts);
  };

  const handleClickBuy = () => {
    setToggle({
      all: false,
      buy: true,
      rent: false,
      sold: false,
    });

    setStatus("buy");
    setProperties(
      agencyDetail.posts.filter(
        (val) => val.property_category === "buy" && val.ad_status !== "4"
      )
    );
  };

  const handleClickRent = () => {
    setToggle({
      all: false,
      buy: false,
      rent: true,
      sold: false,
    });

    setStatus("rent");
    setProperties(
      agencyDetail.posts.filter(
        (val) => val.property_category === "rent" && val.ad_status !== "4"
      )
    );
  };

  const handleClickSold = () => {
    setToggle({
      all: false,
      buy: false,
      rent: false,
      sold: true,
    });

    setStatus(1);
    setProperties(agencyDetail.posts.filter((val) => val.ad_status === "4"));
  };

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddToWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlist(body, token));
  };

  useEffect(() => {
    setAgencyId(new URLSearchParams(search).get("indx"));
  }, [location.search]);

  useEffect(() => {
    agencyId && dispatch(fetchGetAgencyDetail(agencyId, page, status));
    return () => {
      dispatch(removeAgentAgencyData());
    };
  }, [agencyId, page, status]);

  useEffect(() => {
    message && dispatch(removeAgentAgencyData());
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    message && dispatch(fetchGetAgencyDetail(agencyId, page, status));
  }, [message]);

  useEffect(() => {
    errMessage && dispatch(removeAgentAgencyData());
    errMessage && toast.success(errMessage);
    errMessage && dispatch(removeWishlistData());
    errMessage && dispatch(fetchGetAgencyDetail(agencyId, page, status));
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (!agencyId || loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 w-full h-full relative">
      <div className="absolute top-[8%] w-full">
        {agentEnquiry && (
          <AgentEnquiryPopup
            data={agentDetail}
            setAgentEnquiry={setAgentEnquiry}
            agentId={agentId}
            agencyId={agencyId}
          />
        )}
      </div>

      <div className="absolute top-[1%] md:top-[2.5%] w-full">
        {agencyEnquiry && (
          <AgencyEnquiryPopup
            setAgencyEnquiry={setAgencyEnquiry}
            agencyId={agencyId}
          />
        )}
      </div>

      {showEmailPopup && (
        <div className="absolute top-[1%] md:top-[2.5%] w-full">
          <AgencyEnquiryPopup
            setAgencyEnquiry={setShowEmailPopup}
            agencyId={agencyId}
          />
        </div>
      )}

      {agentEnquiry || agencyEnquiry || showEmailPopup ? (
        <div
          className="w-full h-full absolute top-0 z-30 bg-black opacity-10"
          onClick={() => {
            setAgentEnquiry(false);
            setAgencyEnquiry(false);
            setShowEmailPopup(false);
          }}
        ></div>
      ) : null}

      <div
        className="h-14 md:h-16 xl:h-20 w-full flex justify-center items-center overflow-hidden"
        style={{ background: `${agencyDetail.agency?.company_color}` }}
      >
        <div className="w-24 h-8 md:w-36 md:h-12 xl:w-40 xl:h-14">
          {agencyDetail.agency?.company_logo ? (
            <img
              src={agencyDetail.agency?.company_logo}
              alt="logo"
              className="w-full h-full object-contain"
            />
          ) : (
            <img src={classiBazarLogo} alt="logo" className="w-full h-full" />
          )}
        </div>
      </div>
      <div className="w-full h-64 sm:h-96 lg:h-[35rem] overflow-hidden mt-0">
        {agencyDetail.agency?.image ? (
          <img
            src={agencyDetail.agency?.image}
            alt="img"
            className="w-full h-full object-fill md:object-contain"
          />
        ) : (
          <img
            src={classiBazarLogo}
            alt="img"
            className="w-full h-full object-fill"
          />
        )}
      </div>

      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <div className="flex items-center justify-between flex-wrap md:flex-nowrap">
          <div>
            <h4 className="font-semibold text-2xl md:text-4xl text-textPrimary">
              {agencyDetail?.agency?.company_name}
            </h4>

            <p className="text-textSecondary text-base md:text-xl mt-1">
              {agencyDetail?.agency?.street_address}
            </p>
          </div>
          <div className="mt-5 md:mt-0 w-full md:w-fit">
            <Button
              value="Enquire Agency"
              handleClick={() => {
                scrollToTop();
                setAgencyEnquiry(true);
              }}
              className="w-full px-5 py-3 bg-primary rounded-xl text-white"
            />

            {/* email and call */}
            <div className="flex justify-center gap-x-2 md:gap-x-5 w-full flex-row mt-5">
              <div
                className="border py-2.5 md:py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center transition duration-1000 ease-in-out hover:bg-gray-100 justify-center rounded-2xl"
                onClick={() => {
                  scrollToTop();
                  setShowEmailPopup(true);
                }}
              >
                <HiOutlineMail className="text-textSecondary text-base md:text-2xl w-full" />
                <p className="text-sm md:text-lg text-textPrimary w-full">
                  Email
                </p>
              </div>

              <a
                href={`tel: ${agencyDetail.agency?.contact_number}`}
                className="w-full"
              >
                <div className="border py-2.5 md:py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center justify-center rounded-2xl transition duration-1000 ease-in-out hover:bg-gray-100">
                  <BiPhoneCall className="text-textSecondary text-base md:text-2xl" />
                  <p className="text-sm md:text-lg text-textPrimary">Call</p>
                </div>
              </a>
            </div>
          </div>
        </div>

        {/* about us  */}
        <div className="w-full mt-7 md:mt-10 mx-auto">
          <h4 className="text-textPrimary text-xl md:text-2xl font-semibold">
            About {agencyDetail.agency?.company_name}
          </h4>
          <hr className="w-20 border border-primary mt-4" />

          <p className="mt-7 text-sm md:text-base">
            {agencyDetail.agency?.personal_info}
          </p>
        </div>

        <hr className="w-full mt-7" />

        {/* Performance stat  */}
        <div className="w-full mt-7 md:mt-10 mx-auto">
          <h4 className="text-textPrimary text-xl md:text-2xl font-semibold">
            Performance stats
          </h4>
          <hr className="w-20 border border-primary mt-4" />
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center flex-col">
              <h4 className="font-medium text-xs md:text-2xl text-primary mt-10">
                {agencyDetail.agency?.sold?.length}
              </h4>
              <h5 className="text-[0.5rem] md:text-sm lg:text-lg">
                Total Sales
              </h5>
            </div>

            <div className="flex items-center flex-col">
              <h4 className="font-medium text-xs md:text-2xl text-primary mt-10">
                ${agencyDetail.agency?.average_sale_price}
              </h4>
              <h5 className="text-[0.5rem] md:text-sm lg:text-lg">
                Average sales price
              </h5>
            </div>

            <div className="flex items-center flex-col">
              <h4 className="font-medium text-xs md:text-2xl text-primary mt-10">
                ${agencyDetail.agency?.sold_amount}
              </h4>
              <h5 className="text-[0.5rem] md:text-sm lg:text-lg">
                Total sales value
              </h5>
            </div>

            <div className="flex items-center flex-col">
              <h4 className="font-medium text-xs md:text-2xl text-primary mt-10">
                {agencyDetail.agency?.days_on_market}
              </h4>
              <h5 className="text-[0.5rem] md:text-sm lg::text-lg">
                Average days on market
              </h5>
            </div>
          </div>

          {/* table */}

          <div className="w-full border mt-5 md:mt-10 rounded-xl">
            <div className="flex w-full p-2 rounded-t-xl md:p-5 gap-x-6 justify-between items-center bg-primary text-white">
              <div className="w-fit md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>Properties Types</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-fit md:w-full md:justify-between">
                <h6>Sales</h6>
                <h6>Sales Average</h6>
                <h6>Lease</h6>
                <h6>Lease Average</h6>
              </div>
            </div>

            {/* house */}
            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>House</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>{agencyDetail?.property_types?.house?.sold}</h6>
                <h6>{agencyDetail?.property_types?.house?.average}</h6>
                <h6>{agencyDetail?.property_types?.house?.rent}</h6>
                <h6>
                  {agencyDetail?.property_types?.house?.average_rent_price}
                </h6>
              </div>
            </div>

            {/* appartment */}
            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>Appartment</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>{agencyDetail?.property_types?.appartment?.sold}</h6>
                <h6>{agencyDetail?.property_types?.appartment?.average}</h6>
                <h6>{agencyDetail?.property_types?.appartment?.rent}</h6>
                <h6>
                  {agencyDetail?.property_types?.appartment?.average_rent_price}
                </h6>
              </div>
            </div>

            {/* townhouse */}

            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>Townhouse</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>{agencyDetail?.property_types?.townhouse?.sold}</h6>
                <h6>{agencyDetail?.property_types?.townhouse?.average}</h6>
                <h6>{agencyDetail?.property_types?.townhouse?.rent}</h6>
                <h6>
                  {agencyDetail?.property_types?.townhouse?.average_rent_price}
                </h6>
              </div>
            </div>

            {/* land */}

            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>Land</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>{agencyDetail?.property_types?.land?.sold}</h6>
                <h6>{agencyDetail?.property_types?.land?.average}</h6>
                <h6>{agencyDetail?.property_types?.land?.rent}</h6>
                <h6>
                  {agencyDetail?.property_types?.land?.average_rent_price}
                </h6>
              </div>
            </div>

            {/* rural */}

            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>Rural</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>{agencyDetail?.property_types?.rural?.sold}</h6>
                <h6>{agencyDetail?.property_types?.rural?.average}</h6>
                <h6>{agencyDetail?.property_types?.rural?.rent}</h6>
                <h6>
                  {agencyDetail?.property_types?.rural?.average_rent_price}
                </h6>
              </div>
            </div>

            {/* new appartment */}

            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>New Appartment</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>
                  {agencyDetail?.property_types?.["new appartment"]?.sold}
                </h6>
                <h6>
                  {agencyDetail?.property_types?.["new appartment"]?.average}
                </h6>
                <h6>
                  {agencyDetail?.property_types?.["new appartment"]?.rent}
                </h6>
                <h6>
                  {
                    agencyDetail?.property_types?.["new appartment"]
                      ?.average_rent_price
                  }
                </h6>
              </div>
            </div>

            {/* new house and land */}

            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>New House and Land</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>
                  {agencyDetail?.property_types?.["new house and land"]?.sold}
                </h6>
                <h6>
                  {
                    agencyDetail?.property_types?.["new house and land"]
                      ?.average
                  }
                </h6>
                <h6>
                  {agencyDetail?.property_types?.["new house and land"]?.rent}
                </h6>
                <h6>
                  {
                    agencyDetail?.property_types?.["new house and land"]
                      ?.average_rent_price
                  }
                </h6>
              </div>
            </div>

            {/* office space and commercial  */}

            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>Office Space and Commercial</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>
                  {
                    agencyDetail?.property_types?.[
                      "office space and commercial"
                    ]?.sold
                  }
                </h6>
                <h6>
                  {
                    agencyDetail?.property_types?.[
                      "office space and commercial"
                    ]?.average
                  }
                </h6>
                <h6>
                  {
                    agencyDetail?.property_types?.[
                      "office space and commercial"
                    ]?.rent
                  }
                </h6>
                <h6>
                  {
                    agencyDetail?.property_types?.[
                      "office space and commercial"
                    ]?.average_rent_price
                  }
                </h6>
              </div>
            </div>

            {/* garage and storage */}
            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>Garage and Storage</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>
                  {agencyDetail?.property_types?.["garage and storage"]?.sold}
                </h6>
                <h6>
                  {
                    agencyDetail?.property_types?.["garage and storage"]
                      ?.average
                  }
                </h6>
                <h6>
                  {agencyDetail?.property_types?.["garage and storage"]?.rent}
                </h6>
                <h6>
                  {
                    agencyDetail?.property_types?.["garage and storage"]
                      ?.average_rent_price
                  }
                </h6>
              </div>
            </div>

            {/* villa */}

            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>Villa</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>{agencyDetail?.property_types?.["villa"]?.sold}</h6>
                <h6>{agencyDetail?.property_types?.["villa"]?.average}</h6>
                <h6>{agencyDetail?.property_types?.["villa"]?.rent}</h6>
                <h6>
                  {agencyDetail?.property_types?.["villa"]?.average_rent_price}
                </h6>
              </div>
            </div>

            {/* others */}
            <div className="flex w-full p-2 md:p-5 gap-x-6 justify-between items-center bg-white ">
              <div className="w-2/5 md:w-2/3 2xl:w-full text-[0.5rem] sm:text-xs lg:text-sm">
                <h6>Other</h6>
              </div>
              <div className=" flex items-center gap-x-5 text-[0.5rem] sm:text-xs lg:text-sm w-full justify-between">
                <h6>{agencyDetail?.property_types?.["other"]?.sold}</h6>
                <h6>{agencyDetail?.property_types?.["other"]?.average}</h6>
                <h6>{agencyDetail?.property_types?.["other"]?.rent}</h6>
                <h6>
                  {agencyDetail?.property_types?.["other"]?.average_rent_price}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <hr className="w-full mt-7" />

        {/* agents list  */}
        <div className="w-full mt-7 md:mt-10 mx-auto">
          <h4 className="text-textPrimary text-xl md:text-2xl font-semibold">
            Our Teams
          </h4>
          <hr className="w-20 border border-primary mt-4" />

          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 mt-10">
            {agencyDetail.agents &&
              agencyDetail.agents.map((val) => {
                return (
                  <div key={val.id} className="border rounded-xl  mt-5">
                    <div className="w-full flex justify-between p-5">
                      <div className="w-16 h-16 rounded-full overflow-hidden">
                        {val.thumbnail ? (
                          <img
                            src={val?.thumbnail}
                            alt="img"
                            className="w-full h-full object-fill"
                          />
                        ) : (
                          <img
                            src={classiBazarLogo}
                            alt="img"
                            className="w-full h-full object-fill"
                          />
                        )}
                      </div>
                      <div className="w-3/4">
                        <h6 className="font-semibold text-xl">
                          {val.first_name} {val.last_name}
                        </h6>
                        <p className="text-textSecondary truncate">
                          {val.about_me}
                        </p>
                      </div>
                    </div>

                    <hr className="w-full mt-3" />
                    <div className="w-full p-5 mt-3">
                      <Button
                        value="Contact Agent"
                        handleClick={() => {
                          scrollToTop();
                          setAgentId(val.id);
                          setAgentEnquiry(true);
                          setAgentDetail({
                            id: val.id,
                            thumbnail: val.thumbnail,
                            first_name: val.first_name,
                            last_name: val.last_name,
                            company_name: val.company_name,
                          });
                        }}
                        className="w-full text-white py-3 bg-primary rounded-xl"
                      />
                    </div>

                    <div className="flex justify-center gap-x-2 md:gap-x-5 w-full flex-row p-5">
                      <div
                        className="border py-2.5 md:py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center transition duration-1000 ease-in-out hover:bg-gray-100 justify-center rounded-2xl"
                        onClick={() => {
                          scrollToTop();
                          setAgentId(val.id);
                          setAgentEnquiry(true);
                          setAgentDetail({
                            id: val.id,
                            thumbnail: val.thumbnail,
                            first_name: val.first_name,
                            last_name: val.last_name,
                            company_name: val.company_name,
                          });
                        }}
                      >
                        <HiOutlineMail className="text-textSecondary text-base md:text-2xl" />
                        <p className="text-sm text-textPrimary">Email</p>
                      </div>
                      <a href={`tel: ${val.mobile}`} className="w-full">
                        <div className="border py-2.5 md:py-3 cursor-pointer px-5 md:px-10 w-full gap-x-3 flex items-center justify-center rounded-2xl transition duration-1000 ease-in-out hover:bg-gray-100">
                          <BiPhoneCall className="text-textSecondary text-base md:text-2xl" />
                          <p className="text-sm  text-textPrimary">Call</p>
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <hr className="w-full mt-5 md:mt-10" />
        {/* our posts */}
        <div className="w-full mx-auto mt-5 md:mt-10">
          <h4 className="text-textPrimary text-xl md:text-2xl font-semibold">
            Current Properties Listing
          </h4>
          <hr className="w-20 border border-primary mt-4" />

          <div className="flex items-center md:gap-x-10 mt-7 md:mt-10 border-b">
            <h5
              onClick={handleClickAll}
              className={`${
                toggle.all
                  ? "font-medium text-primary text-base md:text-lg border-b border-b-primary"
                  : " text-textPrimary text-sm md:text-base"
              } px-4 md:px-7 cursor-pointer pb-2`}
            >
              All
            </h5>
            <h5
              onClick={handleClickBuy}
              className={`${
                toggle.buy
                  ? "font-medium text-primary text-base md:text-lg border-b border-b-primary"
                  : " text-textPrimary text-sm md:text-base"
              } px-4 md:px-7 cursor-pointer pb-2`}
            >
              Buy
            </h5>
            <h5
              onClick={handleClickRent}
              className={`${
                toggle.rent
                  ? "font-medium text-primary text-base md:text-lg border-b border-b-primary"
                  : " text-textPrimary text-sm md:text-base"
              } px-4 md:px-7 cursor-pointer pb-2`}
            >
              Rent
            </h5>

            <h5
              onClick={handleClickSold}
              className={`${
                toggle.sold
                  ? "font-medium text-primary text-base md:text-lg border-b border-b-primary"
                  : " text-textPrimary text-sm md:text-base"
              } px-4 md:px-7 cursor-pointer pb-2`}
            >
              Sold/Leased
            </h5>
          </div>
          <div className="w-full h-full relative">
            {properties && properties.length ? (
              <>
                <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                  {properties.map((val) => {
                    return (
                      <div key={val.post_id}>
                        <GridPropertyCard
                          data={val}
                          handleAddToWishlist={handleAddToWishlist}
                          handleRemoveWishlist={handleRemoveWishlist}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="mt-14">
                  {totalPage > 5 ? (
                    <Pagination
                      setPage={setPage}
                      page={page}
                      totalData={totalData}
                      totalPage={totalPage}
                    />
                  ) : (
                    <SinglePagnination
                      setPage={setPage}
                      page={page}
                      totalData={totalData}
                      totalPage={totalPage}
                    />
                  )}
                </div>
              </>
            ) : (
              <div className="relative">
                <img
                  src={noData}
                  alt=""
                  className="w-full md:w-3/4 lg:w-2/3 h-full object-contain"
                />
                <h2 className="absolute top-1/2 left-[6%] md:left-[1%] 2xl:left-[5%]  text-white font-semibold text-xs sm:text-sm md:text-base 2xl:text-2xl text-center w-full md:w-10/12 lg:w-3/4 2xl:w-2/3 mx-auto">
                  No Properties Added
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyDetailPage;
