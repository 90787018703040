import React from "react";
import { NewsCardSkeleton } from "../../Component";

const NewsSkeleton = () => {
  return (
    <div className="py-28 w-11/12 sm:w-3/4 mx-auto">
      <div className="mt-10">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Latest Property News
        </h4>
        <hr className="w-20 border border-primary mt-4" />
        <h4 className="text-textPrimary font-medium text-base md:text-xl mt-10 w-full h-8 rounded-full bg-slate-200 animate-pulse tracking-wide"></h4>

        <div className="grid animate-pulse grid-cols-12 gap-x-5 md:gap-x-10 w-full mt-10">
          <div className="col-span-7 h-[28rem] w-full bg-slate-200">
            {/* <img src={NewsPage1} alt="" className="w-full h-full object-cover" /> */}
          </div>
          <div className="col-start-8 col-end-13 animate-pulse h-full w-full">
            <div className="flex flex-col gap-y-7 animate-pulse">
              <div className="w-full h-52 bg-slate-200"></div>
              <div className="w-full h-52 bg-slate-200"></div>
              {/* <img
              src={NewsPage2}
              alt=""
              className="w-full h-full object-cover"
            /> */}
              {/* <img
              src={NewsPage3}
              alt=""
              className="w-full h-full object-cover"
            /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Property News
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <NewsCardSkeleton />
      </div>
      <div className="mt-20">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Opinions and editorial
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <NewsCardSkeleton />
      </div>

      <div className="mt-20">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Property Market Trends
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <NewsCardSkeleton />
      </div>

      <div className="mt-20">
        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
          Buying and Building New
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <NewsCardSkeleton />
      </div>
    </div>
  );
};

export default NewsSkeleton;
