import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const loading = () => ({
  type: ActionTypes.SEARCH_LOADING,
});

export const removeSearchData = () => ({
  type: ActionTypes.REMOVE_SEARCH_DATA,
});

export const fetchGetSearchResults =
  (
    category,
    address,
    streetname,
    suburb,
    state,
    bedroom,
    bathroom,
    parking,
    type,
    minPrice,
    maxPrice,
    minArea,
    maxArea,
    page,
    token
  ) =>
  async (dispatch) => {
    const body = [
      {
        category: category,
        address: address,
        streetName: streetname,
        state: state,
        suburb: suburb,
        bedroom: bedroom,
        bathroom: bathroom,
        parking: parking,
        type: type,
        minPrice: minPrice,
        maxPrice: maxPrice,
        minArea: minArea,
        maxArea: maxArea,
      },
    ];

    localStorage.setItem("search-property", JSON.stringify(body[0]));

    const propertyAddress =
      address && address.length
        ? address
            .map((item) => `property_address[]=${encodeURIComponent(item)}`)
            .join("&")
        : "";

    const propertyStreetName =
      streetname && streetname.length
        ? streetname
            .map((item) => `property_street_name[]=${encodeURIComponent(item)}`)
            .join("&")
        : "";

    const propertyState =
      state && state.length
        ? state
            .map((item) => `property_state[]=${encodeURIComponent(item)}`)
            .join("&")
        : "";

    const propertySuburb =
      suburb && suburb.length
        ? suburb
            .map((item) => `property_suburb[]=${encodeURIComponent(item)}`)
            .join("&")
        : "";

    const propertyBedroom = bedroom ? `no_of_room=${bedroom}` : "";

    const propertyBathroom = bathroom ? `no_of_bathroom=${bathroom}` : "";
    const propertyParking = parking ? `no_of_carspace=${parking}` : "";

    const propertyType = type ? `property_type=${type}` : "";

    const price =
      maxPrice && minPrice
        ? `price=${minPrice}__${maxPrice}`
        : !minPrice && maxPrice
        ? `price=0__${maxPrice}`
        : minPrice && !maxPrice
        ? `price=${minPrice}__9999999`
        : "";

    const area =
      maxArea && minArea
        ? `area=${minArea}__${maxArea}`
        : !minArea && maxArea
        ? `area=0__${maxArea}`
        : minArea && !maxArea
        ? `area=${minArea}__9999999`
        : "";

    await axios
      .get(
        `${BaseUrl}/v3/ads_list?slug=real-estate&api_from=classiEstate&property_category=${category}&page=${page}&realestate_type=Residential${
          propertyAddress ||
          propertyState ||
          propertyStreetName ||
          propertySuburb ||
          propertyBedroom ||
          propertyBathroom ||
          propertyParking ||
          propertyType ||
          price ||
          area
            ? "&"
            : ""
        }${propertyAddress && propertyAddress + "&"}${
          propertyState && propertyState + "&"
        }${propertyStreetName && propertyStreetName + "&"}${
          propertySuburb && propertySuburb + "&"
        }${propertyBedroom && propertyBedroom + "&"}${
          propertyBathroom && propertyBathroom + "&"
        }${propertyParking && propertyParking + "&"}${
          propertyType && propertyType + "&"
        }${price && price + "&"}${area && area}`,
        token && token !== undefined
          ? { headers: { Authorization: `Bearer ${token}` } }
          : null
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_SEARCH_RESULT,
          payload: data,
        });
      });
  };

export const fetchGetDefaultSearch =
  (
    category,
    lat,
    lng,
    bedroom,
    bathroom,
    parking,
    type,
    minPrice,
    maxPrice,
    minArea,
    maxArea,
    page,
    token
  ) =>
  async (dispatch) => {
    const body = [
      {
        category: category,
        lat: lat,
        lng: lng,
        bedroom: bedroom,
        bathroom: bathroom,
        parking: parking,
        type: type,
        minPrice: minPrice,
        maxPrice: maxPrice,
        minArea: minArea,
        maxArea: maxArea,
      },
    ];

    localStorage.setItem("search-property", JSON.stringify(body[0]));

    const propertyBedroom = bedroom ? `no_of_room=${bedroom}` : "";

    const propertyBathroom = bathroom ? `no_of_bathroom=${bathroom}` : "";
    const propertyParking = parking ? `no_of_carspace=${parking}` : "";

    const propertyType = type && type.length ? `property_type=${type}` : "";

    const price =
      maxPrice && minPrice
        ? `price=${minPrice}__${maxPrice}`
        : !minPrice && maxPrice
        ? `price=0__${maxPrice}`
        : minPrice && !maxPrice
        ? `price=${minPrice}__9999999`
        : "";

    const area =
      maxArea && minArea
        ? `area=${minArea}__${maxArea}`
        : !minArea && maxArea
        ? `area=0__${maxArea}`
        : minArea && !maxArea
        ? `area=${minArea}__9999999`
        : "";

    await axios
      .get(
        `${BaseUrl}/v3/ads_list?slug=real-estate&api_from=classiEstate&page=${page}&realestate_type=Residential&latitudes[]=25.2744&longitudes[]=133.7751&property_category=${category}${
          propertyBedroom ||
          propertyBathroom ||
          propertyParking ||
          propertyType ||
          price ||
          area
            ? "&"
            : ""
        }${propertyBedroom && propertyBedroom + "&"}${
          propertyBathroom && propertyBathroom + "&"
        }${propertyParking && propertyParking + "&"}${
          propertyType && propertyType + "&"
        }${price && price + "&"}${area && area}`,
        token && token !== undefined
          ? { headers: { Authorization: `Bearer ${token}` } }
          : null
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_DEFAULT_SEARCH,
          payload: data,
        });
      });
  };

export const fetchGetExploreSearch =
  (
    category,
    lat,
    lng,
    is_featured,
    bedroom,
    bathroom,
    parking,
    type,
    minPrice,
    maxPrice,
    minArea,
    maxArea,
    page,
    token
  ) =>
  async (dispatch) => {
    const body = [
      {
        category: category,
        is_featured: is_featured,
        lat: lat,
        lng: lng,
        bedroom: bedroom,
        bathroom: bathroom,
        parking: parking,
        type: type,
        minPrice: minPrice,
        maxPrice: maxPrice,
        minArea: minArea,
        maxArea: maxArea,
      },
    ];

    const is_featureds = is_featured === 1 ? `is_featured=${is_featured}` : "";

    localStorage.setItem("search-property", JSON.stringify(body[0]));

    const propertyBedroom = bedroom ? `no_of_room=${bedroom}` : "";

    const propertyBathroom = bathroom ? `no_of_bathroom=${bathroom}` : "";
    const propertyParking = parking ? `no_of_carspace=${parking}` : "";

    const propertyType = type && type.length ? `property_type=${type}` : "";

    const price =
      maxPrice && minPrice
        ? `price=${minPrice}__${maxPrice}`
        : !minPrice && maxPrice
        ? `price=0__${maxPrice}`
        : minPrice && !maxPrice
        ? `price=${minPrice}__9999999`
        : "";

    const area =
      maxArea && minArea
        ? `area=${minArea}__${maxArea}`
        : !minArea && maxArea
        ? `area=0__${maxArea}`
        : minArea && !maxArea
        ? `area=${minArea}__9999999`
        : "";

    await axios
      .get(
        `${BaseUrl}/v3/ads_list?slug=real-estate&api_from=classiEstate&page=${page}&realestate_type=Residential&latitudes[]=25.2744&longitudes[]=133.7751&property_category=${category}${
          is_featureds ||
          propertyBedroom ||
          propertyBathroom ||
          propertyParking ||
          propertyType ||
          price ||
          area
            ? "&"
            : ""
        }${is_featureds && is_featureds + "&"}${
          propertyBedroom && propertyBedroom + "&"
        }${propertyBathroom && propertyBathroom + "&"}${
          propertyParking && propertyParking + "&"
        }${propertyType && propertyType + "&"}${price && price + "&"}${
          area && area
        }`,
        token && token !== undefined
          ? { headers: { Authorization: `Bearer ${token}` } }
          : null
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.EXPLORE_MORE,
          payload: data,
        });
      });
  };

export const fetchGetSaveSearch = (token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/v3/my_searches`,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_SAVE_SEARCH,
        payload: data,
      });
    });
};

export const fetchSaveSearches = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/saved_searches`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SAVE_SEARCH_DATA_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SAVE_SEARCH_DATA_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchDeleteSaveSearch = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v3/delete_searches`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_SAVE_SEARCH_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DELETE_SAVE_SEARCH_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchGetInspectionData =
  (
    category,
    date,
    address,
    streetname,
    suburb,
    state,
    bedroom,
    bathroom,
    parking,
    type,
    minPrice,
    maxPrice,
    minArea,
    maxArea,
    page,
    token
  ) =>
  async (dispatch) => {
    const propertyAddress =
      address && address.length
        ? address
            .map((item) => `property_address[]=${encodeURIComponent(item)}`)
            .join("&")
        : "";

    const propertyStreetName =
      streetname && streetname.length
        ? streetname
            .map((item) => `property_street_name[]=${encodeURIComponent(item)}`)
            .join("&")
        : "";

    const propertyState =
      state && state.length
        ? state
            .map((item) => `property_state[]=${encodeURIComponent(item)}`)
            .join("&")
        : "";

    const propertySuburb =
      suburb && suburb.length
        ? suburb
            .map((item) => `property_suburb[]=${encodeURIComponent(item)}`)
            .join("&")
        : "";

    const propertyBedroom = bedroom ? `no_of_room=${bedroom}` : "";

    const propertyBathroom = bathroom ? `no_of_bathroom=${bathroom}` : "";
    const propertyParking = parking ? `no_of_carspace=${parking}` : "";

    const propertyType = type ? `property_type=${type}` : "";

    const price =
      maxPrice && minPrice
        ? `price=${minPrice}__${maxPrice}`
        : !minPrice && maxPrice
        ? `price=0__${maxPrice}`
        : minPrice && !maxPrice
        ? `price=${minPrice}__9999999`
        : "";

    const area =
      maxArea && minArea
        ? `area=${minArea}__${maxArea}`
        : !minArea && maxArea
        ? `area=0__${maxArea}`
        : minArea && !maxArea
        ? `area=${minArea}__9999999`
        : "";

    await axios
      .get(
        `${BaseUrl}/v3/ads_list?slug=real-estate&api_from=classiEstate&property_category=${category}&page=${page}&inspection_date=${date}&realestate_type=Residential${
          propertyAddress ||
          propertyState ||
          propertyStreetName ||
          propertySuburb ||
          propertyBedroom ||
          propertyBathroom ||
          propertyParking ||
          propertyType ||
          price ||
          area
            ? "&"
            : ""
        }${propertyAddress && propertyAddress + "&"}${
          propertyState && propertyState + "&"
        }${propertyStreetName && propertyStreetName + "&"}${
          propertySuburb && propertySuburb + "&"
        }${propertyBedroom && propertyBedroom + "&"}${
          propertyBathroom && propertyBathroom + "&"
        }${propertyParking && propertyParking + "&"}${
          propertyType && propertyType + "&"
        }${price && price + "&"}${area && area}`,
        token && token !== undefined
          ? { headers: { Authorization: `Bearer ${token}` } }
          : null
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_INSPECTION_DATA,
          payload: data,
        });
      });
  };
